import React, { useState, useRef } from 'react';
import moment from 'moment';
import axios from 'axios';
import GenericDialog from '../GenericDialog';
import { classes } from '../styles';
import { IJobAdIndeedProps, IJobAdIndeed } from '../types';
import { Box, Snackbar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ActionButton from '../ActionButton';
import ActionPopover from '../ActionPopover';

export default function AdTable({ ads, jobAds, setAds, adsStatus, apiKey }: IJobAdIndeedProps) {
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogAdID, setDialogAdID] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [selectedAd, setSelectedAd] = useState<IJobAdIndeed | null>(null);
  const [tableHovered, setTableHovered] = useState(false);
  const actionCellRef = useRef(null);
  const jobCellRef = useRef(null);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);

  function handleSnack(message: string) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }

  async function handleArchive(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.put(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex((ad: IJobAdIndeed) => ad.id === id);
      const copyJob = newAds[adsStatus][index];
      newAds.archived && newAds.archived.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been archived`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not archive ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleCopy(url: string) {
    setDialogLoading(true);
    try {
      const { data } = await axios.get(`${url}?operation=clone`);
      window.location.href = data.url;
    } catch (e) {
      console.log(e);
      handleSnack('Could not clone ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleDelete(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      newAds[adsStatus] = newAds[adsStatus].filter((ad: IJobAdIndeed) => ad.id !== id);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been deleted`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not delete ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current) && ads.length > 0) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.arrowsContainer}>
          <Box
            onClick={handleScrollLeft}
            onMouseEnter={handleScrollableEnter}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              left: `${jobCellRef.current?.getBoundingClientRect().right}px`,
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box sx={classes.arrowsContainer}>
          <Box
            onMouseEnter={handleScrollableEnter}
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box
          sx={classes.tableContainer}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Box sx={classes.table}>
            <Box sx={{ ...classes.row, ...classes.rowHeader }}>
              <Box
                component="span"
                ref={jobCellRef}
                sx={{
                  ...classes.title,
                  minWidth: '182px',
                  ...classes.sticky,
                  ...classes.scrollShadowLeft,
                  ...(tableHovered && classes.scrollShadowVisible),
                  left: 0
                }}
              >
                Job
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Creation Date
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Created By
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Expiry
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Active on Indeed
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Sponsored
              </Box>
              <Box
                component="span"
                sx={{
                  ...classes.stickyRight,
                  ...classes.scrollShadowRight,
                  ...(tableHovered && classes.scrollShadowVisible)
                }}
                ref={actionCellRef}
              ></Box>
            </Box>
            {ads && ads.length > 0 ? (
              ads.map((ad: IJobAdIndeed) => (
                <Box key={ad.id} sx={classes.row}>
                  <Box
                    sx={{
                      ...classes.title,
                      minWidth: '182px',
                      ...classes.sticky,
                      ...classes.scrollShadowLeft,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  >
                    {ad.title}
                  </Box>
                  <Box>{moment(ad.created_at).format('D MMM YYYY')}</Box>
                  <Box>{ad.created_by.name}</Box>
                  <Box>{ad.expiry ? moment(ad.expiry).format('D MMM YYYY') : 'No expiry'}</Box>
                  <Box sx={classes.center}>
                    {ad.status === 'open' ? (
                      <CheckCircleIcon sx={classes.iconGreen} />
                    ) : (
                      <CancelIcon sx={classes.iconRed} />
                    )}
                  </Box>
                  <Box sx={classes.center}>
                    {ad.sponsored ? (
                      <CheckCircleIcon sx={classes.iconGreen} />
                    ) : (
                      <Box
                        onClick={() =>
                          (window.location.href = `/admin/indeed/job_ads/${ad.id}/sponsored_job/edit/`)
                        }
                        sx={classes.sponsorText}
                      >
                        Sponsor Ad
                      </Box>
                    )}
                  </Box>
                  <ActionButton
                    ad={ad}
                    jobAds={jobAds}
                    setAds={setAds}
                    adsStatus={adsStatus}
                    tableHovered={tableHovered}
                    apiKey={apiKey}
                    classes={classes}
                    adType={'indeed'}
                    setDialogLoading={setDialogLoading}
                    setDialogUrl={setDialogUrl}
                    setDialogOpen={setDialogOpen}
                    setActionsAnchorEl={setActionsAnchorEl}
                    setDialogAdID={setDialogAdID}
                    setDialogTitle={setDialogTitle}
                    setDialogDescription={setDialogDescription}
                    setDialogButtonText={setDialogButtonText}
                    setDialogButtonCallback={setDialogButtonCallback}
                    setSelectedAd={setSelectedAd}
                    handleSnack={handleSnack}
                    handleCopy={handleCopy}
                    handleArchive={handleArchive}
                  />
                </Box>
              ))
            ) : (
              <Box sx={classes.row}>
                <Box sx={classes.title}>No ads to display</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ActionPopover
        anchorEl={actionsAnchorEl}
        onClose={() => {
          setActionsAnchorEl(null);
          setTimeout(() => {
            setSelectedAd(null);
          }, 300);
        }}
        handleCopy={handleCopy}
        handleArchive={handleArchive}
        handleDelete={handleDelete}
        selectedAd={selectedAd}
        classes={classes}
        adType={'indeed'}
        setDialogUrl={setDialogUrl}
        setDialogTitle={setDialogTitle}
        setDialogDescription={setDialogDescription}
        setDialogButtonText={setDialogButtonText}
        setDialogButtonCallback={setDialogButtonCallback}
        setDialogOpen={setDialogOpen}
        setDialogAdID={setDialogAdID}
      />
      <GenericDialog
        url={dialogUrl}
        title={dialogTitle}
        description={dialogDescription}
        buttonText={dialogButtonText}
        buttonCallback={dialogButtonCallback}
        callbackLoading={dialogLoading}
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        dialogAdID={dialogAdID}
      />
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
