import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { IconButton, Tooltip } from '@mui/material';
import { classes } from '../styles';
import { IJobAd } from '../types';

function CopyAdIdButton({
  ad,
  handleSnack
}: {
  ad: IJobAd;
  handleSnack: (message: string) => void;
}) {
  if (ad?.advertisement_id) {
    return (
      <CopyToClipboard text={ad.advertisement_id} onCopy={() => handleSnack('Ad Id copied')}>
        <Tooltip title="Copy Seek Ad Id">
          <IconButton>
            <ContentCopyIcon fontSize="small" sx={classes.iconGrey} />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    );
  } else {
    return <div />;
  }
}

export default CopyAdIdButton;
