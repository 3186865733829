import React, { Dispatch, useState, useRef } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { MultilineFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { SingleDateTimePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import { scrollToElement } from '../../../utils/scroll-to-element';
import dayjs from 'dayjs';

export default function SparkHire({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [scheduledAt, setScheduledAt] = useState<Date>(new Date());
  const [timezone, setTimezone] = useState<string | null>(null);
  const [job, setJob] = useState<[string, string] | null>(null);
  const [question, setQuestion] = useState<[string, string] | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const fieldRefs = {
    job: useRef<HTMLInputElement>(null),
    question: useRef<HTMLInputElement>(null),
    scheduledAt: useRef<HTMLInputElement>(null)
  };

  const { data: sparkHireData, isFetching: loadingSparkHireData } = useQuery({
    queryKey: ['SparkHire data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getSparkHireData(application.id);
        return res;
      }
    },
    onSuccess: (res) => {
      const savedTimezone = res?.timezones?.find((tz) => tz === res?.timezone);
      savedTimezone && setTimezone(savedTimezone);
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting sparkhire data, ${error}`,
          state: 'error'
        }
      })
  });

  const validateInputs = () => {
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!job) inputErrors.job = 'Please select a job';
    if (!question) inputErrors.question = 'Please select a question set';
    if (dayjs(scheduledAt).isBefore(Date.now()))
      inputErrors.scheduledAt = 'Date and time must be in the future';
    setErrors(inputErrors);
    if (inputErrors.job) {
      scrollToElement(fieldRefs.job);
    } else if (inputErrors.question) {
      scrollToElement(fieldRefs.question);
    } else if (inputErrors.scheduledAt) {
      scrollToElement(fieldRefs.scheduledAt);
    }
    return !Object.keys(inputErrors).length;
  };

  const { mutate: sparkHireExport, isLoading: exportingSparkHire } = useMutation({
    mutationFn: async () => {
      if (application && job && question) {
        const { res } = await Api.sparkHireExport(application.id, {
          job_uuid: job[1],
          interview_questions_set_uuid: question[1],
          email_message: emailMessage,
          scheduled_time: scheduledAt.toISOString(),
          timezone: timezone ? timezone : undefined
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.SPARKHIRE}
      label="Spark Hire export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to Spark Hire</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 3 }}>
          <Autocomplete
            disablePortal
            options={sparkHireData?.jobs || []}
            getOptionLabel={(option) => option[0]}
            value={job}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3 }}
            loading={loadingSparkHireData}
            loadingText="Loading jobs..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Job from Spark Hire"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                helperText={errors.job}
                ref={fieldRefs.job}
              />
            )}
            onChange={(_event, value) => setJob(value)}
          />
          <Autocomplete
            disablePortal
            options={sparkHireData?.questions || []}
            getOptionLabel={(option) => option[0]}
            value={question}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3 }}
            loading={loadingSparkHireData}
            loadingText="Loading questions..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Question set"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                required
                helperText={errors.question}
                ref={fieldRefs.question}
              />
            )}
            onChange={(event, value) => setQuestion(value)}
          />
          <MultilineFormTextField
            label="Email message"
            value={emailMessage}
            onChange={(e) => setEmailMessage(e.target.value)}
            fullWidth
            rows={6}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <Stack sx={{ width: '100%' }}>
              <SingleDateTimePicker
                label="Scheduled at"
                inputStyles={{ ...sharedClasses.datePicker, width: '100%', height: '2em' }}
                onChange={(value) => value && setScheduledAt(value)}
                value={scheduledAt}
                disablePast
                required
                innerRef={fieldRefs.scheduledAt}
                error={errors.scheduledAt}
              />
            </Stack>
            <Autocomplete
              disablePortal
              options={sparkHireData?.timezones || []}
              value={timezone}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: '20px' }}
              loading={loadingSparkHireData}
              loadingText="Loading timezones..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingSparkHireData && <CircularProgress color="inherit" size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  sx={{
                    ...styles.autoCompleteTextfield,
                    '& .MuiInputBase-root': { margin: 'unset', marginTop: 0.5 }
                  }}
                  required
                />
              )}
              onChange={(event, value) => setTimezone(value)}
              disableClearable={!!timezone}
            />
          </Stack>
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && sparkHireExport()}
          isLoading={exportingSparkHire}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
