import React, { useRef, useState, useEffect, useCallback } from 'react';
import { styles } from '../styles';
import { Box, Skeleton, Stack, IconButton, Tabs, Tab } from '@mui/material';
import { ArrowCircleLeft as ArrowCircleLeftIcon } from '@mui/icons-material';
import Questions from '../Questions/Questions';
import { IApprovalTemplate } from '../types';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { getPermissions } from '../../../shared/permissionHelpers';
import Api from '../API';
import { IPermissions } from '../../Jobs/types';

const getTabNumber = () => {
  return 0;
};

const BASE_URL = window.location.origin;

export default function ApprovalTemplate({
  apiKey,
  approvalTemplateId,
  entityId,
  enableEmailTemplateFields
}: {
  apiKey: string;
  approvalTemplateId: number;
  entityId: number;
  enableEmailTemplateFields?: boolean;
}) {
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [tabValue, setTabValue] = useState(getTabNumber());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [approvalTemplateData, setApprovalTemplateData] = useState<IApprovalTemplate>({});
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [userPermissions, setUserPermissions] = useState<Record<string, Record<string, boolean>>>(
    {}
  );

  const headerRefs = useRef<HTMLDivElement[]>([]);

  const getUserPermissions = useCallback(async () => {
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      setUserPermissions(allRoles);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting permissions',
        state: 'error'
      });
    }
  }, [apiKey]);

  const getApprovalTemplate = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await Api.getApprovalTemplate(approvalTemplateId);
      setApprovalTemplateData(data.res);
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch approval template data, ${err}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [approvalTemplateId]);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
  }, [tabValue]);

  useEffect(() => {
    getApprovalTemplate();
    getUserPermissions();
  }, [getApprovalTemplate, getUserPermissions]);

  return (
    <Box sx={styles.jobPageContainer}>
      {isLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={styles.jobPageHeader}>
          <h1>{approvalTemplateData.name}</h1>
        </Box>
      )}
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={0}
          aria-label="Edit Approval Template Fields"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { ...styles.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab ref={(el) => (headerRefs.current[0] = el)} label="Add/Edit fields" />
        </Tabs>
      </Box>
      {isLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={styles.backButton}>
          <Stack
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              window.location.href = `${BASE_URL}/admin/requisitions?templates`;
            }}
          >
            <IconButton>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box>Back to approval templates</Box>
          </Stack>
        </Box>
      )}
      <Box>
        {isLoading ? (
          <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
        ) : (
          <Questions
            apiKey={apiKey}
            entityId={entityId}
            approvalTemplateId={approvalTemplateId}
            approvalTemplateData={approvalTemplateData}
            setApprovalTemplateData={setApprovalTemplateData}
            getApprovalTemplate={getApprovalTemplate}
            userPermissions={userPermissions}
            enableEmailTemplateFields={enableEmailTemplateFields}
          />
        )}
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
