import React, { MouseEvent, SyntheticEvent, useEffect, useReducer, useRef, useState } from 'react';
import { Divider, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { Forward as ForwardIcon } from '@mui/icons-material';
import { ApplicationReducer, InitialApplicationState } from './reducer';
import StyledSnackbar, { ISnackbarInput } from '../Components/CustomUIElements/StyledSnackbar';
import { useQuery } from '@tanstack/react-query';
import Api from './API';
import ApplicationHeader from './ApplicationHeader';
import { styles as tabsStyle } from '../Candidate/styles';
import { styles } from './styles';
import OverviewTab from './OverviewTab';
import ActivitiesTab from '../Candidate/ActivitiesTab';
import TasksAndEventsTab from './TasksAndEventsTab';
import SmartFormsTab from './SmartFormsTab/SmartFormsTab';
import SendEmail from './Modals/SendEmail';
import AddToEvent from './Modals/AddToEvent';
import InviteToTimeslot from './Modals/InviteToTimeslot';
import { ModalType } from './config';
import { getPermissions } from '../../shared/permissionHelpers';
import { formatUserPermissions } from '../Components/Utilities/formatUserPermissions';
import AddForm from './Modals/AddForm';
import SendForm from './Modals/SendForm';
import IntegrationsTab from './IntegrationsTab';
import { IApplicationPageProps } from '.';
import { scrollToElement } from '../utils/scroll-to-element';
import GenericDialog from '../Components/Modals/GenericDialog';

import ExportToOnboard from './Modals/ExportToOnboard';
import { useNotepadUpdater } from './helper';

export default function Application({
  applicationId,
  jobId,
  showLinkedInURL,
  aiSummariesEnabled,
  currentUser,
  newIntegrationEnabled
}: IApplicationPageProps) {
  const [ApplicationState, dispatch] = useReducer(ApplicationReducer, InitialApplicationState);
  const { modalsOpen } = ApplicationState;
  const [tabValue, setTabValue] = useState<number>(0);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [pendingHref, setPendingHref] = useState<string | null>(null);
  const {
    overviewTab: { hasUnsavedChanges }
  } = ApplicationState;

  const urlParams = new URLSearchParams(window.location.search);
  const cognology = urlParams.get('cognology'); // used for new approval created from the approval forms page
  const scrollRef = useRef<HTMLDivElement>(null);
  const headerRefs = useRef<HTMLDivElement[]>([]);
  const urlIndex = window.location.href.split('?i=')[1];
  const applicationURL = `${window.location.origin}/admin/jobs/${jobId}/new_applications/`;

  const { data: application, isLoading: loadingApplication } = useQuery({
    queryKey: ['application'],
    queryFn: async () => {
      const { res } = await Api.getApplicationData(jobId, applicationId, urlIndex);
      return res;
    },
    onSuccess: () => {
      if (cognology) {
        setTabValue(4);
        dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY_WAITING_FOR_APPROVAL });
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application data, ${error}`,
          state: 'error'
        }
      })
  });

  useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions(currentUser.api_key);
      return formatUserPermissions(res);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting user permissions, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const { mutate: updateApplicationNotepad, isLoading: updatingApplicationNotepad } =
    useNotepadUpdater({ application, dispatch });

  const handleTabChange = (_e: SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const onConfirmHandler = (shouldSave: boolean) => {
    if (pendingHref === null) return;
    window.location.href = pendingHref;
    shouldSave && updateApplicationNotepad(ApplicationState.overviewTab.note);
  };

  useEffect(
    () =>
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          apiKey: currentUser.api_key,
          isAdminUser: currentUser.admin,
          userTimeZone: currentUser.timeZone,
          userEmail: currentUser.email
        }
      }),
    [currentUser]
  );

  useEffect(() => {
    const isOverviewTab = tabValue === 0 && application;

    if (isOverviewTab) {
      dispatch({
        type: 'SET_OVERVIEW_TAB',
        payload: { note: ApplicationState.overviewTab.note || application.suitability_comment }
      });
    }
  }, [application]);

  useEffect(() => {
    scrollToElement(scrollRef, { block: 'start', behavior: 'instant' });
  }, [tabValue]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = (event.target as HTMLElement).closest('a');
      if (hasUnsavedChanges && target?.href) {
        event.preventDefault();
        setPendingHref(target.href);
        setShowDialog(true);
      }
    };

    document.addEventListener('click', handleClick as unknown as EventListener);

    return () => {
      document.removeEventListener('click', handleClick as unknown as EventListener);
    };
  }, [hasUnsavedChanges]);

  return (
    <Stack sx={{ padding: 5 }} ref={scrollRef}>
      <Stack sx={styles.applicationPageStickyHeader}>
        <ApplicationHeader
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          showLinkedInURL={showLinkedInURL}
        />
        <Stack>
          <Stack sx={{ flexDirection: 'row', columnGap: 5, justifyContent: 'space-between' }}>
            {loadingApplication ? (
              <Skeleton height={30} animation="wave" width="100%" />
            ) : (
              <>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons={false}
                  TabIndicatorProps={{
                    sx: {
                      ...tabsStyle.candidateTabIndicator,
                      maxWidth: headerRefs.current[tabValue]?.clientWidth - 15
                    }
                  }}
                  sx={tabsStyle.candidateTabs}
                >
                  <Tab
                    id="application-overview-tab"
                    label="Overview"
                    ref={(el) => el && (headerRefs.current[0] = el)}
                  />
                  <Tab
                    id="application-tasks-and-events-tab"
                    label="Tasks & Events"
                    ref={(el) => el && (headerRefs.current[1] = el)}
                  />
                  <Tab
                    id="application-smart-forms-tab"
                    label="Smart Forms"
                    ref={(el) => el && (headerRefs.current[2] = el)}
                  />
                  <Tab
                    id="application-activities-tab"
                    label="Activities"
                    ref={(el) => el && (headerRefs.current[3] = el)}
                  />
                  <Tab
                    id="application-integrations-tab"
                    label="Integrations"
                    ref={(el) => el && (headerRefs.current[4] = el)}
                  />
                </Tabs>
                {urlIndex && (
                  <Stack sx={styles.surroundingApplications}>
                    <Stack sx={{ width: 'max-content', fontSize: '16px', fontWeight: 500 }}>
                      {`${Number(urlIndex) + 1} of ${application?.surround_application.total_number_in_current_filter} applications`}
                    </Stack>
                    {application?.surround_application.pre_app_id && (
                      <Stack
                        id="previous-application-button"
                        sx={styles.surroundingApplicationsButton}
                        onClick={() => {
                          window.location.href = `${applicationURL}${application?.surround_application.pre_app_id}?i=${application?.surround_application.pre_idx}`;
                        }}
                      >
                        <ForwardIcon sx={{ transform: 'scaleX(-1)' }} fontSize="small" />
                      </Stack>
                    )}
                    {application?.surround_application.next_app_id && (
                      <Stack
                        id="next-application-button"
                        sx={styles.surroundingApplicationsButton}
                        onClick={() => {
                          window.location.href = `${applicationURL}${application?.surround_application.next_app_id}?i=${application?.surround_application.next_idx}`;
                        }}
                      >
                        <ForwardIcon fontSize="small" />
                      </Stack>
                    )}
                  </Stack>
                )}
              </>
            )}
          </Stack>
          <Divider />
        </Stack>
      </Stack>
      {tabValue === 0 && (
        <OverviewTab
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          aiSummariesEnabled={aiSummariesEnabled}
          updateApplicationNotepad={updateApplicationNotepad}
          updatingApplicationNotepad={updatingApplicationNotepad}
        />
      )}
      {tabValue === 1 && (
        <TasksAndEventsTab
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          currentUserId={currentUser.id}
        />
      )}
      {tabValue === 2 && <SmartFormsTab ApplicationState={ApplicationState} dispatch={dispatch} />}
      {tabValue === 3 && <ActivitiesTab dispatch={dispatch} />}
      {tabValue === 4 && (
        <IntegrationsTab
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          newIntegrationEnabled={newIntegrationEnabled}
        />
      )}
      {modalsOpen === ModalType.SEND_EMAIL && (
        <SendEmail ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.ADD_TO_EVENT && (
        <AddToEvent
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          smsTemplatePermissions={true}
        />
      )}
      {modalsOpen === ModalType.INVITE_TO_TIMESLOT && (
        <InviteToTimeslot
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          smsTemplatePermissions={true}
        />
      )}
      {modalsOpen === ModalType.ADD_FORM && (
        <AddForm ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.SEND_FORM && (
        <SendForm
          ApplicationState={ApplicationState}
          dispatch={dispatch}
          smsTemplatePermissions={true}
        />
      )}
      {modalsOpen === ModalType.EXPORT_TO_ONBOARD && (
        <ExportToOnboard ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {!!ApplicationState.snackbar.message && (
        <StyledSnackbar
          message={ApplicationState.snackbar.message}
          state={ApplicationState.snackbar.state}
          setSnackbarState={(snackbarInput: ISnackbarInput) =>
            dispatch({ type: 'SET_SNACKBAR', payload: snackbarInput })
          }
        />
      )}
      {showDialog && (
        <GenericDialog
          alternateColor
          buttonCallback={() => onConfirmHandler(true)}
          buttonText="Save"
          callbackLoading={updatingApplicationNotepad}
          description="You have unsaved Notepad changes. Would you like to save them before leaving this page?"
          disablePrimaryButton={updatingApplicationNotepad}
          isDialogOpen={showDialog}
          onCancelCallback={() => onConfirmHandler(false)}
          secondaryButtonText="Discard"
          setDialogOpen={setShowDialog}
          title="Unsaved changes"
          url=""
        />
      )}
    </Stack>
  );
}
