import React from 'react';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useQueryClient } from '@tanstack/react-query';
import { IIntegrationsResponse } from '../../types';
import formatToUserTimeZone from '../../../utils/formatToUserTimeZone';

export default function FoundURecord() {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Exported foundU Record</Stack>
      </Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {['foundU Employee ID', 'Exported at'].map((title, index) => (
              <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                <Stack>{title}</Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationsData?.foundu_record && (
            <TableRow>
              <TableCell sx={{ verticalAlign: 'top' }}>
                {integrationsData?.foundu_record.foundu_id}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>
                {formatToUserTimeZone(integrationsData?.foundu_record.created_at)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Stack>
  );
}
