import React from 'react';
import { Link, Stack } from '@mui/material';
import { Cancel as CancelIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { theme } from '../../../../../ThemeContext/ThemeObject';
import { styles } from '../../styles';

export default function CognologyApprovalDetails({
  requisition,
  reapprovalRequired
}: {
  requisition: { url: string; name: string };
  reapprovalRequired: boolean;
}) {
  return (
    <>
      <Stack sx={styles.cognologySectionTitle}>Approval Details</Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Approval form</Stack>
        <Stack>
          <Link href={requisition.url} target="_blank" style={{ textDecoration: 'underline' }}>
            {requisition.name}
          </Link>
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>
          Reapproval required for modifications
        </Stack>
        {reapprovalRequired ? (
          <CheckCircleIcon sx={{ color: theme.palette.action.main }} />
        ) : (
          <CancelIcon sx={{ color: theme.palette.error.main }} />
        )}
      </Stack>
    </>
  );
}
