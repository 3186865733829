import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, Modal, Button, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styles } from '../styles';
import { IApprovalManagers, IApproversData } from '../types';
import Api from '../API';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';

export default function EditApproverModal({
  apiKey,
  approver,
  setModalsOpen,
  modalsOpen,
  setSnackbar,
  requisitionId,
  getApprovalFormData,
  index
}: {
  apiKey: string;
  approver: IApproversData;
  modalsOpen: boolean;
  setModalsOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbar: Dispatch<
    SetStateAction<{
      message: string;
      state: string;
    }>
  >;
  requisitionId: number;
  getApprovalFormData: () => void;
  index: number;
}) {
  const [approvalManagers, setApprovalManagers] = useState<IApprovalManagers[]>([]);
  const [newApprover, setNewApprover] = useState<IApprovalManagers | null>(approver);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleClose = () => {
    setModalsOpen(false);
    setNewApprover(null);
  };

  async function getApprovers() {
    try {
      const data = await Api.getApprovalManagers({
        override_limit: true,
        limit: 2000,
        sort: 'name',
        sort_order: 'asc'
      });
      setApprovalManagers(data.res.requisition_managers);
    } catch (err) {
      setSnackbar({
        message: 'Failed to fetch approvers list',
        state: 'error'
      });
    }
  }

  useEffect(() => {
    getApprovers();
  }, []);

  const updateApprover = async () => {
    setIsUpdating(true);

    try {
      const response = await Api.putApprovalForm(requisitionId, {
        requisition: {
          approvers_attributes: {
            [index]: { email: newApprover?.email, id: approver.id }
          }
        }
      });
      if (response.res.success) {
        setSnackbar({
          message: response.res.success,
          state: 'success'
        });
      }
    } catch (err) {
      setSnackbar({
        message: `Failed to update approver, ${err}`,
        state: 'error'
      });
    } finally {
      setIsUpdating(false);
      setModalsOpen(false);
      getApprovalFormData();
    }
  };

  const isMandatoryApprover = approver.email.includes('*');
  const defaultValue = approvalManagers.find(
    (manager) => (isMandatoryApprover ? approver.email.slice(1) : approver.email) === manager.email
  );

  return (
    <Modal open={modalsOpen}>
      <Box sx={{ ...styles.actionsModal, height: '640px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
            minWidth: '255px'
          }}
        >
          <Box>
            <CloseIcon onClick={() => handleClose()} sx={styles.closeIcon} />
          </Box>
          <Box sx={styles.modalHeader}>Edit/change Approver</Box>
        </Box>
        <Box sx={{ padding: '32px 0px' }}>
          <Autocomplete
            disabled={isMandatoryApprover}
            disablePortal
            options={approvalManagers}
            getOptionLabel={(option) => `${option.name} (${option.email})`}
            value={newApprover || defaultValue}
            onChange={(event, value) => (value ? setNewApprover(value) : setNewApprover(null))}
            sx={sharedClasses.formAutocomplete}
            ListboxProps={{
              style: {
                fontFamily: 'Source Sans Pro, sans-serif',
                color: '#333333',
                maxHeight: '200px'
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Change approver"
                InputLabelProps={{ shrink: true }}
                placeholder="Please select"
              />
            )}
          />
        </Box>
        <Box sx={{ ...styles.modalActions, marginBottom: 2 }}>
          <Button
            disableElevation
            sx={{ ...styles.buttonRedLight }}
            variant="text"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            id="approval-form-edit-approver-button"
            type="submit"
            disabled={isUpdating}
            sx={styles.modalEditButton}
            onClick={() => updateApprover()}
          >
            {isUpdating ? <CircularProgress size={20} color="inherit" /> : 'Save'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
