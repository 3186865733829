"use strict";
import RecruitApiClient from "../../RecruitApiClient";
const BASE_URL = "/api/v4";
class Api {
  constructor() {
    this.getEntityApprovers = () => RecruitApiClient.get(`${BASE_URL}/requisition_managers/entity_default_approvers`);
    this.getApprovalManagers = (params) => RecruitApiClient.get(`${BASE_URL}/requisition_managers`, params);
    this.createApprovalManager = (params) => RecruitApiClient.post(`${BASE_URL}/requisition_managers`, params);
    this.deleteApprovalManager = (approvalManagerId) => RecruitApiClient.delete(`${BASE_URL}/requisition_managers/${approvalManagerId}`);
    this.deleteApprovalManagers = (params) => RecruitApiClient.delete(`${BASE_URL}/requisition_managers/bulk_destroy`, params);
    this.updateApprovalManager = (params, approvalManagerId) => RecruitApiClient.put(`${BASE_URL}/requisition_managers/${approvalManagerId}`, params);
    this.getEmailTemplates = () => RecruitApiClient.get(`${BASE_URL}/requisition_forms/email_templates`);
    this.getEnabledApprovalTemplates = () => RecruitApiClient.get(`${BASE_URL}/requisition_forms/enabled_for_applications`);
    this.createApprovalTemplate = (params) => RecruitApiClient.post(`${BASE_URL}/requisition_forms`, params);
    this.updateApprovalTemplate = (approvalTemplateId, params) => RecruitApiClient.put(`${BASE_URL}/requisition_forms/${approvalTemplateId}`, params);
    this.cloneApprovalTemplate = (approvalTemplateId, params) => RecruitApiClient.post(`${BASE_URL}/requisition_forms/${approvalTemplateId}/clone`, params);
    this.getApprovalTemplate = (approvalTemplateId) => RecruitApiClient.get(`${BASE_URL}/requisition_forms/${approvalTemplateId}`);
    this.getApprovalTemplates = (params) => RecruitApiClient.get(`${BASE_URL}/requisition_forms`, params);
    this.getApprovalForms = (params) => RecruitApiClient.get(`${BASE_URL}/requisitions`, params);
    this.getApprovalForm = (approvalFormId) => RecruitApiClient.get(`${BASE_URL}/requisitions/${approvalFormId}`);
    this.putApprovalForm = (approvalFormId, params) => RecruitApiClient.put(`${BASE_URL}/requisitions/${approvalFormId}`, params);
    this.postResendEmail = (approvalFormId, params) => RecruitApiClient.post(`${BASE_URL}/requisitions/${approvalFormId}/resend_email`, params);
    this.cloneApprovalForm = (approvalFormId) => RecruitApiClient.post(`${BASE_URL}/requisitions/${approvalFormId}/clone`);
    this.generateBulkPdf = (approvalFormId) => RecruitApiClient.post(`${BASE_URL}/requisitions/${approvalFormId}/bulk_pdf`);
    this.getTargetEntities = () => RecruitApiClient.get(`${BASE_URL}/target_entities`);
    this.moveApprovalTemplate = (approvalTemplateId, entityId) => RecruitApiClient.post(
      `${BASE_URL}/requisition_forms/${approvalTemplateId}/move?requisition_form[entity_id]=${entityId}`
    );
    this.getCreators = () => RecruitApiClient.get(`${BASE_URL}/requisition_forms/creators`);
    this.getApprovalPublicLinks = (params) => RecruitApiClient.get(`${BASE_URL}/requisition_public_links`, params);
    this.createApprovalPublicLink = (params) => RecruitApiClient.post(`${BASE_URL}/requisition_public_links`, params);
    this.updateApprovalPublicLink = (publicLinkId, params) => RecruitApiClient.put(`${BASE_URL}/requisition_public_links/${publicLinkId}`, params);
    this.deleteApprovalPublicLinks = (params) => RecruitApiClient.delete(`${BASE_URL}/requisition_public_links/bulk_destroy`, params);
    this.newField = (approvalTemplateId, params) => RecruitApiClient.post(`${BASE_URL}/fields?requisition_form_id=${approvalTemplateId}`, params);
    this.addSystemField = (approvalTemplateId, systemFieldId) => RecruitApiClient.post(
      `${BASE_URL}/system_fields?requisition_form_id=${approvalTemplateId}&system_field_id=${systemFieldId}`
    );
    this.getQuestions = (params) => RecruitApiClient.get(`${BASE_URL}/fields`, params);
    this.deleteQuestion = (id, approvalTemplateId) => RecruitApiClient.delete(`${BASE_URL}/fields/${id}?requisition_form_id=${approvalTemplateId}`);
    this.cloneQuestion = (id, approvalTemplateId) => RecruitApiClient.post(
      `${BASE_URL}/fields/${id}/clone?requisition_form_id=${approvalTemplateId}`
    );
    this.putQuestion = (id, approvalTemplateId, params) => RecruitApiClient.put(
      `${BASE_URL}/fields/${id}?requisition_form_id=${approvalTemplateId}`,
      params
    );
    this.postArchiveApprovalForms = (params) => RecruitApiClient.post(`${BASE_URL}/requisitions/bulk_archive`, params);
    this.postUnarchiveApprovalForms = (params) => RecruitApiClient.post(`${BASE_URL}/requisitions/bulk_unarchive`, params);
    this.deleteTrashApprovalForms = (params) => RecruitApiClient.delete(`${BASE_URL}/requisitions/bulk_destroy`, params);
    this.postArchiveApprovalTemplates = (params) => RecruitApiClient.post(`${BASE_URL}/requisition_forms/bulk_archive`, params);
    this.postUnarchiveApprovalTemplates = (params) => RecruitApiClient.post(`${BASE_URL}/requisition_forms/bulk_unarchive`, params);
    this.deleteTrashApprovalTemplates = (params) => RecruitApiClient.delete(`${BASE_URL}/requisition_forms/bulk_destroy`, params);
    this.getAssignableUsers = () => RecruitApiClient.get(`${BASE_URL}/assignable_users`);
    this.getCSVData = (questionId, positionId) => RecruitApiClient.get(`${BASE_URL}/system_fields/${questionId}/remote`, {
      position_id: positionId,
      new_position: false
    });
    this.getJobs = () => RecruitApiClient.get(`${BASE_URL}/jobs/jobs_for_requisitions`);
    this.getApplications = (jobId) => RecruitApiClient.get(`${BASE_URL}/jobs/${jobId}/applications_for_requisition`);
    this.getFilters = (url, params) => RecruitApiClient.get(`${BASE_URL}/requisitions/${url}`, params);
  }
}
export default new Api();
