import React, { useState, Dispatch } from 'react';
import { Stack, Skeleton } from '@mui/material';
import OldIntegrationsLink from './OldIntegrationsLink';
import ExportButton from './ExportButton';
import OnboardExpressLogo from './Logos/OnboardExpressLogo.png';
import XrefLogo from './Logos/XrefLogo.png';
import CognologyLogo from './Logos/CognologyLogo.png';
import MvsLogo from './Logos/MvsLogo.png';
import ReferooLogo from './Logos/ReferooLogo.png';
import FoundULogo from './Logos/FoundULogo.png';
import TestgridLogo from './Logos/TestgridLogo.png';
import HronboardLogo from './Logos/HronboardLogo.png';
import RoublerLogo from './Logos/RoublerLogo.png';
import SparkHireLogo from './Logos/SparkHireLogo.png';
import Fit2WorkLogo from './Logos/Fit2WorkLogo.png';
import AlayaCareLogo from './Logos/AlayaCareLogo.png';
import { ApplicationState, ApplicationAction } from '../types';
import { ModalType } from '../config';
import OnboardExpress from './Modals/OnboardExpress';
import XRef from './Modals/XRef';
import Cognology from './Modals/Cognology/Cognology';
import Mvs from './Modals/Mvs';
import Referoo from './Modals/Referoo';
import FoundU from './Modals/FoundU';
import Testgrid from './Modals/Testgrid';
import Hronboard from './Modals/Hronboard';
import Roubler from './Modals/Roubler';
import SparkHire from './Modals/SparkHire';
import Fit2Work from './Modals/Fit2Work';
import AlayaCare from './Modals/AlayaCare';
import { ScoutOnboardLogo } from './Logos/Logos';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';
import { styles } from './styles';
import { IApplication } from '../types';
import UnusualActivity from './Modals/UnusualActivity';
import AlreadyExportedModal, { AlreadyExportedModalProps } from './Modals/AlreadyExportedModal';
import XrefRecord from './Records/XrefRecord';
import ReferooRecord from './Records/ReferooRecord';
import MvsRecord from './Records/MvsRecord';
import Fit2WorkRecord from './Records/Fit2WorkRecord';
import TestgridRecord from './Records/TestgridRecord';
import SparkHireRecord from './Records/SparkHireRecord';
import { useGetIntegrationsData, useGetCognologyData } from '../helper';
import CognologyAfterApprovalModal from './Modals/Cognology/CognologyAfterApprovalModal';
import CognologyWaitingForApprovalModal from './Modals/Cognology/CognologyWaitingForApprovalModal';
import { isIntegrationAvailable } from './helper';
import FoundURecord from './Records/FoundURecord';

export default function IntegrationsTab({
  ApplicationState,
  dispatch,
  newIntegrationEnabled
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  newIntegrationEnabled: boolean;
}) {
  const [alreadyExportedModalOpen, setAlreadyExportedModalOpen] =
    useState<AlreadyExportedModalProps>({
      revolve: false,
      referoo: false,
      hro: false,
      sparkhire: false,
      cognology: false,
      foundu: false
    });
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);
  const urlParams = new URL(window.location.href);

  const { integrationsData, loadingIntegrationsData } = useGetIntegrationsData({
    applicationId: application?.id,
    dispatch
  });

  const { cognologyData } = useGetCognologyData({
    applicationId: application?.id,
    dispatch,
    enabled:
      application?.integrations.cognology &&
      !!integrationsData &&
      !integrationsData.cognology_login_url
  });

  const cognologyAction = () => {
    if (integrationsData?.cognology_record_exported) {
      setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, cognology: true });
    } else {
      if (integrationsData?.cognology_record_exported === null) {
        dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY });
      } else {
        if (cognologyData?.record.status === 'approved') {
          dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY_APPROVED });
        } else {
          dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY_WAITING_FOR_APPROVAL });
        }
      }
    }
  };

  if (!application || loadingIntegrationsData)
    return (
      <Stack sx={{ rowGap: 2 }}>
        {[...Array(2)].map((_, index) => (
          <Skeleton key={index} sx={{ transform: 'unset', height: '50px' }} animation="wave" />
        ))}
      </Stack>
    );

  return (
    <Stack sx={{ rowGap: 5 }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 4, flexWrap: 'wrap', rowGap: 4 }}>
        {isIntegrationAvailable('revolve', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={styles.integrationCardImageContainer}
              component="img"
              src={OnboardExpressLogo}
            />
            <ExportButton
              onClick={() =>
                application.revolve_record_id
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, revolve: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ONBOARD_EXPRESS })
              }
            />
          </Stack>
        )}
        {isIntegrationAvailable(
          'scoutOnboard',
          newIntegrationEnabled,
          permissions,
          application
        ) && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={styles.integrationCardImageContainer}>
              <ScoutOnboardLogo />
            </Stack>
            <ExportButton
              onClick={() =>
                dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EXPORT_TO_ONBOARD })
              }
            />
          </Stack>
        )}
        {isIntegrationAvailable('xref', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
              component="img"
              src={XrefLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.XREF })}
            />
          </Stack>
        )}
        {isIntegrationAvailable('mvs', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
              component="img"
              src={MvsLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.MVS })}
            />
          </Stack>
        )}
        {isIntegrationAvailable('referoo', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '5.5rem' }}
              component="img"
              src={ReferooLogo}
            />
            <ExportButton
              onClick={() => {
                if (integrationsData?.referoo_login_url)
                  window.open(integrationsData?.referoo_login_url);
                else {
                  integrationsData?.referoo_record
                    ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, referoo: true })
                    : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.REFEROO });
                }
              }}
            />
          </Stack>
        )}
        {isIntegrationAvailable('foundU', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '3.5rem' }}
              component="img"
              src={FoundULogo}
            />
            <ExportButton
              onClick={() => {
                integrationsData?.foundu_record
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, foundu: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.FOUNDU });
              }}
            />
          </Stack>
        )}
        {isIntegrationAvailable('testGrid', newIntegrationEnabled, permissions, application) && (

          <Stack sx={styles.integrationCard}>
            <Stack sx={{ padding: '8px 0px', width: '5rem' }} component="img" src={TestgridLogo} />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.TESTGRID })}
            />
          </Stack>
        )}
        {isIntegrationAvailable('hro', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={{ padding: '8px 0px', width: '7rem' }} component="img" src={HronboardLogo} />
            <ExportButton
              onClick={() =>
                integrationsData?.hro_record_exported
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, hro: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.HRONBOARD })
              }
            />
          </Stack>
        )}
        {isIntegrationAvailable('cognology', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '6rem', padding: '8px 0px' }}
              component="img"
              src={CognologyLogo}
            />
            <ExportButton
              onClick={() =>
                integrationsData?.cognology_login_url
                  ? window.open(integrationsData?.cognology_login_url)
                  : cognologyAction()
              }
            />
          </Stack>
        )}
        {isIntegrationAvailable('roubler', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '6rem' }}
              component="img"
              src={RoublerLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ROUBLER })}
            />
          </Stack>
        )}
        {isIntegrationAvailable('sparkhire', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '12px 0px', width: '7rem' }}
              component="img"
              src={SparkHireLogo}
            />
            <ExportButton
              onClick={() =>
                integrationsData?.sparkhire_record
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, sparkhire: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SPARKHIRE })
              }
            />
          </Stack>
        )}
        {isIntegrationAvailable('fit2work', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '12px 0px', width: '6rem' }}
              component="img"
              src={Fit2WorkLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.FIT2WORK })}
            />
          </Stack>
        )}
        {isIntegrationAvailable('alayacare', newIntegrationEnabled, permissions, application) && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '6rem' }}
              component="img"
              src={AlayaCareLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ALAYACARE })}
            />
          </Stack>
        )}
      </Stack>
      {isIntegrationAvailable('referoo', newIntegrationEnabled, permissions, application) &&
        integrationsData?.referoo_record && <ReferooRecord dispatch={dispatch} />}
      {isIntegrationAvailable('xref', newIntegrationEnabled, permissions, application) &&
        !!integrationsData?.xref_records.length && <XrefRecord dispatch={dispatch} />}
      {isIntegrationAvailable('mvs', newIntegrationEnabled, permissions, application) &&
        !!integrationsData?.mvs_requests.length && <MvsRecord />}
      {isIntegrationAvailable('fit2work', newIntegrationEnabled, permissions, application) &&
        !!integrationsData?.fit2_work_records.length && <Fit2WorkRecord dispatch={dispatch} />}
      {isIntegrationAvailable('sparkhire', newIntegrationEnabled, permissions, application) && (
        <SparkHireRecord />
      )}
      {isIntegrationAvailable('testGrid', newIntegrationEnabled, permissions, application) && (
        <TestgridRecord />
      )}
      {isIntegrationAvailable('foundU', newIntegrationEnabled, permissions, application) &&
        !!integrationsData?.foundu_record && <FoundURecord />}
      <OldIntegrationsLink />
      {modalsOpen === ModalType.ONBOARD_EXPRESS && (
        <OnboardExpress ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.XREF && (
        <XRef ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {(modalsOpen === ModalType.COGNOLOGY || modalsOpen === ModalType.EDIT_COGNOLOGY) && (
        <Cognology ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.COGNOLOGY_WAITING_FOR_APPROVAL && (
        <CognologyWaitingForApprovalModal
          open={modalsOpen === ModalType.COGNOLOGY_WAITING_FOR_APPROVAL}
          onClose={() => {
            dispatch({ type: 'SET_MODALS_OPEN', payload: null });
            urlParams.search = '';
            window.history.pushState(null, '', urlParams.href);
          }}
        />
      )}
      {modalsOpen === ModalType.COGNOLOGY_APPROVED && (
        <CognologyAfterApprovalModal
          open={modalsOpen === ModalType.COGNOLOGY_APPROVED}
          onClose={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          dispatch={dispatch}
        />
      )}
      {modalsOpen === ModalType.MVS && (
        <Mvs ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.REFEROO && (
        <Referoo ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.FOUNDU && (
        <FoundU ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.TESTGRID && (
        <Testgrid ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.HRONBOARD && (
        <Hronboard ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.ROUBLER && (
        <Roubler ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.SPARKHIRE && (
        <SparkHire ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.FIT2WORK && (
        <Fit2Work ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <AlayaCare ApplicationState={ApplicationState} dispatch={dispatch} />
      <AlreadyExportedModal
        alreadyExportedModalOpen={alreadyExportedModalOpen}
        onClose={() =>
          setAlreadyExportedModalOpen({
            revolve: false,
            referoo: false,
            hro: false,
            sparkhire: false,
            cognology: false,
            foundu: false
          })
        }
        dispatch={dispatch}
        buttonName={alreadyExportedModalOpen.cognology ? 'Make changes and re-export' : undefined}
        callback={() =>
          alreadyExportedModalOpen.cognology
            ? dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY })
            : undefined
        }
      />
      {modalsOpen === ModalType.UNUSUAL_ACTIVITY && (
        <UnusualActivity ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
    </Stack>
  );
}
