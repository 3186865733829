import React, { useState, Dispatch } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { ControlledStaticDatePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { classes } from '../../Job/Applications/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../API';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { ModalType } from '../config';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { styles } from './styles';
import SmsTemplateComponent from '../../Components/CustomUIElements/SmsTemplateComponent';
import { useGetEmailTemplate, useGetEvents, useGetSmsTemplatePlaceholders } from '../helper';
import { useDispatch } from '../../hooks/useDispatch';
import EmailsDropdown from './Components/EmailsDropdown';
import EmailBodyComponent from './Components/EmailBodyComponent';
import NotificationOptionSwitch from './Components/NotificationOptionsSwitch';
import { ISmsTemplate } from '../../Job/Applications/Modals/SMSCandidates';
import formatToUserTimeZone from '../../utils/formatToUserTimeZone';

export default function AddToEvent({
  ApplicationState,
  dispatch,
  smsTemplatePermissions
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  smsTemplatePermissions: boolean;
}) {
  const [selectedDay, setSelectedDay] = useState<Date | null>(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [notification, setNotification] = useState<string>('email');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [smsTemplate, setSmsTemplate] = useState<ISmsTemplate | null>(null);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string | null>('');
  const [body, setBody] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { modalsOpen, apiKey } = ApplicationState;

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  useGetEmailTemplate({ apiKey, selectedEmailTemplate, setBody, dispatch });
  const { displayError, displaySuccess } = useDispatch(dispatch);
  const { events, loadingEvents } = useGetEvents({ apiKey, dispatch });
  const { placeholders } = useGetSmsTemplatePlaceholders({ kind: 'event', dispatch });

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplate || !application) return;
      const res = await API.sendEmail(
        application.job.id,
        selectedTimeSlot,
        Number(selectedEmailTemplate.split(' -')[0]),
        body,
        [application.id]
      );
      return res;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['application events'], { exact: true });
      displaySuccess('Add to event email notification has been sent successfully');
      dispatch({ type: 'SET_MODALS_OPEN', payload: null });
    },
    onError: (error: { success: boolean; message: string }) => displayError(error.message)
  });

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      if (application) {
        const res = await API.addToEventSms(
          application.job.id,
          selectedTimeSlot,
          [application.id],
          smsMessage,
          smsTemplate?.id
        );
        if (res.success !== 0) {
          queryClient.invalidateQueries(['application events'], { exact: true });
          displaySuccess('Add to event SMS notification has been sent successfully');
          dispatch({ type: 'SET_MODALS_OPEN', payload: null });
        } else {
          displayError('There is an error in sending SMS notification');
        }
        return res;
      }
    }
  });

  const selectedDayEvents = events?.filter((e) => {
    if (!selectedDay) return false;
    return formatToUserTimeZone(e.begins_at, 'ymd') === formatToUserTimeZone(selectedDay, 'ymd');
  });

  const shouldDisableDate = (date: Date) => {
    if (!events) return true;
    return !events
      .map((e) => formatToUserTimeZone(e.begins_at, 'ymd'))
      .includes(formatToUserTimeZone(date, 'ymd'));
  };

  const buttonName = step === 1 ? 'Next' : 'Send';

  const eventCandidates = events
    ?.find((e) => e.id === selectedTimeSlot)
    ?.attendees.filter((a) => a.type === 'Candidate')
    .map((c) => c.application_id);

  const candidateAlreadyBooked = application && eventCandidates?.includes(application.id);

  const validateInput = () => {
    const fieldsErrors: Record<string, string> = {};
    if (notification === 'email') {
      !selectedEmailTemplate && (fieldsErrors.template = 'Must select a template');
      !body.trim() && (fieldsErrors.body = 'Must add email body');
    } else {
      !smsMessage.trim() && (fieldsErrors.sms = 'Must add SMS content');
    }
    setErrors(fieldsErrors);
    return !Object.keys(fieldsErrors).length;
  };

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      notification === 'email' ? validateInput() && sendEmail() : validateInput() && sendSms();
    }
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.ADD_TO_EVENT}
      label="Add to event modal"
      handleClose={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
      styleOverrides={{ ...styles.modalStyleOverrides, width: '900px' }}
      disableEnforceFocus
    >
      <Stack sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Stack id="add-to-event-title" sx={{ ...classes.actionModalHeader, paddingBottom: 3 }}>
          Add to Event
        </Stack>
        {step === 1 ? (
          <Stack sx={{ flexGrow: 1, rowGap: 1, overflow: 'auto', paddingBottom: 10 }}>
            <Stack sx={{ paddingLeft: 1, rowGap: 0.5 }}>
              <Stack sx={{ color: '#939393' }}>Timeslot availability:</Stack>
              <Stack>
                {selectedTimeSlot ? (
                  <Stack
                    sx={{
                      ...styles.timeslotAvailabilityButton,
                      background: candidateAlreadyBooked ? '#E5E5E5' : '#EFFDF3',
                      color: candidateAlreadyBooked ? '#939393' : '#8CCF95'
                    }}
                  >
                    {candidateAlreadyBooked ? 'BOOKED' : 'AVAILABLE'}
                  </Stack>
                ) : (
                  <Stack sx={{ padding: '6px 0px' }}>-</Stack>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Box sx={classes.calendar}>
                <ControlledStaticDatePicker
                  displayStaticWrapperAs="desktop"
                  views={['day']}
                  value={selectedDay}
                  disablePast
                  onChange={(value) => setSelectedDay(value)}
                  shouldDisableDate={shouldDisableDate}
                />
              </Box>
              <Box sx={classes.dailyTimeslots}>
                <Box sx={{ ...classes.dailyTimeslotsHeader, marginTop: 2, marginBottom: '20px' }}>
                  Choose available timeslot
                </Box>
                {loadingEvents ? (
                  <Box sx={classes.noTimeslots}>
                    <CircularProgress size={32} />
                  </Box>
                ) : (
                  <Stack sx={classes.addToEventTimeslotsContainer}>
                    {selectedDayEvents?.map((e) => {
                      const candidates = e.attendees.filter((a) => a.type === 'Candidate');
                      const fullyBooked = candidates.length === e.max_candidates;
                      return (
                        <Stack
                          key={e.id}
                          sx={{
                            ...classes.addToEventTimeslot,
                            border: `1px solid ${e.id === selectedTimeSlot ? '#5BC4C0' : '#DDDDDD'}`,
                            color: `${fullyBooked ? '#939393' : '#666666'}`,
                            background: `${fullyBooked ? '#EAEEF0' : 'inherit'}`,
                            cursor: `${fullyBooked ? 'unset' : 'pointer'}`
                          }}
                          onClick={() => {
                            if (fullyBooked) return;
                            setSelectedTimeSlot(e.id);
                          }}
                        >
                          <Stack sx={{ rowGap: '4px' }}>
                            <Stack sx={{ fontSize: '14px' }}>
                              {formatToUserTimeZone(e.begins_at, 'onlyTime')}
                            </Stack>
                            <Stack sx={{ fontSize: '12px' }}>{e.subject}</Stack>
                          </Stack>
                          <Stack sx={{ color: `${fullyBooked ? '#939393' : '#084D6D'}` }}>
                            {candidates.length}/{e.max_candidates} BOOKED
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Stack sx={classes.addToEventSecondStepContainer}>
            <NotificationOptionSwitch
              setNotification={(value) => {
                setNotification(value);
                setErrors({});
              }}
              notification={notification}
            />
            {notification === 'email' ? (
              <Stack sx={{ paddingTop: 4, rowGap: 3 }}>
                <EmailsDropdown
                  apiKey={apiKey}
                  selectedEmailTemplate={selectedEmailTemplate}
                  setSelectedEmailTemplate={setSelectedEmailTemplate}
                  setBody={setBody}
                  dispatch={dispatch}
                  error={errors.template}
                  required
                  type="no_interview_urls"
                />
                <EmailBodyComponent body={body} setBody={setBody} error={errors.body} />
              </Stack>
            ) : (
              <SmsTemplateComponent
                smsMessageError={errors.sms}
                {...{
                  smsTemplatePermissions,
                  smsMessage,
                  setSmsMessage,
                  smsTemplate,
                  setSmsTemplate,
                  dispatch,
                  placeholders
                }}
              />
            )}
          </Stack>
        )}
        <Box sx={{ ...classes.modalFooter, columnGap: 1, paddingTop: 2 }}>
          <Button
            id="close-add-to-event-modal-button"
            sx={classes.modalCancelButton}
            onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          >
            Cancel
          </Button>
          {step !== 1 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            id="add-to-event-submit-button"
            type="submit"
            sx={{
              ...sharedClasses.genericButton,
              '&.Mui-disabled ': { background: '#DDDDDD', color: '#939393' }
            }}
            onClick={handleSubmit}
            disabled={step === 1 ? !selectedTimeSlot || candidateAlreadyBooked : false}
          >
            {sendingSms || sendingEmail ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              buttonName
            )}
          </Button>
        </Box>
      </Stack>
    </StyledModal>
  );
}
