import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Select,
  Skeleton,
  MenuItem,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  Tooltip
} from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { IApprovalManagers, IApprovalManagersTableProps } from '../types';
import { approvalManagersTableHeaders } from '../config';
import { classes } from '../../Job/Applications/styles';
import GenericDialog from '../../Components/Modals/GenericDialog';
import NewApprovalManager from './NewApprovalManager';
import Api from '../API';

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

export default function ApprovalManagersTable({
  apiKey,
  approvalManagers,
  density,
  isLoading,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  rowsPerPage,
  setRowsPerPage,
  totalPages,
  currentPage,
  setCurrentPage,
  totalApprovalManagers,
  selected,
  setSelected,
  globalSelected,
  setGlobalSelected,
  selectAllIsChecked,
  setSelectAllIsChecked,
  selectAllIsIndeterminate,
  setSelectAllIsIndeterminate,
  getApprovalManagers,
  userPermissions,
  setSnackbar,
  isApprovalManagerDialogOpen,
  setIsApprovalManagerDialogOpen,
  getAllApprovalManagers
}: IApprovalManagersTableProps) {
  const [selectAllApprovalManagers, setSelectAllApprovalManagers] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteApprovalManagers, setDeleteApprovalManagers] = useState<number[]>([]);
  const [editedManager, setEditedManager] = useState<IApprovalManagers | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isFetchingAllApprovalManagers, setIsFetchingAllApprovalManagers] =
    useState<boolean>(false);

  const handleDeleteApprovalManager = async (id: number) => {
    setIsDeleting(true);
    const approvalManagerId = id;
    try {
      const { res } = await Api.deleteApprovalManager(approvalManagerId);
      if (res.success) {
        setSnackbar({
          message: `Approval manager has been successfully deleted.`,
          state: 'success'
        });
        getApprovalManagers(null, null, null, null);
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to delete approval manager, ${error?.errors}`,
        state: 'error'
      });
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      setSelected([]);
      setGlobalSelected([]);
    }
  };

  const handleDeleteMultipleManagers = async (ids: number[]) => {
    setIsDeleting(true);
    try {
      const { res } = await Api.deleteApprovalManagers(
        { requisition_manager_ids: ids }
      );
      if (res.requisition_manager_ids.failure.length) {
        setSnackbar({
          message: `Failed to delete the approval manager id(s): ${res.requisition_manager_ids.failure.join(
            ', '
          )}`,
          state: 'error'
        });
      } else {
        setSnackbar({
          message: `Approval managers have been successfully deleted.`,
          state: 'success'
        });
        getApprovalManagers(null, null, null, null);
      }
    } catch (error) {
      setSnackbar({
        message: `Failed to delete the approval managers, ${error?.errors}`,
        state: 'error'
      });
    } finally {
      setIsDeleting(false);
      setIsDialogOpen(false);
      setSelected([]);
      setGlobalSelected([]);
    }
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(columnValue);
    getApprovalManagers(null, null, null, { sortBy: columnValue, sortOrder: newSortOrder });
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    approvalManager: IApprovalManagers
  ) => {
    let newGlobalSelected = [...globalSelected];
    event.target.checked
      ? newGlobalSelected.push(approvalManager)
      : (newGlobalSelected = newGlobalSelected.filter((app) => app.id !== approvalManager.id));
    const newSelected = newGlobalSelected.map((app) => app.id);
    setSelected(newSelected);
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(
      approvalManagers.map((app) => app.id).every((id) => newSelected.includes(id))
    );
    setSelectAllIsIndeterminate(
      approvalManagers.map((app) => app.id).some((id) => newSelected.includes(id))
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newGlobalSelected = globalSelected.filter(
      (currentApp) => !approvalManagers.map((app) => app.id).includes(currentApp.id)
    );
    if (event.target.checked) {
      const selectAllGlobalSelected = [...newGlobalSelected, ...approvalManagers];
      setSelected(selectAllGlobalSelected.map((approvalManager) => approvalManager.id));
      setGlobalSelected(selectAllGlobalSelected);
      setSelectAllIsChecked(true);
      setSelectAllIsIndeterminate(false);
      setSelectAllApprovalManagers(true);
      return;
    }
    setSelected(newGlobalSelected.map((approvalManager) => approvalManager.id));
    setGlobalSelected(newGlobalSelected);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
    setSelectAllApprovalManagers(false);
  };

  const handleSelectAllApprovalManagers = async () => {
    setIsFetchingAllApprovalManagers(true);
    const allApprovalManagers = await getAllApprovalManagers();
    if (allApprovalManagers) {
      setGlobalSelected(allApprovalManagers);
      setSelected(allApprovalManagers.map((manager) => manager.id));
      setSelectAllIsChecked(true);
      setSelectAllApprovalManagers(true);
      setSelectAllIsIndeterminate(false);
    }
    setIsFetchingAllApprovalManagers(false);
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getApprovalManagers(1, selection, null, null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    sessionStorage.setItem(`approvalManagersTablePage`, newPage);
    getApprovalManagers(newPage, null, null, null);
  };

  const clearAllSelection = () => {
    setSelectAllApprovalManagers(false);
    setGlobalSelected([]);
    setSelected([]);
    setSelectAllIsChecked(false);
    setSelectAllIsIndeterminate(false);
  };

  const handleEditManager = (manager: IApprovalManagers) => {
    setIsApprovalManagerDialogOpen(true);
    setEditedManager(manager);
  };

  const displaySelectAll =
    selectAllApprovalManagers &&
    globalSelected.length !== totalApprovalManagers &&
    totalApprovalManagers > rowsPerPage;

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <TableContainer sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}>
          <Table
            sx={{
              ...classes.tableWrapper,
              minWidth: 'unset',
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="approval managers"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                  <Checkbox
                    id="select-all-checkbox"
                    sx={classes.checkbox}
                    indeterminate={!selectAllIsChecked && selectAllIsIndeterminate}
                    checked={selectAllIsChecked}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all approval managers'
                    }}
                  />
                </TableCell>
                {approvalManagersTableHeaders.map((header) => (
                  <TableCell key={header.name}>
                    <TableSortLabel
                      active={sortBy === header.name}
                      direction={sortBy === header.name ? (sortOrder as 'asc' | 'desc') : 'asc'}
                      onClick={(event) => handleSortChange(event, header.name)}
                      sx={header.sortable ? classes.sortable : null}
                      disabled={!header.sortable}
                      id={`${header.name}-sort-label-approval-managers`}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalManagers &&
                !isLoading &&
                approvalManagers.map((manager) => (
                  <TableRow key={manager.id}>
                    <TableCell sx={{ ...classes.sticky, width: '58px' }}>
                      <Checkbox
                        id={`select-checkbox-${manager.id}`}
                        sx={classes.checkbox}
                        checked={selected.includes(manager.id)}
                        onChange={(event) => handleSelectOne(event, manager)}
                        inputProps={{
                          'aria-label': `select approval managers ${manager.name}`
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ minWidth: '150px' }}>{manager.name}</TableCell>
                    <TableCell sx={{ minWidth: '200px' }}>{manager.email}</TableCell>
                    <TableCell>
                      <Box sx={classes.actionItems}>
                        <Tooltip placement="top" title="Edit" arrow>
                          <IconButton
                            className="approval-manager-edit-button"
                            onClick={() => handleEditManager(manager)}
                          >
                            <EditIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Delete" arrow>
                          <IconButton
                            className="approval-manager-delete-button"
                            sx={{ color: '#E37D7A' }}
                            onClick={() => {
                              setIsDialogOpen(true);
                              setDeleteApprovalManagers([manager.id]);
                            }}
                          >
                            <DeleteIcon sx={{ fontSize: '18px' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!isLoading && approvalManagers?.length === 0 && (
            <Box sx={classes.noApplications}>No Approval Managers found</Box>
          )}
          {isLoading && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={handlePageChange}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalApprovalManagers} Total</span>
        </Box>
      </Box>
      <Box
        id="selected-tooltip"
        sx={
          globalSelected?.length > 0
            ? { ...classes.selectedTooltip, ...classes.fadeIn }
            : { ...classes.selectedTooltip, ...classes.fadeOut }
        }
      >
        <Box
          id="selected-tooltip-text"
          sx={{
            display: 'flex',
            alignItems: 'center',
            ...classes.selectedTooltipText
          }}
        >
          {globalSelected.length === totalApprovalManagers
            ? `All ${globalSelected?.length} approval managers are selected`
            : `${globalSelected?.length} selected`}
          <IconButton
            size="small"
            sx={{
              ...classes.selectedTooltipText,
              ...classes.selectedAllTooltipText,
              marginLeft: 1,
              color: '#666666'
            }}
            onClick={clearAllSelection}
          >
            Clear selection
          </IconButton>
        </Box>
        {displaySelectAll && (
          <IconButton
            disabled={isFetchingAllApprovalManagers}
            size="small"
            sx={{ ...classes.selectedTooltipText, ...classes.selectedAllTooltipText }}
            onClick={handleSelectAllApprovalManagers}
          >
            Select all {totalApprovalManagers} approval managers
          </IconButton>
        )}
        <Box sx={classes.selectedTooltipButtonsContainer}>
          <Button
            variant="contained"
            sx={classes.selectedTooltipButton}
            onClick={() => {
              setIsDialogOpen(true);
              setDeleteApprovalManagers(selected);
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
      <NewApprovalManager
        apiKey={apiKey}
        setSnackbar={setSnackbar}
        isApprovalManagerDialogOpen={isApprovalManagerDialogOpen}
        setIsApprovalManagerDialogOpen={setIsApprovalManagerDialogOpen}
        editedManager={editedManager}
        setEditedManager={setEditedManager}
        getApprovalManagers={getApprovalManagers}
      />
      <GenericDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        title={`Are you sure you want to delete ${
          deleteApprovalManagers.length > 1 ? 'these' : 'this'
        } approval ${deleteApprovalManagers.length > 1 ? 'managers' : 'manager'}?`}
        description=""
        buttonCallback={() => {
          deleteApprovalManagers.length > 1
            ? handleDeleteMultipleManagers(deleteApprovalManagers)
            : handleDeleteApprovalManager(deleteApprovalManagers[0]);
        }}
        callbackLoading={isDeleting}
        buttonText="Delete"
        url=""
      />
    </Box>
  );
}
