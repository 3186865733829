import React, { Dispatch, SetStateAction } from 'react';
import { Box, IconButton, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { classes } from '../../NewUI/Job/Applications/styles';
import { getRatingColor } from '../../NewUI/Job/Applications/SelectedApplicants';
import { theme } from '../../ThemeContext/ThemeObject';
import { IAttendee } from '../types';

export default function SelectedCandidatesTable({
  candidates,
  selectedCandidates,
  setSelectedCandidates,
  searchTerm,
  action
}: {
  candidates: IAttendee[];
  selectedCandidates: IAttendee[];
  setSelectedCandidates: Dispatch<SetStateAction<number[]>>;
  searchTerm: string;
  action: string;
}) {
  return (
    <Table sx={classes.selectedApplicantsTable} aria-label="selected applicants">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          {action !== 'SMS' && <TableCell>Email</TableCell>}
          {action !== 'Email' && <TableCell>Phone</TableCell>}
          <TableCell>Status</TableCell>
          <TableCell>Rating</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {candidates
          .filter((candidate) => candidate.name.toLowerCase().includes(searchTerm))
          .map((candidate) => (
            <TableRow key={candidate.attendee_id}>
              <TableCell
                onClick={() =>
                  candidate.application_url && window.open(candidate.application_url, '_blank')
                }
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {candidate.name}
              </TableCell>
              {action !== 'SMS' && <TableCell>{candidate.email}</TableCell>}
              {action !== 'Email' && <TableCell>{candidate.phone}</TableCell>}
              <TableCell sx={classes.applicationStatus}>
                <Box
                  sx={{
                    ...classes.statusDropdownSelected,
                    ...classes.statusClampSelected
                  }}
                >
                  <Box
                    sx={{
                      ...classes.statusColour,
                      backgroundColor: candidate.status_details?.colour || '#AAAAAA'
                    }}
                  ></Box>
                  {candidate.status_details?.name || 'New'}
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  color: '#000000',
                  fontWeight: '600'
                }}
              >
                <Box
                  sx={{
                    ...classes.rating,
                    backgroundColor: getRatingColor(
                      candidate.application_rating === null
                        ? 101
                        : Number(candidate.application_rating)
                    )
                  }}
                >
                  {candidate.application_rating
                    ? Math.ceil(Number(candidate.application_rating)) + '%'
                    : '-'}
                </Box>
              </TableCell>
              <TableCell sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton
                  onClick={() =>
                    setSelectedCandidates(
                      selectedCandidates
                        .filter((s) => s.id !== candidate.id)
                        .map((c) => c.attendee_id)
                    )
                  }
                >
                  <ClearIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
