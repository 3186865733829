import React, { useState, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Pagination,
  MenuItem,
  FormControl,
  Select,
  Skeleton,
  Popover,
  IconButton,
  Tooltip
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Unarchive as UnarchiveIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { IApprovalTemplateTableProps, IApprovalTemplate } from './types';
import moment from 'moment-timezone';
import { classes } from '../../Job/Applications/styles';
import { approvalTemplateHeaders } from './config';
import Api from '../API';
import GenericDialog from '../../Components/Modals/GenericDialog';
import MoveApprovalTemplate from './MoveApprovalTemplate';
import ApprovalTemplateSettings from '../ApprovalTemplateSettings/ApprovalTemplateSettings';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NotePopover from './NotePopover';

const tablePadding = (density: string) => {
  if (density === 'Default') {
    return '22px';
  } else if (density === 'Compact') {
    return '15px';
  } else {
    return '8px';
  }
};

export default function ApprovalTemplatesTable({
  approvalTemplates,
  setApprovalTemplates,
  getApprovalTemplates,
  setSnackbarState,
  isLoading,
  setIsLoading,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalPages,
  totalApprovalTemplates,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  activeColumns,
  apiKey,
  userPermissions,
  density,
  archived,
  setTemplateArchived,
  enableEmailTemplateFields
}: IApprovalTemplateTableProps) {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [tableHovered, setTableHovered] = useState(false);
  const [canScroll, setCanScroll] = useState([false, true]);
  const tableRef = useRef<HTMLDivElement>(null);
  const actionCellRef = useRef(null);
  const titleCellRef = useRef(null);
  const [trashAlertOpen, setTrashAlertOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteApprovalTemplates, setDeleteApprovalTemplates] = useState([]);
  const [editingApprovalTemplate, setEditingApprovalTemplate] = useState<IApprovalTemplate | null>(
    null
  );
  const [action, setAction] = useState('');
  const [noteAnchorEl, setNoteAnchorEl] = useState(null);

  const handleArchiveOrTrashApprovalTemplates = async (ids: Array<number>, action: string) => {
    setIsLoading(true);
    try {
      const response =
        action === 'archive'
          ? await Api.postArchiveApprovalTemplates(
              { approval_template_ids: ids }
            )
          : await Api.deleteTrashApprovalTemplates(
              { approval_template_ids: ids }
            );
      setSnackbarState({
        message: response.res.success,
        state: 'success'
      });
    } catch (error) {
      setSnackbarState({
        message: error?.errors,
        state: 'error'
      });
    } finally {
      setActionsAnchorEl(null);
      getApprovalTemplates(null, null, null, null, null, archived);
      setTemplateArchived(true);
    }
  };

  const handleUnarchiveApprovalTemplates = async (ids: Array<number>) => {
    setIsLoading(true);
    try {
      const response = await Api.postUnarchiveApprovalTemplates(
        { approval_template_ids: ids }
      );
    } catch (error) {
      setSnackbarState({
        message: `Unable to unarchive approval template, ${error?.errors.join('. ')}`,
        state: 'error'
      });
    } finally {
      setActionsAnchorEl(null);
      getApprovalTemplates(null, null, null, null, null, archived);
      setTemplateArchived(true);
    }
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getApprovalTemplates(null, selection, null, null, null, archived);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage === currentPage) return;
    setCurrentPage(newPage);
    sessionStorage.setItem(`approvalTemplatesTablePage`, newPage);
    getApprovalTemplates(newPage, null, null, null, null, archived);
  };

  const handleSortChange = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    columnValue: string
  ) => {
    const isAsc = sortBy === columnValue && sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    setSortBy(columnValue);
    getApprovalTemplates(
      null,
      null,
      null,
      { sortBy: columnValue, sortOrder: newSortOrder },
      null,
      archived
    );
  };

  const handleScroll = () => {
    if (tableRef.current.scrollLeft < 10) {
      setCanScroll([false, true]);
    } else if (
      tableRef.current.scrollLeft >
      tableRef.current.scrollWidth - tableRef.current.clientWidth - 10
    ) {
      setCanScroll([true, false]);
    } else {
      setCanScroll([true, true]);
    }
  };

  const handleScrollLeft = () => {
    tableRef.current.scrollLeft > 360
      ? (tableRef.current.scrollLeft -= 300)
      : (tableRef.current.scrollLeft = 0);
  };

  const handleScrollRight = () => {
    tableRef.current.scrollWidth - tableRef.current.clientWidth > 360
      ? (tableRef.current.scrollLeft += 300)
      : (tableRef.current.scrollLeft = tableRef.current.scrollWidth - tableRef.current.clientWidth);
  };

  function isScrollable(element: HTMLElement) {
    return element && element.scrollWidth > element.clientWidth + 20;
  }

  const handleScrollableEnter = () => {
    if (isScrollable(tableRef.current)) setTableHovered(true);
  };

  const handleScrollableExit = () => {
    setTableHovered(false);
  };

  const handleAction = (template: IApprovalTemplate, action: string) => {
    setEditModalOpen(true);
    setEditingApprovalTemplate(template);
    setAction(action);
  };

  const handleOpenNote = (event: React.MouseEvent<HTMLElement>, template: IApprovalTemplate) => {
    setEditingApprovalTemplate(template);
    setNoteAnchorEl(event.currentTarget);
  };

  const handleCloseNote = () => {
    setEditingApprovalTemplate(null);
    setNoteAnchorEl(null);
  };

  const headerStyle = (name: string) => {
    switch (name) {
      case 'name':
        return {
          ...classes.sticky,
          ...classes.scrollShadowLeft,
          ...(tableHovered && classes.scrollShadowVisible),
          width: '200px'
        };
      case 'updated_at':
        return { width: '120px' };
      case 'created_by':
        return { width: '140px' };
      case 'note':
        return { width: '150px' };
      default:
        return { width: '90px' };
    }
  };

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.arrowsContainer}>
          <Box
            onClick={handleScrollLeft}
            onMouseEnter={handleScrollableEnter}
            sx={{
              ...classes.leftArrow,
              ...(tableHovered && canScroll[0] && classes.showArrows),
              left: `${titleCellRef.current?.getBoundingClientRect().right}px`,
              top: 0
            }}
            id="left-arrow"
          >
            <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <Box sx={classes.arrowsContainer}>
          <Box
            onMouseEnter={handleScrollableEnter}
            onClick={handleScrollRight}
            sx={{
              ...classes.rightArrow,
              ...(tableHovered && canScroll[1] && classes.showArrows),
              left: `${actionCellRef.current?.getBoundingClientRect().left - 40}px`,
              top: 0
            }}
            id="right-arrow"
          >
            <KeyboardArrowRightIcon fontSize="large" sx={{ color: '#CCCCCC' }} />
          </Box>
        </Box>
        <TableContainer
          sx={{ boxShadow: 'none', scrollBehavior: 'smooth' }}
          component={Paper}
          onMouseEnter={handleScrollableEnter}
          onMouseLeave={handleScrollableExit}
          ref={tableRef}
          onScroll={handleScroll}
        >
          <Table
            sx={{
              ...classes.tableWrapper,
              'th, td, tr, thead': { padding: `${tablePadding(density)} 10px` }
            }}
            aria-label="approval templates"
          >
            <TableHead>
              <TableRow>
                {approvalTemplateHeaders.map((header, index) => {
                  if (
                    header.name === 'note' &&
                    !userPermissions?.['Approval Forms']?.['Manage Approval Form Notes']
                  ) {
                    return;
                  }
                  return (
                    activeColumns[header.name] && (
                      <TableCell
                        key={header.name}
                        sx={headerStyle(header.name)}
                        ref={header.name === 'name' ? titleCellRef : null}
                      >
                        <TableSortLabel
                          active={sortBy === header.name}
                          direction={sortBy === header.name ? (sortOrder as 'asc' | 'desc') : 'asc'}
                          onClick={(event) => handleSortChange(event, header.name)}
                          sx={{ ...classes.sortable, paddingLeft: `${index === 0 ? '16px' : ''}` }}
                          disabled={!header.sortable}
                          id={`${header.name}-sort-label-approval-templates`}
                        >
                          {header.label}
                        </TableSortLabel>
                      </TableCell>
                    )
                  );
                })}
                {(userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] ||
                  userPermissions?.['Approval Forms']?.['Edit Approval Form (HR use)'] ||
                  userPermissions?.['Approval Forms']?.['Clone Approval Form (HR use)'] ||
                  userPermissions?.['Approval Forms']?.['Archive Approval Forms (HR use)'] ||
                  userPermissions?.['Approval Forms']?.['Move Approval Template']) && (
                  <TableCell
                    sx={{
                      zIndex: '4',
                      ...classes.attachmentsHeader,
                      ...classes.stickyRight,
                      ...classes.scrollShadowRight,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {approvalTemplates &&
                !isLoading &&
                approvalTemplates.map((approvalTemplate: IApprovalTemplate, index) => {
                  const updatedAt = approvalTemplate.updated_at
                    ? moment
                        .tz(approvalTemplate.updated_at, timeZoneString)
                        .format('DD MMM YYYY,-h:mma z')
                        .split('-')
                        .map((line) => <div key={`${approvalTemplate.id}-${line}`}>{line}</div>)
                    : '-';
                  return (
                    <TableRow key={approvalTemplate.id}>
                      {activeColumns.name && (
                        <TableCell
                          sx={{
                            ...classes.sticky,
                            minWidth: '200px',
                            ...classes.scrollShadowLeft,
                            ...(tableHovered && classes.scrollShadowVisible)
                          }}
                        >
                          <Box sx={classes.titleLink}>
                            <a
                              href={approvalTemplate.requisition_form_fields_link}
                              className="approval-template-title-link"
                            >
                              {approvalTemplate.name || '-'}
                            </a>
                          </Box>
                        </TableCell>
                      )}
                      {activeColumns.updated_at && <TableCell>{updatedAt || '-'}</TableCell>}
                      {activeColumns.created_by && (
                        <TableCell>{approvalTemplate.created_by || '-'}</TableCell>
                      )}
                      {userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] &&
                        activeColumns.note && <TableCell>{approvalTemplate.note || '-'}</TableCell>}
                      {userPermissions?.['Approval Forms']?.[
                        'Create / Edit Approval Forms (HR use)'
                      ] ||
                      userPermissions?.['Approval Forms']?.['Edit Approval Form (HR use)'] ||
                      userPermissions?.['Approval Forms']?.['Clone Approval Form (HR use)'] ||
                      userPermissions?.['Approval Forms']?.['Archive Approval Forms (HR use)'] ||
                      userPermissions?.['Approval Forms']?.['Move Approval Template'] ||
                      userPermissions?.['Approval Forms']?.['Manage Approval Form Notes'] ? (
                        <TableCell
                          sx={{
                            zIndex: '4 !important',
                            ...classes.attachmentsCell,
                            ...classes.stickyRight,
                            ...classes.scrollShadowRight,
                            ...(tableHovered && classes.scrollShadowVisible)
                          }}
                          ref={actionCellRef}
                        >
                          <Box sx={{ ...classes.actionItems, justifyContent: 'end' }}>
                            {!archived &&
                              userPermissions?.['Approval Forms']?.[
                                'Manage Approval Form Notes'
                              ] && (
                                <Tooltip placement="top" title="Add Note" arrow>
                                  <IconButton
                                    onClick={(e) => handleOpenNote(e, approvalTemplate)}
                                    className="approval-template-add-note-button"
                                  >
                                    <NoteAddIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {!archived &&
                              (userPermissions?.['Approval Forms']?.[
                                'Create / Edit Approval Forms (HR use)'
                              ] ||
                                userPermissions?.['Approval Forms']?.[
                                  'Edit Approval Form (HR use)'
                                ]) && (
                                <Tooltip placement="top" title="Edit" arrow>
                                  <IconButton
                                    onClick={() => handleAction(approvalTemplate, 'Edit')}
                                    className="approval-template-edit-button"
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {!archived &&
                              userPermissions?.['Approval Forms']?.[
                                'Clone Approval Form (HR use)'
                              ] && (
                                <Tooltip placement="top" title="Clone" arrow>
                                  <IconButton
                                    onClick={() => handleAction(approvalTemplate, 'Clone')}
                                    className="approval-template-clone-button"
                                  >
                                    <FileCopyIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {userPermissions?.['Approval Forms']?.[
                              'Archive Approval Forms (HR use)'
                            ] || userPermissions?.['Approval Forms']?.['Move Approval Template']
                              ? !archived && (
                                  <Tooltip placement="top" title="More options" arrow>
                                    <IconButton
                                      onClick={(e) => {
                                        setActionsAnchorEl(e.currentTarget);
                                        setEditingApprovalTemplate(approvalTemplate);
                                      }}
                                      className="approval-template-action-button"
                                    >
                                      <MoreVertIcon sx={{ color: '#666666' }} />
                                    </IconButton>
                                  </Tooltip>
                                )
                              : !archived &&
                                (userPermissions?.['Approval Forms']?.[
                                  'Create / Edit Approval Forms (HR use)'
                                ] ||
                                  userPermissions?.['Approval Forms']?.[
                                    'Edit Approval Form (HR use)'
                                  ]) && (
                                  <Tooltip placement="top" title="Archive" arrow>
                                    <IconButton
                                      className="approval-template-delete-button"
                                      onClick={() => {
                                        setDeleteApprovalTemplates(approvalTemplate.id);
                                        setTrashAlertOpen(true);
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{ fontSize: '18px', color: '#E37D7A !important' }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                            {archived &&
                              userPermissions?.['Approval Forms']?.[
                                'Archive Approval Forms (HR use)'
                              ] && (
                                <Tooltip placement="top" title="Unarchive" arrow>
                                  <IconButton
                                    className="unarchive-button"
                                    onClick={(e) =>
                                      handleUnarchiveApprovalTemplates([approvalTemplate.id])
                                    }
                                  >
                                    <UnarchiveIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell ref={actionCellRef}></TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!isLoading && approvalTemplates?.length === 0 && (
            <Box sx={classes.noApplications}>No Approval Templates found</Box>
          )}
          {isLoading && (
            <>
              {[...Array(10)].map((_, index) => (
                <Box key={index} sx={classes.skeletonContainer}>
                  <Skeleton animation="wave" height={60} />
                </Box>
              ))}
            </>
          )}
        </TableContainer>
      </Box>
      <Box sx={classes.paginationParent}>
        <Box sx={classes.rowSelectContainer}>
          <span>Rows per page: </span>
          <FormControl>
            <Select
              id="rows-per-page"
              sx={classes.rowSelect}
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
              displayEmpty
              MenuProps={{ sx: classes.paginationMenuItems }}
            >
              <MenuItem id="first-rpp-item" value={10}>
                10
              </MenuItem>
              <MenuItem id="second-rpp-item" value={20}>
                20
              </MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={classes.paginationContainer}>
          <Pagination
            id="pagination-menu"
            count={totalPages}
            page={currentPage}
            siblingCount={0}
            onChange={handlePageChange}
            sx={classes.pagination}
          />
          <span id="total-jobs">{totalApprovalTemplates} Total</span>
        </Box>
      </Box>
      {!archived && noteAnchorEl && (
        <NotePopover
          setApprovalTemplates={setApprovalTemplates}
          anchorEl={noteAnchorEl}
          handleCloseNote={handleCloseNote}
          approvalTemplateId={editingApprovalTemplate?.id}
          approvalTemplateNote={editingApprovalTemplate?.note}
          apiKey={apiKey}
          setSnackbarState={setSnackbarState}
        />
      )}
      {!archived && (
        <Popover
          id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
          sx={classes.actionsMenu}
          open={Boolean(actionsAnchorEl)}
          anchorEl={actionsAnchorEl}
          onClose={() => setActionsAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          {userPermissions?.['Approval Forms']?.['Move Approval Template'] && (
            <span>
              <MoveApprovalTemplate
                apiKey={apiKey}
                approvalTemplateId={editingApprovalTemplate?.id}
                entity={editingApprovalTemplate?.entity_name}
                getApprovalTemplates={getApprovalTemplates}
                setSnackbarState={setSnackbarState}
                setActionsAnchorEl={setActionsAnchorEl}
              />
            </span>
          )}
          {userPermissions?.['Approval Forms']?.['Archive Approval Forms (HR use)'] && (
            <span
              onClick={() =>
                handleArchiveOrTrashApprovalTemplates(editingApprovalTemplate.id, 'archive')
              }
              className="archive-approval-template-action-button"
            >
              Archive
            </span>
          )}
          {(userPermissions?.['Approval Forms']?.['Create / Edit Approval Forms (HR use)'] ||
            userPermissions?.['Approval Forms']?.['Edit Approval Form (HR use)']) && (
            <span
              onClick={() => {
                setDeleteApprovalTemplates(editingApprovalTemplate.id);
                setTrashAlertOpen(true);
                setActionsAnchorEl(null);
              }}
              className="trash-approval-template-action-button"
            >
              Trash
            </span>
          )}
        </Popover>
      )}
      <GenericDialog
        isDialogOpen={trashAlertOpen}
        setDialogOpen={setTrashAlertOpen}
        title="Trash approval template?"
        description="Are you sure want to trash this approval template?"
        buttonCallback={() => {
          setTrashAlertOpen(false);
          handleArchiveOrTrashApprovalTemplates(deleteApprovalTemplates, 'trash');
          setDeleteApprovalTemplates([]);
        }}
        callbackLoading={false}
        buttonText="Trash"
        url=""
      />
      {editingApprovalTemplate && editModalOpen && (
        <ApprovalTemplateSettings
          modalsOpen={editModalOpen}
          setModalsOpen={() => setEditModalOpen(false)}
          setSnackbar={setSnackbarState}
          approvalTemplateData={editingApprovalTemplate}
          getApprovalTemplate={async () =>
            await getApprovalTemplates(null, null, null, null, null, false)
          }
          action={action}
          questions={editingApprovalTemplate.fields.filter(
            (question) => question.enabled && !question.for_email_template
          )}
          enableEmailTemplateFields={enableEmailTemplateFields}
        />
      )}
    </Box>
  );
}
