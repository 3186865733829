import { Box, Button, CircularProgress } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import dashboardStyles from '../../styles';
import { theme } from '../../../../ThemeContext/ThemeObject';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import AddTasks from '../../../Job/ActionModals/AddTask/AddTasks';
import StartWorkFlow from '../../../Job/ActionModals/StartWorkflow/StartWorkFlow';
import { TaskItem } from './TaskItem';
import Loader from '../Loader';
import { NoTasksSVG } from '../../../Components/Icons';
import { styles } from './styles';
import { EmptyState } from '../../../Components/CustomUIElements/EmptyState';

const STATUSES = ['Pending', 'Completed'];

interface IProps {
  setSnackbar: React.Dispatch<
    React.SetStateAction<{
      message: string;
      state: 'success' | 'warning' | 'error';
    }>
  >;
}

function TasksDashboard({ setSnackbar }: IProps) {
  const tabRef = useRef<HTMLDivElement>();
  const [tab, setTab] = useState(0);
  const [tabDimensions, setTabDimensions] = useState<{ width: number; offset: number }[]>([]);
  const queryClient = useQueryClient();

  const { isLoading, isSuccess, data, fetchNextPage, isFetchingNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['getTasks', tab],
      queryFn: ({ queryKey, pageParam = 1 }) =>
        Api.getTasks({ to_do: queryKey[1] === 0, page: pageParam }),
      getNextPageParam: (lastPage) => lastPage.nextPage || undefined
    });

  const { mutate } = useMutation({
    mutationFn: ({ id, completed }: { id: string; completed: boolean }) =>
      Api.completeTask(id, completed),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getTasks'] });
      setSnackbar({
        message: data?.message,
        state: 'success'
      });
    },
    onError: () => {
      setSnackbar({
        message: 'Could not update. Please try again.',
        state: 'error'
      });
    }
  });

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    if (!e) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (!isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  }

  useEffect(() => {
    if (!tabRef.current) return;
    setTabDimensions(
      Array.from(tabRef.current.childNodes as NodeListOf<HTMLDivElement>).map((child) => ({
        offset: child.offsetLeft,
        width: child.clientWidth
      }))
    );
  }, [tabRef]);

  const handleCompleteTask = useCallback(
    (id: number, completed: boolean) => {
      mutate({ id: id.toString(), completed });
    },
    [mutate]
  );

  return (
    <Box sx={{ ...dashboardStyles.section, ...styles.section }}>
      <Box sx={{ ...dashboardStyles.sectionTitle, ...styles.sectionTitle }}>
        <Box>Tasks</Box>
        <Box display="flex" gap={theme.spacing(2)}>
          <AddTasks
            isDashboard
            buttonElement={<Button sx={styles.button}>Add Task</Button>}
            apiKey={''}
            refetch={refetch}
          />
          <StartWorkFlow
            isDashboard
            buttonElement={<Button sx={styles.button}>Add workflow</Button>}
            setSnackbarState={setSnackbar}
            apiKey={''}
          />
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box ref={tabRef} ml={4} mr={4} sx={styles.tabContainer}>
          {STATUSES.map((item, index) => (
            <Tab
              label={item}
              key={index}
              isSelected={index === tab}
              onClick={() => setTab(index)}
            />
          ))}
        </Box>
        <Box
          sx={{
            ...styles.line,
            width: tabDimensions[tab]?.width,
            left: tabDimensions[tab]?.offset
          }}
        />
      </Box>
      <Box sx={styles.taskContainer} onScroll={handleScroll} mr={4} ml={4}>
        {isLoading &&
          Array(3)
            .fill(null)
            .map((_, i) => <Loader key={i} />)}
        {isSuccess && (
          <>
            {data.pages[0].data.length ? (
              <>
                {data.pages.map((page) =>
                  page.data.map((item) => (
                    <TaskItem key={item.id} data={item} handleCompleteTask={handleCompleteTask} />
                  ))
                )}
                {isFetchingNextPage && hasNextPage && (
                  <Box flex={1} justifyContent="center">
                    <Box display="flex" flex={1} justifyContent="center">
                      <CircularProgress size={20} sx={{ color: theme.palette.primary.main }} />
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <EmptyState
                svgIcon={<NoTasksSVG />}
                label={`You have no ${tab === 0 ? 'pending' : 'completed'} tasks`}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

const Tab = ({
  label,
  isSelected,
  onClick
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Box
    sx={{
      ...styles.tab,
      fontWeight: isSelected ? 600 : undefined,
      color: isSelected ? theme.palette.primary.main : theme.palette.common.grey
    }}
    onClick={onClick}
  >
    {label}
  </Box>
);

export default TasksDashboard;
