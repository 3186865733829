import React from "react";
import { Box } from "@mui/material";
import sharedStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
import renderHTML from "react-render-html";
const MAX_KEY_SELLING_POINTS = 3;
function Step3({ answers }) {
  const sharedClasses = sharedStyles();
  return /* @__PURE__ */ React.createElement(Box, { className: sharedClasses.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Preview Ad"), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewContainer }, /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewTitle }, `${answers.title} (${answers.workType})`), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: sharedClasses.previewDetails
    },
    `${answers.address} (${answers.workLocation})`
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: sharedClasses.previewDetails
    },
    `$${answers.payRangeLow}-$${answers.payRangeHigh}`
  ), answers.benefits && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, answers.benefits.map((benefit, index) => /* @__PURE__ */ React.createElement("span", { key: index }, benefit, index < answers.benefits.length - 1 && " \u2022 "))), answers.featured && /* @__PURE__ */ React.createElement(Box, { sx: { marginTop: "1rem" } }, /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, "Featured Job on Viewjobs"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, answers.jobPreview.summary), Array.from({ length: MAX_KEY_SELLING_POINTS }).map((_, index) => /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails, key: index }, "\u2022 ", answers.jobPreview[`key${index + 1}`]))), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewContent }, renderHTML(answers.description))));
}
Step3.propTypes = jobAdPropTypes;
export default Step3;
