import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  switchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  descriptionLabel: {
    marginTop: 50
  },
  textField: {
    marginTop: 10
  },
  salaryIntervalLabel: {
    width: 150
  },
  formControl: {
    marginTop: 50
  },
  large: {
    maxWidth: 600
  },
  previewButtons: {
    display: "flex",
    marginTop: 30,
    "& div": {
      borderRadius: 6,
      fontWeight: "600",
      lineHeight: "20px",
      background: "#2557A7",
      color: "#FFF",
      fontSize: 20,
      marginRight: 10,
      padding: "9px 24px",
      "&:hover": {
        background: "#3d6fbf",
        cursor: "pointer"
      }
    }
  }
}));
