import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined';
import ForwardOutlinedIcon from '@mui/icons-material/ForwardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SmsIcon from '@mui/icons-material/Sms';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Skeleton from '@mui/material/Skeleton';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import AutoStatuses from './Automation/AutoStatuses/AutoStatuses';
import { classes } from './styles';
import General from './General/General';
import Api from '../Job/API';
import { IJob, IPermissions } from '../Job/types';
import CircularProgress from '@mui/material/CircularProgress';
import {
  changeURL,
  editDescriptionDefault,
  editGeneralJobDefault,
  editGoogleScriptDefault,
  editJobObject,
  editPanelWorkflowDefault,
  editRequisitionInfoDefault,
  editRevolveDefault,
  editSourcesDefault,
  editTemplateDefault
} from '../Jobs/config';
import { IJobData } from './types';
import CandidateAttachments from '../Job/CandidateAttachments/CandidateAttachments';
import Questions from './Questions/Questions';
import Template from '../EditJob/Template/Template';
import Sources from '../EditJob/Sources/Sources';
import Description from '../EditJob/Description/Description';
import PanelWorkflow from './Advanced/PanelWorkflow/PanelWorkflow';
import GoogleConversionScript from './Advanced/GoogleConversionScript/GoogleScript';
import Revolve from './Advanced/Revolve/Revolve';
import RequisitionInfo from './Advanced/RequisitionInfo/RequisitionInfo';
import TimeBased from './Automation/StatusTransitions/TimeBased';
import AutoForward from './Automation/AutoForward/AutoForward';
import AccountClockIcon from '../EditJob/Sources/AccountClockIcon';
import SourcesIconSVG from '../EditJob/Sources/SourcesIconSVG';
import AutoReply from './Automation/AutoReply/AutoReply';
import AutoSMSReply from './Automation/AutoSMSReply/AutoSMSReply';
import AutoSMSForward from './Automation/AutoSMSForward/AutoSMSForward';
import TestGrid from './Advanced/TestGrid/TestGrid';
import HRQuestions from '../EditJob/HRQuestions/HRQuestions';
import WarningDialog from '../Components/Modals/WarningDialog';
import EditJobToggleLeftSVG from './EditJobToggleLeftSVG';
import EditJobToggleRightSVG from './EditJobToggleRightSVG';
import UpdateIcon from '@mui/icons-material/Update';
import { RobotIcon } from '../../AIStudio/SharedComponents/Graphics/FelixSVG';
import AIStudioSettings from './Advanced/AIStudio/AIStudioSettings';
import { convertToNormalTabIndex, convertToPanelIndex, TabGroup } from './tabIndexUtils';
import { getPermissions } from '../../shared/permissionHelpers';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { formatUserPermissions } from '../Components/Utilities/formatUserPermissions';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { userTimeZone } from '../utils/formatToUserTimeZone';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  group?: number;
}
const URL_PARAMS = new URL(window.location.href);
const BASE_URL = window.location.origin;

export default function EditJob({
  aiInterview,
  aiStudio,
  aiStudioSummaries,
  apiKey,
  job_id,
  jobFeedActive,
  canAddIndeedDemographicQ
}: {
  aiInterview: boolean;
  aiStudio: boolean;
  aiStudioSummaries: boolean;
  apiKey: string;
  job_id: number;
  jobFeedActive: boolean;
  canAddIndeedDemographicQ: boolean;
}) {
  const getEditTabNumber = (urlParams: string) => changeURL(0, false, urlParams);
  const [job, setJob] = useState<IJob | null>(null);
  const [mainTabValue, setMainTabValue] = useState(0);
  const [jobLoading, setJobLoading] = useState(true);
  const [editTabValue, setEditTabValue] = useState(getEditTabNumber(URL_PARAMS.search));
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [copied, setCopied] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [requisitionsOptions, setRequisitionsOptions] = useState([]);
  const [classificationsOptions, setClassificationsOptions] = useState([]);
  const [localOptions, setLocalOptions] = useState([]);
  const [autoRaterOptions, setAutoRaterOptions] = useState([]);
  const [assignedUsersOptions, setAssignedUsersOptions] = useState([]);
  const [salespersonOptions, setSalespersonOptions] = useState([]);
  const [jobPanelChairsOptions, setJobPanelChairsOptions] = useState([]);
  const [rmTeamOptions, setRmTeamOptions] = useState([]);
  const [changeTab, setChangeTab] = useState<[boolean, null | number]>([false, null]);
  const [generalTabJobData, setGeneralTabJobData] = useState<IJobData | null>(null);
  const [panelWorkflowTabJobData, setPanelWorkflowTabJobData] = useState<IJobData | null>(null);
  const [googleScriptTabJobData, setGoogleScriptTabJobData] = useState<IJobData | null>(null);
  const [revolveTabJobData, setRevolveTabJobData] = useState<IJobData | null>(null);
  const [descriptionTabJobData, setDescriptionTabJobData] = useState<IJobData | null>(null);
  const [requisitionInfoTabJobData, setRequisitionInfoTabJobData] = useState<IJobData | null>(null);
  const [templateTabJobData, setTemplateTabJobData] = useState<IJobData | null>(null);
  const [sourcesTabJobData, setSourcesTabJobData] = useState<IJobData | null>(null);
  const [sourceListIds, setSourceListIds] = useState([]);
  const [attachmentsTabJobData, setAttachmentsTabJobData] = useState(null);
  const [candidateAttachments, setCandidateAttachments] = useState(null);
  const [warningModalOpen, setWarningModalOpen] = useState(editTabValue === 4);
  const [hasActiveSeekAd, setHasActiveSeekAd] = useState(false);
  const [userPermissions, setUserPermissions] = useState<Record<string, Record<string, boolean>>>(
    {}
  );
  const [isToggled, setIsToggled] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const headerRefs = useRef<HTMLDivElement[]>([]);

  const getPageParams = (tabNo: number) => {
    switch (tabNo) {
      case 0:
        return '?edit=true';
      default:
        return '';
    }
  };

  const TabPanel = useCallback((props: TabPanelProps) => {
    const { children, value, index, group, ...other } = props;

    const resolvedIndex = group === undefined ? index : convertToPanelIndex(index, group);
    return (
      <div
        role="tabpanel"
        hidden={value !== resolvedIndex}
        id={`vertical-tabpanel-${resolvedIndex}`}
        aria-labelledby={`vertical-tab-${resolvedIndex}`}
        {...other}
      >
        {value === resolvedIndex && <Box>{children}</Box>}
      </div>
    );
  }, []);

  const TabProps = useCallback((tabIndex: number, group: number) => {
    const index = convertToNormalTabIndex(tabIndex, group);
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      'data-testid': `vertical-tab-${index}`
    };
  }, []);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[mainTabValue].clientWidth - 32);
  }, [mainTabValue]);

  const handleEditTabChange = (event: React.SyntheticEvent, newValue: number) => {
    URL_PARAMS.search = changeURL(newValue, true, '');
    window.history.pushState(null, null, URL_PARAMS.href);
    if (editTabValue === 1) {
      newValue === 4 && setWarningModalOpen(true);
      return setChangeTab([true, newValue]);
    }
    if (newValue === 4) {
      setWarningModalOpen(true);
    }
    setEditTabValue(newValue);
  };

  const handleMainTabChange = (event: React.ChangeEvent, newValue: number) => {
    setMainTabValue(newValue);
    URL_PARAMS.search = getPageParams(newValue);
    window.history.pushState(null, '', URL_PARAMS.href);
  };

  useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions();
      return formatUserPermissions(res);
    },
    onError: (error) => {
      setSnackbar({
        state: 'error',
        message: `There was an error getting user permissions, ${error}`
      });
    }
  });

  useQuery({
    queryKey: ['job', job_id],
    queryFn: async () => await Api.getJob(job_id, { 'X-api-authenticate': apiKey }),
    onSuccess: (data) => {
      setFetchedData(data);
    },
    onError: (error) => {
      setSnackbar({
        state: 'error',
        message: `There was an error getting job, ${error}`
      });
    }
  });

  const setFetchedData = (data) => {
    setJobLoading(true);
    const jobResponse = data;
    setJob(jobResponse);
    setGeneralTabJobData(editGeneralJobDefault(jobResponse));
    setPanelWorkflowTabJobData(editPanelWorkflowDefault(jobResponse));
    setGoogleScriptTabJobData(editGoogleScriptDefault(jobResponse));
    setRevolveTabJobData(editRevolveDefault(jobResponse));
    setRequisitionInfoTabJobData(editRequisitionInfoDefault(jobResponse));
    setDescriptionTabJobData(editDescriptionDefault(jobResponse));
    setTemplateTabJobData(editTemplateDefault(jobResponse));
    setSourcesTabJobData(editSourcesDefault(jobResponse));
    setSourceListIds(jobResponse.sources.map((source) => source.id));
    setCandidateAttachments(
      jobResponse.candidate_attachments.map((attachment) => ({
        ...attachment,
        id: attachment.value
      }))
    );
    setJobLoading(false);
    setLastUpdate(jobResponse.updated_at);
  };

  const fetchActiveSeekAds = useCallback(async () => {
    try {
      const response = await Api.getActiveSeekAd(job_id, { 'X-api-authenticate': apiKey });
      if (response.job_ads.active && response.job_ads.active.length > 0) {
        setHasActiveSeekAd(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, [job_id, apiKey]);

  const getUserPermissions = useCallback(async () => {
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean> | string | boolean> = {};
      data.roles.forEach((role: IPermissions) => {
        allRoles['paid_user'] = role.paid_role;
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        });
      });
      allRoles['user_type'] = data.roles[0]?.user_type;
      setUserPermissions(allRoles);
    } catch (error) {
      console.log(error);
    }
  }, [setUserPermissions, apiKey]);

  useEffect(() => {
    getUserPermissions();
    fetchActiveSeekAds();
  }, [getUserPermissions, fetchActiveSeekAds]);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (isEdited) {
        return true;
      } else {
        return;
      }
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [isEdited]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const updateData = async ({ id, data }) =>
    Api.updateJob({ 'X-api-authenticate': apiKey }, id, { job: data });

  const mutation = useMutation(updateData, {
    onSuccess: (data) => {
      setSnackbar({
        message: data?.errors ? data.errors : 'Updated job successfully',
        state: data?.errors ? 'error' : 'success'
      });
      setIsEdited(false);
      queryClient.invalidateQueries(['job', job_id]);
    },
    onError: (error) => {
      setSnackbar({
        message: error?.errors || 'Something has gone wrong with saving your job',
        state: 'error'
      });
    }
  });

  const handleUpdateJob = async () => {
    setIsUpdating(true);
    const editObj = editJobObject(
      candidateAttachments,
      generalTabJobData,
      descriptionTabJobData,
      templateTabJobData,
      revolveTabJobData,
      googleScriptTabJobData,
      panelWorkflowTabJobData,
      sourceListIds
    );
    mutation.mutate({ id: job_id, data: editObj });

    setIsUpdating(false);
    setIsEdited(false);
  };

  const lastSaved = new Date(lastUpdate);
  const lastSavedFormat = (job: IJob | null) => {
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const pickedtimezone = job?.time_zone ?? userTimeZone ?? timeZoneString;
    const timeZone = moment.tz(pickedtimezone).format('z');
    const timeString = lastSaved.toLocaleString('en-US', {
      timeZone: pickedtimezone,
      hour: 'numeric',
      minute: 'numeric',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    });
    return `${timeString} ${timeZone}`;
  };

  return (
    <Box sx={classes.jobPageContainer}>
      <Box sx={classes.editJobPageHeader}>
        {jobLoading ? (
          <Skeleton animation="wave" width={600} height={56} />
        ) : (
          <Box sx={{ color: '#666666' }}>
            <h4 id="reference">{job?.reference}</h4>
            <h1 id="title">{job?.title}</h1>
          </Box>
        )}
      </Box>
      <Box sx={classes.linkContainer}>
        {jobLoading ? (
          <Skeleton animation="wave" width={400} height={50} />
        ) : (
          job?.public_url && (
            <>
              <a href={job?.public_url} target="_blank" rel="noopener noreferrer">
                <Box id="external-link" sx={classes.link}>
                  {job.public_url}
                </Box>
              </a>
              <CopyToClipboard text={job?.public_url} onCopy={handleCopy}>
                <Button sx={classes.copyButton} id="copy-link">
                  {copied ? 'Copied!' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </>
          )
        )}
      </Box>
      <Box sx={classes.tabsContainer}>
        <Tabs
          value={mainTabValue}
          onChange={handleMainTabChange}
          aria-label="Jobs, Archive, Multiposter Tabs"
          sx={{
            ...classes.tabs,
            '& .MuiTabs-indicator': { ...classes.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab
            ref={(el) => (el ? (headerRefs.current[0] = el) : null)}
            label="Edit"
            {...TabProps(0)}
          />
          <TabPanel tabValue={mainTabValue} index={0}></TabPanel>
        </Tabs>
      </Box>
      {jobLoading ? (
        <Skeleton animation="wave" sx={{ marginBottom: '20px' }} width={200} height={56} />
      ) : (
        <Box sx={classes.backAndLastSaveContainer}>
          <Stack
            flexDirection="row"
            alignItems="center"
            onClick={() => {
              window.location.href = `${BASE_URL}${job?.job_link}`;
            }}
            sx={{ ...classes.backToJobButton, marginBottom: 'unset' }}
          >
            <IconButton sx={classes.iconGrey}>
              <ArrowCircleLeftIcon />
            </IconButton>
            <Box>Back</Box>
          </Stack>
          <Stack sx={classes.lastSaved}>
            {isUpdating ? (
              <>
                <CircularProgress size={12} color="inherit" sx={{ marginRight: 1 }} />
                Saving
              </>
            ) : (
              <>
                <UpdateIcon sx={{ margin: '4px', fontSize: '1rem' }} />
                Last saved {lastSavedFormat(job)}
              </>
            )}
          </Stack>
        </Box>
      )}
      <Box sx={classes.editTabsContainer}>
        <Tabs
          orientation="vertical"
          value={editTabValue}
          variant="scrollable"
          onChange={handleEditTabChange}
          aria-label="Vertical tabs example"
          sx={{
            ...classes.editTabs,
            '& svg': {
              height: '1.5rem',
              marginRight: isToggled ? '0px !important' : '16px !important',
              color: '#084D6D'
            },
            '& .MuiTabs-indicator': { ...classes.editTabIndicator },
            minWidth: isToggled ? '58px !important' : '220px !important',
            transition: 'all 0.5s'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                ...classes.tabsSubheader,
                fontSize: isToggled ? 0 : '12px',
                transition: 'all 0.5s'
              }}
            >
              Job Information
            </Box>
            <Box
              sx={{ ...classes.toggleIcon, marginLeft: isToggled ? '6.5px' : 'auto' }}
              onClick={() => setIsToggled(!isToggled)}
            >
              {isToggled ? <EditJobToggleRightSVG /> : <EditJobToggleLeftSVG />}
            </Box>
          </Box>
          <Tooltip placement="right" title={'General'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<InfoOutlinedIcon />}
              iconPosition="start"
              label={isToggled ? '' : 'General'}
              {...TabProps(0, TabGroup.JOB_INFORMATION)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Description'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<BusinessCenterOutlinedIcon />}
              iconPosition="start"
              label={isToggled ? '' : 'Description'}
              {...TabProps(1, TabGroup.JOB_INFORMATION)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Template'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<ContentPasteOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Edit Job Template']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Template'}
              {...TabProps(2, TabGroup.JOB_INFORMATION)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Questions'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<LiveHelpOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Create / Edit Job Questions']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Questions'}
              {...TabProps(3, TabGroup.JOB_INFORMATION)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Sources'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<SourcesIconSVG />}
              iconPosition="start"
              label={isToggled ? '' : 'Sources'}
              {...TabProps(4, TabGroup.JOB_INFORMATION)}
            />
          </Tooltip>
          <Box
            sx={{
              ...classes.tabsSubheader,
              fontSize: isToggled ? 0 : '12px',
              transition: 'all 0.5s'
            }}
          >
            Candidate Settings
          </Box>
          <Tooltip placement="right" title={'Attachments'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<AttachFileOutlinedIcon />}
              iconPosition="start"
              label={isToggled ? '' : 'Attachments'}
              {...TabProps(0, TabGroup.CANDIDATE_SETTINGS)}
            />
          </Tooltip>
          <Box
            sx={{
              ...classes.tabsSubheader,
              fontSize: isToggled ? 0 : '12px',
              transition: 'all 0.5s'
            }}
          >
            Automation
          </Box>
          <Tooltip
            placement="right"
            title={'Auto Statuses'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={
                <FlashOnOutlinedIcon
                  sx={{ color: 'transparent !important', stroke: '#084d6d', strokeWidth: '1.8px' }}
                />
              }
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Manage Auto Statuses']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Auto Statuses'}
              {...TabProps(0, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Status Transitions'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<AccountClockIcon />}
              iconPosition="start"
              label={isToggled ? '' : 'Status Transitions'}
              {...TabProps(1, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Auto Forward'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<ForwardOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Manage Auto Forward configs']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Auto Forward'}
              {...TabProps(2, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Auto Email Reply'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<EmailOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Manage Auto Forward configs']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Auto Email Reply'}
              {...TabProps(3, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Auto SMS Reply'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<SmsIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Manage Auto Forward configs']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Auto SMS Reply'}
              {...TabProps(4, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Auto SMS Forward'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<SendToMobileIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions?.['Job Options']?.['Manage Auto Forward configs']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Auto SMS Forward'}
              {...TabProps(5, TabGroup.AUTOMATION)}
            />
          </Tooltip>
          <Box
            sx={{
              ...classes.tabsSubheader,
              fontSize: isToggled ? 0 : '12px',
              transition: 'all 0.5s'
            }}
          >
            Advanced
          </Box>
          <Tooltip
            placement="right"
            title={'Requisition Info'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<VerifiedOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions.Jobs?.['Migrate from Requisition Form']
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Requisition Info'}
              {...TabProps(0, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Configure Application HR Questions'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<LiveHelpOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: userPermissions.Jobs?.ConfigureApplicationHRQuestions
                  ? 'block'
                  : 'none !important'
              }}
              label={isToggled ? '' : 'Configure Application HR Questions'}
              {...TabProps(1, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Automated TestGrid'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<GridOnOutlinedIcon />}
              iconPosition="start"
              sx={{
                display:
                  job?.integrations?.test_grid_configured && userPermissions.Integrations?.TestGrid
                    ? 'block'
                    : 'none !important'
              }}
              label={isToggled ? '' : 'Automated TestGrid'}
              {...TabProps(2, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'Revolve'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<LoopOutlinedIcon />}
              iconPosition="start"
              sx={{
                display:
                  job?.integrations?.revolve_configured &&
                  userPermissions.Integrations?.['Export To Revolve Onboard']
                    ? 'block'
                    : 'none !important'
              }}
              label={isToggled ? '' : 'Revolve'}
              {...TabProps(3, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Google Conversion Script'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<GoogleIcon />}
              iconPosition="start"
              label={isToggled ? '' : 'Google Conversion Script'}
              {...TabProps(4, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip
            placement="right"
            title={'Panel Workflow'}
            disableHoverListener={!isToggled}
            arrow
          >
            <Tab
              icon={<AssignmentIndOutlinedIcon />}
              iconPosition="start"
              sx={{
                display: job?.entity_settings.show_panel_workflow ? 'block' : 'none !important'
              }}
              label={isToggled ? '' : 'Panel Workflow'}
              {...TabProps(5, TabGroup.ADVANCED)}
            />
          </Tooltip>
          <Tooltip placement="right" title={'AI Studio'} disableHoverListener={!isToggled} arrow>
            <Tab
              icon={<RobotIcon viewBox="0 0 19 19" />}
              iconPosition="start"
              sx={{
                display: aiStudio && aiStudioSummaries ? 'block' : 'none !important'
              }}
              label={isToggled ? '' : 'AI Studio'}
              {...TabProps(6, TabGroup.ADVANCED)}
            />
          </Tooltip>
        </Tabs>
        {jobLoading ? (
          <Skeleton animation="wave" sx={{ marginLeft: '2rem' }} width={200} height={56} />
        ) : (
          <Box sx={{ marginLeft: '3rem', width: '100%', overflow: 'auto' }}>
            <TabPanel value={editTabValue} index={0} group={TabGroup.JOB_INFORMATION}>
              <General
                apiKey={apiKey}
                job={job}
                jobId={job_id}
                timezoneOptions={timezoneOptions}
                setTimezoneOptions={setTimezoneOptions}
                brandOptions={brandOptions}
                setBrandOptions={setBrandOptions}
                requisitionsOptions={requisitionsOptions}
                setRequisitionsOptions={setRequisitionsOptions}
                classificationsOptions={classificationsOptions}
                setClassificationsOptions={setClassificationsOptions}
                autoRaterOptions={autoRaterOptions}
                setAutoRaterOptions={setAutoRaterOptions}
                assignedUsersOptions={assignedUsersOptions}
                setAssignedUsersOptions={setAssignedUsersOptions}
                salespersonOptions={salespersonOptions}
                setSalespersonOptions={setSalespersonOptions}
                rmTeamOptions={rmTeamOptions}
                setRmTeamOptions={setRmTeamOptions}
                changeTab={changeTab}
                setEditTabValue={setEditTabValue}
                setChangeTab={setChangeTab}
                localOptions={localOptions}
                setLocalOptions={setLocalOptions}
                newJobData={generalTabJobData}
                setNewJobsData={setGeneralTabJobData}
                handleUpdateJob={handleUpdateJob}
                userPermissions={userPermissions}
                setIsEdited={setIsEdited}
                jobFeedActive={jobFeedActive}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={1} group={TabGroup.JOB_INFORMATION}>
              <Description
                descriptionTabJobData={descriptionTabJobData}
                setDescriptionTabJobData={setDescriptionTabJobData}
                setIsEdited={setIsEdited}
                userPermissions={userPermissions}
                aiStudioEnabled={aiStudio}
                aiPrefillData={{
                  title: job?.title,
                  country: job?.location
                }}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={2} group={TabGroup.JOB_INFORMATION}>
              <Template
                apiKey={apiKey}
                job={job}
                templateTabJobData={templateTabJobData}
                setTemplateTabJobData={setTemplateTabJobData}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={3} group={TabGroup.JOB_INFORMATION}>
              <Questions
                apiKey={apiKey}
                jobId={job_id}
                job={job}
                userPermissions={userPermissions}
                aiStudio={aiStudio}
                aiInterview={aiInterview}
                canAddIndeedDemographicQ={canAddIndeedDemographicQ}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={4} group={TabGroup.JOB_INFORMATION}>
              <Sources
                apiKey={apiKey}
                job={job}
                sourcesTabJobData={sourcesTabJobData}
                setSourcesTabJobData={setSourcesTabJobData}
                sourceListIds={sourceListIds}
                setSourceListIds={setSourceListIds}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={0} group={TabGroup.CANDIDATE_SETTINGS}>
              <CandidateAttachments
                apiKey={apiKey}
                job={job}
                candidateAttachments={candidateAttachments}
                setCandidateAttachments={setCandidateAttachments}
                attachmentsTabJobData={attachmentsTabJobData}
                setAttachmentsTabJobData={setAttachmentsTabJobData}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={0} group={TabGroup.AUTOMATION}>
              <AutoStatuses
                apiKey={apiKey}
                jobId={job_id}
                setSnackbar={setSnackbar}
                jobQuestions={job?.questions}
                handleEditTabChange={handleEditTabChange}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={1} group={TabGroup.AUTOMATION}>
              <TimeBased apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={2} group={TabGroup.AUTOMATION}>
              <AutoForward apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={3} group={TabGroup.AUTOMATION}>
              <AutoReply apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={4} group={TabGroup.AUTOMATION}>
              <AutoSMSReply apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={5} group={TabGroup.AUTOMATION}>
              <AutoSMSForward apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={0} group={TabGroup.ADVANCED}>
              <RequisitionInfo
                apiKey={apiKey}
                jobId={job_id}
                setSnackbar={setSnackbar}
                requisitionInfoTabJobData={requisitionInfoTabJobData}
                setRequisitionInfoTabJobData={setRequisitionInfoTabJobData}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={1} group={TabGroup.ADVANCED}>
              <HRQuestions
                apiKey={apiKey}
                jobId={job_id}
                applicationQuestionTemplateId={job.application_question_template.id}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={2} group={TabGroup.ADVANCED}>
              <TestGrid apiKey={apiKey} jobId={job_id} setSnackbar={setSnackbar} />
            </TabPanel>
            <TabPanel value={editTabValue} index={3} group={TabGroup.ADVANCED}>
              <Revolve
                apiKey={apiKey}
                job={job}
                revolveTabJobData={revolveTabJobData}
                setRevolveTabJobData={setRevolveTabJobData}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={4} group={TabGroup.ADVANCED}>
              <GoogleConversionScript
                apiKey={apiKey}
                job={job}
                googleScriptTabJobData={googleScriptTabJobData}
                setGoogleScriptTabJobData={setGoogleScriptTabJobData}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={5} group={TabGroup.ADVANCED}>
              <PanelWorkflow
                apiKey={apiKey}
                jobId={job_id}
                chairsOptions={jobPanelChairsOptions}
                setChairsOptions={setJobPanelChairsOptions}
                panelWorkflowTabJobData={panelWorkflowTabJobData}
                setPanelWorkflowTabJobData={setPanelWorkflowTabJobData}
                setIsEdited={setIsEdited}
              />
            </TabPanel>
            <TabPanel value={editTabValue} index={6} group={TabGroup.ADVANCED}>
              <AIStudioSettings jobId={job_id} setSnackbarState={setSnackbar} />
            </TabPanel>
            {jobLoading ? (
              <Box />
            ) : (
              <Stack alignItems="end" sx={{ padding: 1, width: '100%' }}>
                <Button
                  id="save-job-questions-button"
                  data-testid="save-job-questions-button"
                  type="submit"
                  sx={{
                    ...sharedClasses.saveButton
                  }}
                  onClick={() => {
                    if (editTabValue === 1) {
                      return setChangeTab([false, 1]);
                    }
                    !isUpdating && handleUpdateJob();
                  }}
                >
                  {isUpdating ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                </Button>
                <Stack sx={classes.lastSaved}>
                  {isUpdating ? (
                    <>
                      <CircularProgress size={12} color="inherit" sx={{ marginRight: 1 }} />
                      Saving
                    </>
                  ) : (
                    <>
                      <UpdateIcon sx={{ margin: '4px', fontSize: '1rem' }} />
                      Last saved {lastSavedFormat(job)}
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </Box>
        )}
      </Box>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
      <WarningDialog
        isDialogOpen={hasActiveSeekAd && warningModalOpen}
        setDialogOpen={(isOpen: boolean) => setWarningModalOpen(isOpen)}
        title={'Warning'}
        description={
          '<strong>Any changes</strong> (adding, editing, removing etc.) to your questions will affect and block the importing of applicants from SEEK into :Recruit.'
        }
        buttonText="I understand"
      />
    </Box>
  );
}
