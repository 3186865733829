import React, { useState, useRef, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { classes } from './styles';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import Api from '../API';

export default function NewApprovalModalFromCandidate({
  apiKey,
  jobId,
  applicationId
}: {
  apiKey: string;
  jobId: number;
  applicationId: number;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [approvalTemplate, setApprovalTemplate] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const fieldRefs = {
    approvalTemplate: useRef<HTMLInputElement>(null)
  };

  const fetchEnabledApprovalTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const { res } = await Api.getEnabledApprovalTemplates();
      setTemplateOptions(res?.enabled_requisition_forms);
    } catch (error) {
      setSnackbar({
        message: `There was an error getting approval templates, ${error.errors}`,
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const navigateToNewApproval = () => {
    if (approvalTemplate === null) return;
    window.location.href = `/admin/requisitions/new?form_id=${approvalTemplate.id}&job_id=${jobId}&application_id=${applicationId}`;
    setIsNavigating(true);
  };

  useEffect(() => {
    templateOptions?.length === 0 && !isLoading && fetchEnabledApprovalTemplates();
  }, [fetchEnabledApprovalTemplates]);

  return (
    <Box>
      <Button
        disableRipple
        variant="contained"
        onClick={() => {
          setIsDialogOpen(true);
        }}
        sx={{
          padding: '0px 4px 1px 4px',
          fontSize: '10px',
          background: '#98A4AE',
          color: 'black',
          fontWeight: 'bold',
          fontFamily: 'inherit',
          margin: '0px 1px',
          '&:hover': {
            background: '#88939c'
          },
          '&:focus': {
            outline: 'none'
          }
        }}
      >
        Create an approval
      </Button>
      <Modal
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="new-approval-modal"
      >
        <Box sx={classes.actionsModal}>
          <Box sx={{ padding: '12px 0px 30px 0px' }}>
            <Box id="new-approval-modal-title" sx={classes.modalHeader}>
              New approval
            </Box>
          </Box>
          <Box sx={{ marginTop: '1.75rem' }}>
            <Box sx={classes.modalFormLine}>
              <Box sx={{ position: 'relative' }}>
                <Autocomplete
                  disablePortal
                  options={templateOptions}
                  ref={fieldRefs.approvalTemplate}
                  getOptionLabel={(option) => option?.name}
                  id="state-region-province"
                  loading={templateOptions?.length < 1}
                  loadingText={
                    isLoading ? 'Loading approval templates...' : 'No approval templates enabled'
                  }
                  value={approvalTemplate}
                  onChange={(event, value) => setApprovalTemplate(value)}
                  sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an approval template"
                      InputLabelProps={{ shrink: true }}
                      placeholder="Please select"
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <CloseIcon onClick={() => setIsDialogOpen(false)} sx={classes.closeIcon} />
            <Box sx={classes.modalActions}>
              <Button
                disableElevation
                sx={{ ...classes.buttonRedLight }}
                variant="text"
                onClick={() => {
                  setIsDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                id="submit-new-approval-button"
                type="submit"
                sx={classes.modalEditButton}
                disabled={approvalTemplate === null}
                onClick={navigateToNewApproval}
              >
                {isNavigating ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
