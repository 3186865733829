import { Box, Skeleton, Stack } from '@mui/material';
import { AccountBox, InsertDriveFile, Star, WatchLater } from '@mui/icons-material';
import React from 'react';
import { getApplicationRatingColor } from '../../config';
import { styles } from '../styles';
import formatToUserTimeZone from '../../../utils/formatToUserTimeZone';

const WidgetBlock = ({
  icon,
  heading,
  body,
  isLoading
}: {
  icon: React.ReactNode;
  heading: string;
  body: React.ReactNode;
  isLoading: boolean;
}) => (
  <Box sx={styles.widgetBlock}>
    <Box sx={styles.widgetBlockIcon}>{icon}</Box>
    <Stack sx={styles.widgetBlockText}>
      <Box sx={styles.widgetBlockHeading}>{heading}</Box>
      <Box sx={styles.widgetBlockBody}>
        {isLoading ? <Skeleton animation="wave" width={80} height={30} /> : body}
      </Box>
    </Stack>
  </Box>
);

export const RenderFormResponseBlocks = ({
  ApplicationId,
  name,
  created_at,
  submitted_by,
  rating,
  isLoading,
  expandedRows,
  linkToUser
}: {
  ApplicationId: number;
  name: string;
  created_at: string;
  submitted_by: string;
  rating: string;
  isLoading: boolean;
  expandedRows: number[];
  linkToUser?: string;
}) => {
  const formattedCreatedAtTime = formatToUserTimeZone(created_at);
  const BASE_URL = window.location.origin;
  return (
    <Box sx={styles.subWidget}>
      <Box sx={styles.widgetBlockContainer}>
        <WidgetBlock
          icon={<InsertDriveFile fontSize={'small'} />}
          heading="Application"
          body={<Box>{`${ApplicationId} - ${name}`}</Box>}
          isLoading={isLoading && !expandedRows.includes(ApplicationId)}
        />
        <WidgetBlock
          icon={<WatchLater fontSize={'small'} />}
          heading="Submitted at"
          body={<Box>{formattedCreatedAtTime}</Box>}
          isLoading={isLoading}
        />
        <WidgetBlock
          icon={<AccountBox fontSize={'small'} />}
          heading="Submitted by"
          body={
            <Box>
              {linkToUser ? (
                <a id="submitted-by-user-link" href={BASE_URL + linkToUser}>
                  {submitted_by}
                </a>
              ) : (
                submitted_by
              )}
            </Box>
          }
          isLoading={isLoading}
        />
        <WidgetBlock
          icon={<Star fontSize={'small'} />}
          heading="Rating"
          body={
            <Box
              sx={{
                color: getApplicationRatingColor(rating === 'N/A' ? 101 : Number(rating)),
                backgroundColor: 'transparent'
              }}
            >
              {!isNaN(parseFloat(rating)) ? Math.ceil(parseFloat(rating)) + '%' : '-'}
            </Box>
          }
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};
