"use strict";
function detectOperator(group) {
  if (group.includes("AND")) {
    return "AND";
  }
  return "OR";
}
export const booleanQueryToEditableArray = (booleanQuery) => {
  const baseOperatorsRegex = /\b(AND|OR|NOT)\b/g;
  const parenthesisContentRegex = new RegExp("(?<=\\().*?(?=\\))", "g");
  const matches = [...booleanQuery.match(parenthesisContentRegex) || []];
  if (matches.length === 0) {
    throw new Error("No valid groups found in the booleanQuery string");
  }
  const baseOperators = [];
  baseOperators.push("ROOT");
  const onlyBaseOperators = booleanQuery.replace(parenthesisContentRegex, "");
  let match = baseOperatorsRegex.exec(onlyBaseOperators);
  while (match !== null) {
    baseOperators.push(match[0]);
    match = baseOperatorsRegex.exec(onlyBaseOperators);
  }
  return matches.map((match2, index) => {
    const group = match2.trim();
    const operator = detectOperator(group);
    const queryValues = group.split(new RegExp(`\\b${operator}\\b`, "g")).map((value) => value.trim());
    return {
      id: index,
      baseOperator: baseOperators[index].trim(),
      operator,
      queryValues
    };
  });
};
export const editableBooleanArrayToBooleanQuery = (editableBooleanArray) => {
  return editableBooleanArray.map((obj, index) => {
    const queryPart = `(${obj.queryValues.join(` ${obj.operator} `)})`;
    if (index === 0) {
      return queryPart;
    }
    return `${obj.baseOperator} ${queryPart}`;
  }).join(" ");
};
