import React from 'react';
import { Popover } from '@mui/material';
import { ActionPopoverProps } from './types';

const ActionPopover: React.FC<ActionPopoverProps> = ({
  anchorEl,
  onClose,
  handleCopy,
  handleArchive,
  handleDelete,
  selectedAd,
  classes,
  adType,
  setDialogUrl,
  setDialogTitle,
  setDialogDescription,
  setDialogButtonText,
  setDialogButtonCallback,
  setDialogOpen,
  setDialogAdID
}) => {
  const urls = adType === 'indeed' ? selectedAd?.indeed_urls : selectedAd?.viewjobs_urls;
  const adBoard = adType === 'indeed' ? 'Indeed' : 'ViewJobs';

  return (
    <Popover
      id={anchorEl ? 'actions-menu-popover' : undefined}
      sx={{ ...classes.actionsMenu, minWidth: 'unset', width: '135px' }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {selectedAd && (
        <>
          {!['expired', 'archived'].includes(selectedAd.status) && (
            <span
              onClick={() => {
                setDialogUrl(urls.copy);
                setDialogTitle('Are you sure you want to clone this ad?');
                setDialogDescription(
                  `You will have to repost for your ad to be active on ${adBoard}.`
                );
                setDialogButtonText('Clone ad');
                setDialogButtonCallback(() => handleCopy);
                setDialogOpen(true);
              }}
              className={`clone-${adBoard}-ad-button`}
            >
              Clone
            </span>
          )}
          {selectedAd.status === 'expired' ? (
            <span
              onClick={() => {
                setDialogUrl(
                  `/api/v4/${adType}/jobs/${selectedAd.job_id}/job_ads/${selectedAd.id}/archive`
                );
                setDialogAdID(selectedAd.id);
                setDialogTitle('Are you sure you want to archive this ad?');
                setDialogDescription(null);
                setDialogButtonText('Archive now');
                setDialogButtonCallback(() => handleArchive);
                setDialogOpen(true);
              }}
            >
              Archive
            </span>
          ) : (
            selectedAd.status !== 'open' &&
            !['open', 'archived'].includes(selectedAd.status) && (
              <span
                onClick={() => {
                  setDialogUrl(
                    `/api/v4/${adType}/jobs/${selectedAd.job_id}/job_ads/${selectedAd.id}`
                  );
                  setDialogAdID(selectedAd.id);
                  setDialogTitle('Are you sure you want to delete this ad?');
                  setDialogDescription(
                    'Your job ad will be permanently deleted and you will not be able to recover your ad details.'
                  );
                  setDialogButtonText('Delete ad');
                  setDialogButtonCallback(() => handleDelete);
                  setDialogOpen(true);
                }}
              >
                Delete
              </span>
            )
          )}
        </>
      )}
    </Popover>
  );
};

export default ActionPopover;
