import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import { defaultFilters, dropdownSetting, dropdownOptions } from './config';
import ApprovalTemplatesTable from './ApprovalTemplatesTable';
import Api from '../API';
import {
  IFilterDropdownProps,
  IGetApprovalTemplates,
  TSetUserPreferences,
  IApprovalTemplateCreators,
  IApprovalTemplate
} from './types';
import { styles } from '../styles';
import FieldDropdown from '../Dropdowns/FieldDropdown';
import FilterDropdown from './FilterDropdown';
import Search from './Search';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { IUserPermissions } from '../../Components/sharedTypes';
import { fetchPreferences, updateUiSettings } from '../../helpers/uiPreferencesHelpers';

export default function ApprovalTemplates({
  apiKey,
  userPermissions,
  archived,
  setTemplateArchived,
  enableEmailTemplateFields
}: {
  apiKey: string;
  userPermissions: IUserPermissions | undefined;
  archived: boolean;
  setTemplateArchived: Dispatch<SetStateAction<boolean>>;
  enableEmailTemplateFields?: boolean;
}) {
  const [approvalTemplates, setApprovalTemplates] = useState<IApprovalTemplate[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<IFilterDropdownProps['filters']>(defaultFilters);
  const [creators, setCreators] = useState<IApprovalTemplateCreators[]>([]);
  const [activeColumns, setActiveColumns] = useState(dropdownSetting);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalApprovalTemplates, setTotalApprovalTemplates] = useState(0);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [density, setDensity] = useState<string>('Default');

  const getFirstApprovalTemplatesAndPreferences = useCallback(async () => {
    setIsLoading(true);
    try {
      const { res: preferenceData } = await fetchPreferences(
        `approval_templates${archived ? '_archived' : ''}`
      );
      const { res: densityData } = await fetchPreferences('universal');
      const preference = preferenceData?.ui_preferences[0];
      const densityPreference = densityData?.ui_preferences[0];
      preference?.sorting?.sortBy && setSortBy(preference.sorting.sortBy);
      preference?.sorting?.sortOrder && setSortOrder(preference.sorting.sortOrder.toLowerCase());
      preference?.filters && setFilters(preference.filters);
      const selectedCreatedBy = preference?.filters?.created_by?.map((x) => x.id);
      const sessionPage = sessionStorage.getItem(`approvalTemplatesTablePage`);
      !archived && sessionPage && setCurrentPage(Number(sessionPage));
      preference?.row_count && setRowsPerPage(preference.row_count);
      preference?.columns && setActiveColumns(preference.columns);
      densityPreference?.columns?.density && setDensity(densityPreference?.columns?.density);
      const response = await Api.getApprovalTemplates(
        {
          archived: archived || false,
          page: !archived && sessionPage ? Number(sessionPage) : 1,
          limit: preference?.row_count || rowsPerPage,
          sort: preference?.sorting?.sortBy || 'updated_at',
          sort_order: (preference?.sorting?.sortOrder?.toLowerCase() || 'desc').toUpperCase(),
          'q[search]': search,
          'filter[user_ids]': selectedCreatedBy?.filters?.length === 0 ? [] : selectedCreatedBy
        }
      );
      setApprovalTemplates(response.res.requisition_forms);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalTemplates(parseInt(response.resHead['x-total-count']));
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting approval templates',
        state: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  }, [search]);

  const setUserPreferences: TSetUserPreferences = async (
    limit,
    sort,
    columns,
    newFilters,
    density
  ) => {
    if (density) {
      await updateUiSettings({ columns: { density }, source: 'universal' }, (errorMessage) => {
        setSnackbar({
          message: `There was an error saving your preferences, ${errorMessage}`,
          state: 'error'
        });
      });
    } else {
      await updateUiSettings(
        {
          sortable_columns: columns || activeColumns,
          row_count: limit || rowsPerPage,
          filters: newFilters || filters,
          sorting: sort,
          source: `approval_templates${archived ? '_archived' : ''}`
        },
        () => {
          setSnackbar({
            message: 'There was an error saving your preferences',
            state: 'error'
          });
        }
      );
    }
  };

  const getApprovalTemplates: IGetApprovalTemplates = async (
    page,
    limit,
    searchQuery,
    sort,
    updatedFilters,
    archived
  ) => {
    setIsLoading(true);
    try {
      const selectedCreatedBy = (updatedFilters?.created_by || filters.created_by).map((x) => x.id);
      const response = await Api.getApprovalTemplates(
        {
          archived: archived || false,
          page: page || currentPage,
          limit: limit || rowsPerPage,
          sort: sort?.sortBy || sortBy,
          sort_order: (sort?.sortOrder || sortOrder).toUpperCase(),
          'q[search]': searchQuery !== null ? searchQuery : search,
          'filter[user_ids]': selectedCreatedBy?.length === 0 ? [] : selectedCreatedBy
        }
      );
      setApprovalTemplates(response.res.requisition_forms);
      setTotalPages(parseInt(response.resHead['x-total-pages']));
      setTotalApprovalTemplates(parseInt(response.resHead['x-total-count']));
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting approval templates',
        state: 'error'
      });
    } finally {
      if (sort || updatedFilters) {
        setUserPreferences(null, sort, null, updatedFilters, null);
      } else if (limit) {
        setUserPreferences(limit, null, null, null, null);
      }
      setIsLoading(false);
    }
  };

  const getCreators = useCallback(async () => {
    try {
      const response = await Api.getCreators();
      setCreators(response.res.creators);
    } catch (error) {
      setSnackbar({
        message: 'There was an error getting creators',
        state: 'error'
      });
    }
  }, [apiKey]);

  const handleChangeActiveColumns = async (columns: Record<string, boolean>) => {
    setActiveColumns(columns);
    setUserPreferences(null, null, columns, null, null);
  };

  useEffect(() => {
    getFirstApprovalTemplatesAndPreferences();
    getCreators();
  }, [apiKey, getFirstApprovalTemplatesAndPreferences, getCreators]);

  return (
    <Box>
      <Box sx={styles.filtersRow}>
        <Search
          setSearch={setSearch}
          getApprovalTemplates={getApprovalTemplates}
          archived={archived}
        />
        <FilterDropdown
          filters={filters}
          setFilters={setFilters}
          getApprovalTemplates={getApprovalTemplates}
          creators={creators}
          setUserPreferences={setUserPreferences}
          setCurrentPage={setCurrentPage}
          archived={archived}
        />
        <FieldDropdown
          options={dropdownOptions}
          activeColumns={activeColumns}
          handleChangeActiveColumns={handleChangeActiveColumns}
          density={density}
          setDensity={setDensity}
          userPermissions={userPermissions}
          setUserPreferences={setUserPreferences}
          label="approval-templates"
        />
      </Box>
      <ApprovalTemplatesTable
        approvalTemplates={approvalTemplates}
        setApprovalTemplates={setApprovalTemplates}
        getApprovalTemplates={getApprovalTemplates}
        setSnackbarState={setSnackbar}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalPages={totalPages}
        totalApprovalTemplates={totalApprovalTemplates}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        activeColumns={activeColumns}
        apiKey={apiKey}
        userPermissions={userPermissions}
        density={density}
        archived={archived}
        setTemplateArchived={setTemplateArchived}
        enableEmailTemplateFields={enableEmailTemplateFields}
      />
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
