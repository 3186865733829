"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import queryString from "query-string";
const BASE_URL = window.location.origin;
class RecruitApiClient {
  constructor() {
    this.handleResponse = (response) => __async(this, null, function* () {
      if (response.ok) {
        const resHead = {};
        [...response.headers].forEach(
          (header) => resHead[header[0]] = header[1]
        );
        const text = yield response.text();
        const data = text ? JSON.parse(text) : null;
        return { res: data, resHead };
      }
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
    this.get = (path, params = null, headers = null, download) => fetch(
      `${BASE_URL}${path}${params ? `?` : ""}${queryString.stringify(params, {
        arrayFormat: "bracket",
        encode: false
      })}`,
      {
        headers: this.getHeaders(headers)
      }
    ).then((r) => {
      if (download) return r.blob();
      return this.handleResponse(r);
    });
    this.post = (path, params = {}, headers = null, download) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "POST",
      body: JSON.stringify(params)
    }).then((r) => {
      if (download) return r.blob();
      return this.handleResponse(r);
    });
    this.put = (path, params = {}, headers = null) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "PUT",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
    this.delete = (path, params = {}, headers = null) => fetch(`${BASE_URL}${path}`, {
      headers: this.getHeaders(headers),
      method: "DELETE",
      body: JSON.stringify(params)
    }).then((r) => this.handleResponse(r));
  }
  getHeaders(headers) {
    return Object.assign(
      {},
      {
        "Content-Type": "application/json"
      },
      headers
    );
  }
}
export default new RecruitApiClient();
