"use strict";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
const ALLOWED_FORMATS = {
  long: "Do MMMM YYYY[,] h:mma",
  short: "Do MMM YYYY",
  shortWithDayOfWeek: "ddd, D MMM YYYY",
  onlyTime: "LT",
  ymd: "YYYY-MM-DD"
};
const formatToUserTimeZone = (timeString, format = "long") => {
  var _a;
  return dayjs(timeString).tz(userTimeZone).format((_a = ALLOWED_FORMATS[format]) != null ? _a : ALLOWED_FORMATS.short);
};
export const userTimeZone = (() => {
  var _a;
  return ((_a = document.querySelector('meta[name="user-timezone"]')) == null ? void 0 : _a.getAttribute("value")) || dayjs.tz.guess();
})();
export default formatToUserTimeZone;
