import React from 'react';
import { Stack } from '@mui/material';
import { styles } from '../../styles';
import { ICognologyRecord, IIntegrationsResponse, ICognologyData } from '../../../types';
import { theme } from '../../../../../ThemeContext/ThemeObject';
import dayjs from 'dayjs';
import { ICountries, IStates } from '../../../../Components/sharedTypes';
import { useQueryClient } from '@tanstack/react-query';
import CognologyApprovalDetails from './CognologyApprovalDetails';
import DocTypeIcon from '../../../../Components/Utilities/DocTypeIcon';

export default function CognologySummary({
  record,
  requisition,
  reapprovalRequired,
  country,
  state,
  startDate
}: {
  record: ICognologyRecord;
  requisition?: { url: string; name: string } | null;
  reapprovalRequired?: boolean;
  country?: ICountries | null;
  state?: IStates | null;
  startDate?: Date | null;
}) {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  const cognologyData = queryClient.getQueryData<ICognologyData>(['cognology data']);
  return (
    <>
      {requisition && reapprovalRequired && (
        <CognologyApprovalDetails {...{ requisition, reapprovalRequired }} />
      )}
      <Stack sx={styles.cognologySectionTitle}>Candidate Details</Stack>
      {!integrationsData?.cognology_config.id_blank && (
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>Employee ID</Stack>
          <Stack>{record.candidate_employee_id || '-'}</Stack>
        </Stack>
      )}
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Title</Stack>
        <Stack>{record.candidate_title || '-'}</Stack>
      </Stack>
      <Stack sx={{ ...styles.modalDoubleColumn, div: { width: '100%' } }}>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>First name</Stack>
          <Stack>{record.candidate_first_name || '-'}</Stack>
        </Stack>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>Last name</Stack>
          <Stack>{record.candidate_last_name || '-'}</Stack>
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Email</Stack>
        <Stack>{record.candidate_email || '-'}</Stack>
      </Stack>
      <Stack sx={{ ...styles.modalDoubleColumn, div: { width: '100%' } }}>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>
            {'Phone (must include country code)'}
          </Stack>
          <Stack>{record.candidate_phone1 || '-'}</Stack>
        </Stack>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>
            {'Mobile (must include country code)'}
          </Stack>
          <Stack>{record.candidate_phone2 || '-'}</Stack>
        </Stack>
      </Stack>
      <Stack sx={{ ...styles.modalDoubleColumn, div: { width: '100%' } }}>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>Street address</Stack>
          <Stack>{record.candidate_addr_street1 || '-'}</Stack>
        </Stack>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>{`Street address cont'd`}</Stack>
          <Stack>{record.candidate_addr_street2 || '-'}</Stack>
        </Stack>
      </Stack>
      <Stack sx={{ ...styles.modalDoubleColumn, div: { width: '100%' } }}>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>City, town or suburb</Stack>
          <Stack>{record.candidate_addr_suburb || '-'}</Stack>
        </Stack>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>Postcode or zipcode</Stack>
          <Stack>{record.candidate_addr_postcode || '-'}</Stack>
        </Stack>
      </Stack>
      <Stack sx={{ ...styles.modalDoubleColumn, div: { width: '100%' } }}>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>Country</Stack>
          <Stack>{country?.printable_name || record.candidate_addr_country || '-'}</Stack>
        </Stack>
        <Stack>
          <Stack sx={{ color: theme.palette.common.grey }}>State, region or province</Stack>
          <Stack>{state?.name || record.candidate_addr_state || '-'}</Stack>
        </Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Gender</Stack>
        <Stack>{record.candidate_gender || '-'}</Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Manager employee ID</Stack>
        <Stack>{record.manager_id || '-'}</Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Application URL</Stack>
        <Stack>{record.recruitment_record_url || '-'}</Stack>
      </Stack>
      <Stack>
        <Stack sx={{ color: theme.palette.common.grey }}>Start date</Stack>
        <Stack>
          {startDate || record.start_date ? dayjs(startDate).format('dddd, D MMMM YYYY') : '-'}
        </Stack>
      </Stack>
      <Stack sx={styles.cognologyCategoriesContainer}>
        {cognologyData?.categories?.map((category) => (
          <Stack key={category.id} sx={{ width: '48%' }}>
            <Stack sx={{ color: theme.palette.common.grey }}>{category.name}</Stack>
            <Stack>
              {record.categories?.[category.id]?.name ||
                record.categories?.[category.id]?.new_value ||
                '-'}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {!!cognologyData?.application_assets?.length && (
        <>
          <Stack sx={styles.cognologySectionTitle}>Attachments</Stack>
          {cognologyData.application_assets.map((asset, index) => (
            <Stack key={index} sx={styles.modalDoubleColumn}>
              <Stack sx={styles.cognologyAttachmentContainer}>
                <Stack sx={styles.fileIcon}>{DocTypeIcon(asset.file_name.split('.').pop())}</Stack>
                <Stack>{asset.file_name}</Stack>
              </Stack>
              <Stack>
                <Stack sx={{ color: theme.palette.common.grey }}>Attachment tag</Stack>
                <Stack>{record.assets?.[asset.id]?.name || '-'}</Stack>
              </Stack>
            </Stack>
          ))}
        </>
      )}
    </>
  );
}
