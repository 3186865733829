import React, { Dispatch } from 'react';
import { Button, Dialog, Stack } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';
import { styles } from '../../styles';
import { sharedClasses } from '../../../../Components/CustomUIElements/sharedClasses';
import { ApplicationAction, IApplication } from '../../../types';
import { ModalType } from '../../../config';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../API';
import { useGetCognologyData } from '../../../helper';

export default function CognologyAfterApprovalModal({
  open,
  onClose,
  dispatch
}: {
  open: boolean;
  onClose: () => void;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { cognologyData } = useGetCognologyData({
    applicationId: application?.id,
    dispatch
  });

  const { mutate: exportToCognology } = useMutation({
    mutationFn: async () => {
      if (application) {
        const { res } = await Api.exportToCognology(application.id);
        return res;
      }
    },
    onSuccess: (res) => {
      if (!res) return;
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      dispatch({ type: 'SET_MODALS_OPEN', payload: null });
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack sx={styles.alreadyExportedModal}>
        <ErrorIcon sx={{ color: '#5BC4BF', fontSize: '4rem' }} />
        <Stack sx={styles.modalTitle}>Export to Cognology</Stack>
        <Stack sx={{ alignItems: 'center', color: '#333333', paddingBottom: 3 }}>
          This application has been approved
        </Stack>
        <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
          <Button
            sx={sharedClasses.genericRedButton}
            onClick={() =>
              cognologyData?.record.reapproval_required_for_modifications
                ? dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.COGNOLOGY })
                : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EDIT_COGNOLOGY })
            }
          >
            {cognologyData?.record.reapproval_required_for_modifications
              ? 'Reset approval'
              : 'Edit candidate details'}
          </Button>
          <Button sx={sharedClasses.genericButton} onClick={() => exportToCognology()}>
            Continue to export
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
