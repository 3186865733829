import React, { Dispatch } from 'react';
import { Link, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useQueryClient } from '@tanstack/react-query';
import { IIntegrationsResponse, ApplicationAction } from '../../types';
import UpdateStatusIcon from '../UpdateStatusIcon';
import formatToUserTimeZone from '../../../utils/formatToUserTimeZone';

export default function ReferooRecord({ dispatch }: { dispatch: Dispatch<ApplicationAction> }) {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  if (!integrationsData?.referoo_record) return null;
  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Referoo requested checks</Stack>
        <UpdateStatusIcon dispatch={dispatch} />
      </Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {[
              'Submitted at',
              'References required',
              'Reference ID',
              'Candidate status',
              'Referees status'
            ].map((title, index) => (
              <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                <Stack>{title}</Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ verticalAlign: 'top', minWidth: '120px' }}>
              {formatToUserTimeZone(integrationsData.referoo_record.created_at)}
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top', minWidth: '150px' }}>
              {integrationsData.referoo_record.number_of_references_required}
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top', minWidth: '110px' }}>
              {integrationsData.referoo_record.referoo_id}
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top', minWidth: '120px' }}>
              {integrationsData.referoo_record.candidate_status}
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <Stack sx={{ rowGap: 2 }}>
                {integrationsData.referoo_record.referees_status ? (
                  <>
                    {integrationsData.referoo_record.referees_status.map((status, index) => (
                      <Stack key={index} sx={{ rowGap: 1 }}>
                        <Stack>{status.referee}</Stack>
                      </Stack>
                    ))}
                  </>
                ) : (
                  '-'
                )}
                {integrationsData.referoo_record.report_url && (
                  <Link
                    href={
                      integrationsData.referoo_login_url ||
                      integrationsData.referoo_record.report_url
                    }
                    target="_blank"
                    style={{
                      color: 'inherit',
                      width: 'fit-content',
                      textDecoration: 'underline'
                    }}
                  >
                    View All Report
                  </Link>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
