import React, { SetStateAction, useState, Dispatch, useCallback } from 'react';
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  IconButton,
  Box
} from '@mui/material';
import { styles } from '../styles';
import { IApproversData } from '../types';
import EditApproverModal from './EditApproverModal';
import ActionModal from './ActionModal';
import moment from 'moment-timezone';
import Api from '../API';
import { IUserPermissions } from '../../Components/sharedTypes';
import GenericDialog from '../../Components/Modals/GenericDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { StylesProvider } from '@material-ui/core';

const tableHeaders = [
  'Name',
  'State',
  'Sent At',
  'Actioned At',
  'Comments',
  'COI Response',
  'Actions'
];

const TableData = ({
  apiKey,
  approver,
  setSnackbar,
  requisitionId,
  approvalFormState,
  getApprovalFormData,
  index,
  previousApproverActioned,
  userPermissions
}: {
  apiKey: string;
  approver: IApproversData;
  setSnackbar: Dispatch<
    SetStateAction<{
      message: string;
      state: string;
    }>
  >;
  requisitionId: number;
  approvalFormState: string;
  getApprovalFormData: () => void;
  index: number;
  previousApproverActioned: boolean;
  userPermissions: IUserPermissions;
}) => {
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [trashAlertOpen, setTrashAlertOpen] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState<IApproversData>();
  const [showMore, setShowMore] = useState([]);
  const sentAt = approver.sent_at
    ? moment
        .tz(approver.sent_at, timeZoneString)
        .format('DD MMM YYYY,-h:mma z')
        .split('-')
        .map((line) => <div key={`${approver.id}-${line}`}>{line}</div>)
    : '-';
  const actionAt = approver.action_at
    ? moment
        .tz(approver.action_at, timeZoneString)
        .format('DD MMM YYYY,-h:mma z')
        .split('-')
        .map((line) => <div key={`${approver.id}-${line}`}>{line}</div>)
    : '-';

  const resendEmail = async (approverId: number) => {
    try {
      await Api.postResendEmail(requisitionId, { approver_id: approverId });
      setSnackbar({
        message: 'Email sent successfully',
        state: 'success'
      });
    } catch (err) {
      setSnackbar({
        message: 'Failed to resend email',
        state: 'error'
      });
    } finally {
      getApprovalFormData();
    }
  };

  const removeApprover = async (approver: IApproversData) => {
    try {
      const response = await Api.putApprovalForm(requisitionId, {
        requisition: {
          approvers_attributes: {
            [index]: { id: approver.id, _destroy: 1 }
          }
        }
      });
      if (response.res.success) {
        setSnackbar({
          message: response.res.success,
          state: 'success'
        });
      }
    } catch (err) {
      setSnackbar({
        message: `Failed to remove approver, ${err}`,
        state: 'error'
      });
    } finally {
      setTrashAlertOpen(false);
      getApprovalFormData();
    }
  };

  const handleShowMore = useCallback(
    (id: number) => {
      if (!showMore.includes(id)) {
        const copy = [...showMore];
        copy.push(id);
        setShowMore(copy);
      }
      const flag = showMore.indexOf(id);
      if (flag > -1) {
        const copy = [...showMore];
        copy.splice(flag, 1);
        setShowMore(copy);
      }
    },
    [showMore]
  );

  return (
    <TableRow>
      <TableCell sx={{ ...styles.approversTableCell, paddingLeft: 'unset' }}>
        <Stack>
          {approver.email === '*' ? 'Please select an approval manager' : approver.name}
        </Stack>
      </TableCell>
      <TableCell sx={{ ...styles.approversTableCell, display: 'flex', justifyContent: 'center' }}>
        <Stack sx={{ ...styles[approver.state], ...styles.approverTableStateButton }}>
          {approver.state}
        </Stack>
      </TableCell>
      <TableCell sx={styles.approversTableCell}>
        <Stack>{sentAt}</Stack>
      </TableCell>
      <TableCell sx={styles.approversTableCell}>
        <Stack>{actionAt}</Stack>
      </TableCell>
      <TableCell sx={styles.approversTableCell}>
        <Stack>{approver.comment ? approver.comment : '-'}</Stack>
      </TableCell>
      <TableCell sx={{ ...styles.approversTableCell, minWidth: '140px' }}>
        <Box sx={!showMore.includes(approver.id) ? styles.coiColumn : {}}>
          <Box>{approver.coi_response || '-'}</Box>
        </Box>
        {approver.coi_response && (
          <Box
            key={`show-${approver.id}`}
            sx={styles.link}
            onClick={() => handleShowMore(approver.id)}
          >
            {showMore.includes(approver.id) ? 'Show less' : 'Show more'}
          </Box>
        )}
      </TableCell>
      {approvalFormState !== 'declined' &&
        (approver.state === 'pending' || approver.state === 'failed') && (
          <TableCell sx={styles.approversTableCell}>
            <Button
              disableElevation
              sx={styles.normalButton}
              variant="text"
              onClick={() => setEditModalOpen(true)}
            >
              Edit
            </Button>
            {!approver.name.includes('deleted') && (
              <Button
                disabled={!previousApproverActioned}
                disableElevation
                sx={styles.normalButton}
                variant="text"
                onClick={() => resendEmail(approver.id)}
              >
                Resend
              </Button>
            )}
            {previousApproverActioned && approver.editable && (
              <Button
                disableElevation
                sx={styles.normalButton}
                variant="text"
                onClick={() => setActionModalOpen(true)}
              >
                Approve/Decline
              </Button>
            )}
            {approvalFormState === 'pending' &&
              userPermissions?.['Approval Forms']?.['Edit Approval Form at Any Stage (HR use)'] && (
                <IconButton
                  sx={{ color: '#D6827D' }}
                  onClick={() => {
                    setTrashAlertOpen(true);
                    setSelectedApprover(approver);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: '18px' }} />
                </IconButton>
              )}
          </TableCell>
        )}
      <EditApproverModal
        apiKey={apiKey}
        approver={approver}
        modalsOpen={editModalOpen}
        setModalsOpen={setEditModalOpen}
        setSnackbar={setSnackbar}
        requisitionId={requisitionId}
        getApprovalFormData={getApprovalFormData}
        index={index}
      />
      <ActionModal
        apiKey={apiKey}
        approver={approver}
        modalsOpen={actionModalOpen}
        setModalsOpen={setActionModalOpen}
        setSnackbar={setSnackbar}
        requisitionId={requisitionId}
        getApprovalFormData={getApprovalFormData}
        index={index}
      />
      <GenericDialog
        isDialogOpen={trashAlertOpen}
        setDialogOpen={setTrashAlertOpen}
        title={'Remove approver?'}
        description={'Are you sure want to remove this approver?'}
        buttonCallback={() => {
          setTrashAlertOpen(false);
          if (selectedApprover) {
            removeApprover(selectedApprover);
          }
          setSelectedApprover(undefined);
        }}
        callbackLoading={false}
        buttonText="Remove"
        url=""
      />
    </TableRow>
  );
};

export default function ApproversTable({
  apiKey,
  approvers,
  setSnackbar,
  requisitionId,
  approvalFormState,
  getApprovalFormData,
  userPermissions
}: {
  apiKey: string;
  approvers: IApproversData[];
  setSnackbar: Dispatch<
    SetStateAction<{
      message: string;
      state: string;
    }>
  >;
  requisitionId: number;
  approvalFormState: string;
  getApprovalFormData: () => void;
  userPermissions: IUserPermissions;
}) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {tableHeaders.map((header, i) => (
            <TableCell
              sx={{
                ...styles.approversTableHeader,
                paddingLeft: `${header === 'Name' ? 'unset' : ''}`
              }}
              key={i}
            >
              <Stack
                sx={{
                  alignItems: `${['State'].includes(header) ? 'center' : ''}`,
                  marginLeft: `${['Actions'].includes(header) ? '18px' : ''}`
                }}
              >
                {header}
              </Stack>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {approvers.map((approver, i) => (
          <TableData
            key={approver.id}
            approver={approver}
            apiKey={apiKey}
            setSnackbar={setSnackbar}
            requisitionId={requisitionId}
            approvalFormState={approvalFormState}
            getApprovalFormData={getApprovalFormData}
            index={i}
            previousApproverActioned={i === 0 || approvers[i - 1]?.state === 'approved'}
            userPermissions={userPermissions}
          />
        ))}
      </TableBody>
    </Table>
  );
}
