import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import Api from '../../ApprovalForms/API';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function ListItem({
  manager,
  setManager,
  setOpenManager
}: {
  manager?: { id: number; name: string; email: string } | null;
  setManager: Dispatch<SetStateAction<{ id: number; name: string; email: string } | null>>;
  setOpenManager: Dispatch<SetStateAction<boolean>>;
}) {
  const queryClient = useQueryClient();
  const [editName, setEditName] = useState<boolean>(false);
  const [editEmail, setEditEmail] = useState<boolean>(false);
  const [name, setName] = useState<string>(manager?.name || '');
  const [email, setEmail] = useState<string>(manager?.email || '');
  const approvalManagerId = manager?.id;

  const {
    status: createStatus,
    error: createError,
    mutate: addManager
  } = useMutation({
    mutationFn: () =>
      Api.createApprovalManager(
        { requisition_manager: { name: name, email: email } }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(['managers'], { exact: true });
      setOpenManager(false);
      setManager(null);
    }
  });

  const {
    status: editStatus,
    error: editError,
    mutate: editManager
  } = useMutation({
    mutationFn: () =>
      Api.updateApprovalManager(
        { requisition_manager: { name: name, email: email } },
        approvalManagerId
      ),
    onSuccess: (e) => {
      queryClient.invalidateQueries(['managers'], { exact: true });
      setOpenManager(false);
      setManager(null);
    }
  });

  return (
    <Box>
      <Button
        onClick={() => {
          setOpenManager(false);
          setManager(null);
        }}
      >
        Back to list
      </Button>
      {manager && <Stack>id: {manager.id}</Stack>}
      <Stack flexDirection="row" sx={{ padding: '16px 0px' }}>
        <TextField
          value={name}
          disabled={manager ? !editName : false}
          label="Name"
          onChange={(e) => setName(e.target.value)}
        />
        {manager && <Button onClick={() => setEditName(!editName)}>Edit Name</Button>}
      </Stack>
      <Stack flexDirection="row" sx={{ padding: '16px 0px' }}>
        <TextField
          value={email}
          disabled={manager ? !editEmail : false}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {manager && <Button onClick={() => setEditEmail(!editEmail)}>Edit Email</Button>}
      </Stack>
      <Button onClick={() => (manager ? editManager() : addManager())}>
        {manager ? 'Save' : 'Add'}
      </Button>
    </Box>
  );
}
