import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { sharedClasses } from './sharedClasses';

interface IEmptyStateProps {
  svgIcon: ReactNode;
  label: string;
}

export function EmptyState({ svgIcon, label }: IEmptyStateProps) {
  return (
    <Stack sx={{ padding: 7.5, alignItems: 'center', rowGap: 2 }}>
      {svgIcon}
      <Typography sx={sharedClasses.emptyStateText}>{label}</Typography>
    </Stack>
  );
}
