import React, { useState, useRef } from 'react';
import { classes } from '../styles';
import { Box, Snackbar, Button, Tooltip, IconButton } from '@mui/material';
import { IJobAdViewjobsProps, IJobAdViewJobs } from '../types';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ActionButton from '../ActionButton';
import axios from 'axios';
import ActionPopover from '../ActionPopover';
import GenericDialog from '../GenericDialog';

export default function AdTable({ ads, jobAds, setAds, adsStatus, apiKey }: IJobAdViewjobsProps) {
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [tableHovered, setTableHovered] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogAdID, setDialogAdID] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [selectedAd, setSelectedAd] = useState<IJobAdViewJobs | null>(null);

  const actionCellRef = useRef(null);
  const jobCellRef = useRef(null);

  function handleSnack(message: string) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }

  async function handleArchive(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.put(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex((ad: IJobAdViewJobs) => ad.id === id);
      const copyJob = newAds[adsStatus][index];
      copyJob.status = 'archived';
      newAds.archived && newAds.archived.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been archived`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not archive ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleCopy(url: string) {
    setDialogLoading(true);
    try {
      const { data } = await axios.get(`${url}?operation=clone`);
      window.location.href = data.url;
    } catch (e) {
      console.log(e);
      handleSnack('Could not clone ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleDelete(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.delete(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      newAds[adsStatus] = newAds[adsStatus].filter((ad: IJobAdViewJobs) => ad.id !== id);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been deleted`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not delete ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.tableContainer}>
          <Box sx={classes.table}>
            <Box sx={{ ...classes.row, ...classes.rowHeader }}>
              <Box
                component="span"
                ref={jobCellRef}
                sx={{
                  ...classes.title,
                  minWidth: '182px',
                  ...classes.sticky,
                  ...classes.scrollShadowLeft,
                  ...(tableHovered && classes.scrollShadowVisible),
                  left: 0
                }}
              >
                Job
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Creation Date
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Created By
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Expiry
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Active on ViewJobs
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Sponsored
              </Box>
              <Box
                component="span"
                sx={{
                  ...classes.stickyRight,
                  ...classes.scrollShadowRight,
                  ...(tableHovered && classes.scrollShadowVisible)
                }}
                ref={actionCellRef}
              ></Box>
            </Box>
            {ads && ads.length > 0 ? (
              ads.map((ad: IJobAdViewJobs) => (
                <Box key={ad.id} sx={classes.row}>
                  <Box
                    sx={{
                      ...classes.title,
                      minWidth: '182px',
                      ...classes.sticky,
                      ...classes.scrollShadowLeft,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  >
                    {ad.title}
                  </Box>
                  <Box>{moment(ad.created_at).format('D MMM YYYY')}</Box>
                  <Box>{ad.created_by.name}</Box>
                  <Box>
                    {ad.expired_at ? moment(ad.expired_at).format('D MMM YYYY') : 'No expiry'}
                  </Box>
                  <Box sx={classes.center}>
                    {ad.status === 'open' ? (
                      <CheckCircleIcon sx={classes.iconGreen} />
                    ) : (
                      <CancelIcon sx={classes.iconRed} />
                    )}
                  </Box>
                  <Box sx={classes.center}>
                    {ad.featured ? (
                      <CheckCircleIcon sx={classes.iconGreen} />
                    ) : (
                      <CancelIcon sx={classes.iconRed} />
                    )}
                  </Box>
                  <ActionButton
                    ad={ad}
                    jobAds={jobAds}
                    setAds={setAds}
                    adsStatus={adsStatus}
                    tableHovered={tableHovered}
                    apiKey={apiKey}
                    classes={classes}
                    adType={'view_jobs'}
                    setDialogLoading={setDialogLoading}
                    setDialogUrl={setDialogUrl}
                    setDialogOpen={setDialogOpen}
                    setActionsAnchorEl={setActionsAnchorEl}
                    setDialogAdID={setDialogAdID}
                    setDialogTitle={setDialogTitle}
                    setDialogDescription={setDialogDescription}
                    setDialogButtonText={setDialogButtonText}
                    setDialogButtonCallback={setDialogButtonCallback}
                    setSelectedAd={setSelectedAd}
                    handleSnack={handleSnack}
                    handleCopy={handleCopy}
                    handleArchive={handleArchive}
                  />
                </Box>
              ))
            ) : (
              <Box sx={classes.row}>
                <Box sx={classes.title}>No ads to display</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ActionPopover
        anchorEl={actionsAnchorEl}
        onClose={() => {
          setActionsAnchorEl(null);
          setTimeout(() => {
            setSelectedAd(null);
          }, 300);
        }}
        handleCopy={handleCopy}
        handleArchive={handleArchive}
        handleDelete={handleDelete}
        selectedAd={selectedAd}
        classes={classes}
        adType={'view_jobs'}
        setDialogUrl={setDialogUrl}
        setDialogTitle={setDialogTitle}
        setDialogDescription={setDialogDescription}
        setDialogButtonText={setDialogButtonText}
        setDialogButtonCallback={setDialogButtonCallback}
        setDialogOpen={setDialogOpen}
        setDialogAdID={setDialogAdID}
      />
      <GenericDialog
        url={dialogUrl}
        title={dialogTitle}
        description={dialogDescription}
        buttonText={dialogButtonText}
        buttonCallback={dialogButtonCallback}
        callbackLoading={dialogLoading}
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        dialogAdID={dialogAdID}
      />
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
