import React from 'react';
import { Button, Dialog, Stack } from '@mui/material';
import { Schedule as ScheduleIcon } from '@mui/icons-material';
import { styles } from '../../styles';
import { sharedClasses } from '../../../../Components/CustomUIElements/sharedClasses';
import CognologySummary from './CognologySummary';
import { useQueryClient } from '@tanstack/react-query';
import { ICognologyData } from '../../../types';
import { theme } from '../../../../../ThemeContext/ThemeObject';

export default function CognologyAfterApprovalModal({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const cognologyData = queryClient.getQueryData<ICognologyData>(['cognology data']);

  if (!cognologyData) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Stack sx={{ ...styles.alreadyExportedModal, width: 'unset', overflow: 'auto' }}>
        <ScheduleIcon sx={{ color: theme.palette.secondary.yellow, fontSize: '4rem' }} />
        <Stack sx={styles.modalTitle}>Export to Cognology</Stack>
        <Stack sx={{ alignItems: 'center', color: '#333333' }}>
          This Cognology record is waiting for approval. It must be approved before it can be
          exported.
        </Stack>
        <Stack sx={{ width: '100%', rowGap: 2, overflow: 'auto', padding: '8px 0px' }}>
          <CognologySummary
            record={cognologyData.record}
            requisition={cognologyData.cognology_requisition}
            reapprovalRequired={cognologyData.record.reapproval_required_for_modifications}
          />
        </Stack>
        <Button sx={sharedClasses.genericButton} onClick={onClose}>
          Exit
        </Button>
      </Stack>
    </Dialog>
  );
}
