import React, { useState, useRef, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DebouncedFormTextField } from '../../../Components/CustomUIElements/FormTextField';
import FormSelectField from '../../../Components/CustomUIElements/FormSelectField';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { INewApplicationProps } from '../../types';
import { applicantDetailsDefault } from '../config';
import { classes } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import SingleAttachmentSelector from '../../../Components/Utilities/SingleAttachmentSelector';
import { FileWithPath } from 'react-dropzone';
import { serialize } from 'object-to-formdata';
import QuestionFieldGenerator, {
  IApplicantQuestion,
  IFieldChoice
} from '../../../Components/Utilities/QuestionFieldGenerator';
import GenericDialog from '../../../Components/Modals/GenericDialog';
import { EntityContext } from '../../Job';
import { emailIsValid } from '../../../Components/Utilities/emailIsValid';
import { ICountries, IStates } from '../../../Components/sharedTypes';
import { parseCountryData } from '../../../Components/Utilities/countryParser';

const createApplicantQuestionsObject = (questions: IApplicantQuestion[]) => {
  const newObject = {};
  questions.forEach((question: IApplicantQuestion) => {
    if (question.type === 'RankField') {
      newObject[question.id] = {
        ...question,
        data: {}
      };
      question.field_choices.forEach((choice: IFieldChoice, index: number) => {
        newObject[question.id]['data'][choice.id] = index + 1;
      });
    } else if (
      question.type === 'AvailabilityField' ||
      question.type === 'CsvDataLookupField' ||
      question.type === 'JobReferenceLookupField'
    ) {
      newObject[question.id] = {
        ...question,
        data: 'validation bypass'
      };
    } else {
      newObject[question.id] = {
        ...question,
        data: ''
      };
    }
  });
  return newObject;
};

const createQuestionErrorsObject = (questions: IApplicantQuestion[]) => {
  const errors: Record<number, string> = {};
  questions.forEach((question: IApplicantQuestion) => {
    errors[question.id] = '';
  });
  return errors;
};

const BASE_URL = window.location.origin;
export const genderOptions = [
  { id: 'm', label: 'Male' },
  { id: 'f', label: 'Female' },
  { id: 'Non-binary / Non-conforming', label: 'Non-binary / Non-conforming' },
  { id: 'Prefer not to say', label: 'Prefer not to say' }
];
export default function NewApplication({
  apiKey,
  modalsOpen,
  setModalsOpen,
  getApplications,
  jobId,
  jobQuestions,
  jobAttachments,
  jobSources,
  jobHasPrivacyAcknowledgement,
  setSnackbar,
  setJob
}: INewApplicationProps) {
  const { apply_with_mobile, apply_with_phone } = useContext(EntityContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmCancelIsOpen, setConfirmCancelIsOpen] = useState(false);
  const [countries, setCountries] = useState<ICountries[]>([]);
  const [regionValue, setRegionValue] = useState<IStates | null>(null);
  const [countryValue, setCountryValue] = useState<ICountries | null>(null);
  const [applicantDetails, setApplicantDetails] = useState({
    ...applicantDetailsDefault,
    phone: { ...applicantDetailsDefault.phone, required: apply_with_phone },
    phone2: { ...applicantDetailsDefault.phone2, required: apply_with_mobile }
  });
  const [applicantQuestions, setApplicantQuestions] = useState(() =>
    createApplicantQuestionsObject(jobQuestions || [])
  );
  const [questionErrors, setQuestionErrors] = useState(() =>
    createQuestionErrorsObject(jobQuestions || [])
  );
  const [fileList, setFileList] = useState<Record<string, FileWithPath>>({});
  const [uploadErrors, setUploadErrors] = useState<Record<string, string>>({});
  const [sourceValue, setSourceValue] = useState<string | number>('');
  const [sourceError, setSourceError] = useState('');
  const [privacyAcknowledged, setPrivacyAcknowledged] = useState(false);
  const [privacyError, setPrivacyError] = useState('');
  const [sendNotification, setSendNotification] = useState<boolean>(false);
  const questionsRef = useRef<Array<HTMLDivElement | null>>([]);
  const uploadsRef = useRef<Array<HTMLDivElement | null>>([]);
  const containsDuplicates = (array: string[]) => {
    return new Set(array).size !== array.length;
  };

  const fieldRefs = {
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    phone2: useRef<HTMLInputElement>(null),
    addressFirstLine: useRef<HTMLInputElement>(null),
    addressSecondLine: useRef<HTMLInputElement>(null),
    suburb: useRef<HTMLInputElement>(null),
    postcode: useRef<HTMLInputElement>(null),
    country: useRef<HTMLInputElement>(null),
    region: useRef<HTMLInputElement>(null),
    employeeId: useRef<HTMLInputElement>(null)
  };

  const sourceRef = useRef<HTMLInputElement>(null);
  const privacyRef = useRef<HTMLInputElement>(null);

  const handleClearForm = () => {
    setApplicantDetails(applicantDetailsDefault);
    setApplicantQuestions(createApplicantQuestionsObject(jobQuestions || []));
    setQuestionErrors(createQuestionErrorsObject(jobQuestions || []));
    setFileList({});
    setCountryValue(null);
    setRegionValue(null);
    setModalsOpen((prev) => ({ ...prev, newApplication: false }));
    setPrivacyError('');
  };

  useEffect(() => {
    if (!modalsOpen.newApplication || countries.length > 0) return;
    let isMounted = true;
    const handleGetCountries = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/system/v1/countries_states.json`, {
          method: 'GET'
        });
        const data = await response.json();
        const countriesWithlabels = parseCountryData(data);
        if (isMounted) {
          setCountries(countriesWithlabels);
        }
      } catch (error) {
        console.error(error);
      }
    };
    handleGetCountries();
    return () => {
      isMounted = false;
    };
  }, [modalsOpen.newApplication, countries]);

  const handleSubmitApplication = async () => {
    if (!handleValidateFields()) return;
    setIsSubmitting(true);
    const answers_attributes = Object.entries(applicantQuestions)
      .filter(([_, value]) => value.data !== '')
      .map(([id, value]) => {
        if (value.type === 'RadioButtonField' || value.type === 'SelectField') {
          return {
            field_id: id,
            field_choice_id: value.data
          };
        } else if (value.type === 'CheckBoxField') {
          return {
            field_id: id,
            field_choice_ids: Object.entries(value.data)
              .filter(([_, value]) => value)
              .map(([id]) => id)
          };
        } else {
          return {
            field_id: id,
            data: value.data
          };
        }
      });
    const candidate_attributes = {
      firstname: applicantDetails.firstName.value,
      lastname: applicantDetails.lastName.value,
      email: applicantDetails.email.value,
      phone1: applicantDetails.phone.value,
      phone2: applicantDetails.phone2.value,
      address_attributes: {
        street1: applicantDetails.addressFirstLine.value,
        ...(applicantDetails.addressSecondLine.value !== '' && {
          street2: applicantDetails.addressSecondLine.value
        }),
        suburb: applicantDetails.suburb.value,
        postcode: applicantDetails.postcode.value,
        country_id: applicantDetails.country.value,
        state_id: applicantDetails.region.value,
        ...(applicantDetails.employeeId.value !== '' && {
          employee_id: applicantDetails.employeeId.value
        })
      },
      gender: applicantDetails.gender.value.id
    };
    const applicationData = {
      application: {
        candidate_attributes,
        answers_attributes,
        ...(jobHasPrivacyAcknowledgement && { privacy_acknowledgement: privacyAcknowledged }),
        ...(sourceValue && { source_id: sourceValue }),
        asset: fileList,
        send_notification: sendNotification
      }
    };
    const formData = serialize(applicationData);
    try {
      const response = await fetch(`${BASE_URL}/api/v4/jobs/${jobId}/applications`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        },
        body: formData
      });
      if (response.status === 201) {
        setModalsOpen({ ...modalsOpen, newApplication: false });
        handleClearForm();
        getApplications(null, null, null, null, null);
        setJob((prev) => ({ ...prev, applications_count: prev.applications_count + 1 }));
        setSnackbar({
          message: 'Application created successfully',
          state: 'success'
        });
      } else {
        throw new Error('Error creating application');
      }
    } catch (error) {
      setSnackbar({
        message: 'Something went wrong. Please try again.',
        state: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleValidateFields = () => {
    let isValid = true;
    let detailsError: keyof typeof fieldRefs | undefined = undefined;
    let questionError: number | undefined = undefined;
    let uploadError: number | undefined = undefined;
    let sourceInvalid = false;

    // Validate applicant detial fields
    Object.entries(applicantDetails).forEach((field) => {
      let tempErrorMessage = '';
      if (field[1].required && !field[1].value) {
        if (detailsError === undefined) {
          detailsError = field[0] as keyof typeof fieldRefs;
        }
        isValid = false;
        tempErrorMessage = 'This field is required';
      } else if (field[0] === 'email' && !emailIsValid(field[1].value)) {
        if (detailsError === undefined) {
          detailsError = field[0];
        }
        isValid = false;
        tempErrorMessage = 'Please input valid email';
      } else if (field[0] === 'postcode' && field[1]?.value?.length > 10) {
        if (detailsError === undefined) {
          detailsError = field[0];
        }
        isValid = false;
        tempErrorMessage = 'Postcode must be less than 10 characters';
      }
      setApplicantDetails((prev) => ({
        ...prev,
        [field[0]]: { ...prev[field[0] as keyof typeof prev], error: tempErrorMessage }
      }));
    });

    // Validate applicant question fields
    jobQuestions &&
      jobQuestions.forEach((question: IApplicantQuestion, index: number) => {
        let tempErrorMessage = '';
        if (question.type === 'CheckBoxField') {
          const isChecked = Object.values(applicantQuestions[question.id].data).some(
            (value) => value === true
          );
          if (question.required && !isChecked) {
            tempErrorMessage = 'Must select at least one option';
            if (questionError === undefined) {
              questionError = index;
            }
            isValid = false;
          }
        } else if (question.type === 'RankField') {
          const duplicates = containsDuplicates(
            Object.values(applicantQuestions[question.id].data)
          );
          if (question.required && duplicates) {
            tempErrorMessage = 'Ranked choices must be unique';
            if (questionError === undefined) {
              questionError = index;
            }
            isValid = false;
          }
        } else if (question.required && !applicantQuestions[question.id].data) {
          tempErrorMessage =
            question.type !== 'RadioButtonField'
              ? 'This field is required'
              : 'Must select an option';
          if (questionError === undefined) {
            questionError = index;
          }
          isValid = false;
        }
        setQuestionErrors((prev) => ({
          ...prev,
          [question.id]: tempErrorMessage
        }));
      });

    // Validate applicant attachment fields
    jobAttachments &&
      jobAttachments.forEach((attachment, index) => {
        if (attachment.required && !fileList[attachment.value]) {
          setUploadErrors((prev) => ({
            ...prev,
            [attachment.value]: 'Upload required'
          }));
          if (uploadError === undefined) {
            uploadError = index;
          }
          isValid = false;
        }
      });

    // Validate applicant source field
    if (jobSources && jobSources.length > 0 && !sourceValue) {
      sourceInvalid = true;
      isValid = false;
      setSourceError('Source is required');
    }

    if (jobHasPrivacyAcknowledgement && !privacyAcknowledged) {
      setPrivacyError('Please acknowledge privacy statement');
      isValid = false;
    }

    // Scroll to first invalid field
    if (detailsError !== undefined) {
      fieldRefs[detailsError]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (questionError !== undefined) {
      questionsRef?.current[questionError]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (jobHasPrivacyAcknowledgement && !privacyAcknowledged) {
      privacyRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (uploadError !== undefined) {
      uploadsRef?.current[uploadError]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else if (sourceInvalid) {
      sourceRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    return isValid;
  };

  return (
    <Modal
      open={modalsOpen.newApplication}
      onClose={() => setConfirmCancelIsOpen(true)}
      aria-labelledby="new-application-modal"
    >
      <Box sx={classes.actionsModal}>
        <CloseIcon onClick={() => setConfirmCancelIsOpen(true)} sx={classes.closeIcon} />
        <Box id="new-application-modal-title" sx={classes.modalHeader}>
          Lodge new application
        </Box>
        <Box
          sx={classes.modalContent}
          id="new-application-modal-content"
          aria-label="New application modal content"
        >
          <Box role="form" id="new-application-form" aria-label="New application form">
            <Box sx={classes.modalFormLine}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    firstName: {
                      ...prev.firstName,
                      value,
                      error:
                        value || !applicantDetails.firstName.required
                          ? ''
                          : 'This field is required'
                    }
                  }))
                }
                id="first-name"
                label="First Name"
                required={applicantDetails.firstName.required}
                error={applicantDetails.firstName.error}
                innerRef={fieldRefs.firstName}
                styles={{ width: '290px' }}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    lastName: {
                      ...prev.lastName,
                      value,
                      error:
                        value || !applicantDetails.lastName.required ? '' : 'This field is required'
                    }
                  }))
                }
                id="last-name"
                label="Last Name"
                required={applicantDetails.lastName.required}
                error={applicantDetails.lastName.error}
                innerRef={fieldRefs.lastName}
                styles={{ width: '290px' }}
              />
            </Box>
            <Box sx={classes.modalFormLine}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setApplicantDetails((prev) => ({
                    ...prev,
                    email: {
                      ...prev.email,
                      value,
                      error: value && !emailIsValid(value) ? 'Please input valid email' : ''
                    }
                  }));
                }}
                id="email"
                label="Email"
                required={applicantDetails.email.required}
                error={applicantDetails.email.error}
                innerRef={fieldRefs.email}
                styles={{ width: '290px' }}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    phone: {
                      ...prev.phone,
                      value,
                      error:
                        value || applicantDetails.phone.required ? '' : 'This field is required'
                    }
                  }))
                }
                id="phone"
                label="Phone"
                required={applicantDetails.phone.required}
                error={applicantDetails.phone.error}
                innerRef={fieldRefs.phone}
                styles={{ width: '290px' }}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    phone2: {
                      ...prev.phone2,
                      value,
                      error:
                        value || applicantDetails.phone2.required ? '' : 'This field is required'
                    }
                  }))
                }
                id="phone2"
                label="Mobile"
                required={applicantDetails.phone2.required}
                error={applicantDetails.phone2.error}
                innerRef={fieldRefs.phone2}
                styles={{ width: '290px' }}
              />
            </Box>
            <Box sx={classes.fullWidthFieldContainer}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    addressFirstLine: {
                      ...prev.addressFirstLine,
                      value,
                      error:
                        value || !applicantDetails.addressFirstLine.required
                          ? ''
                          : 'This field is required'
                    }
                  }))
                }
                id="street-address"
                label="Street Address"
                required={applicantDetails.addressFirstLine.required}
                error={applicantDetails.addressFirstLine.error}
                innerRef={fieldRefs.addressFirstLine}
                styles={classes.fullWidthField}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    addressSecondLine: {
                      ...prev.addressSecondLine,
                      value,
                      error:
                        value || !applicantDetails.addressSecondLine.required
                          ? ''
                          : 'This field is required'
                    }
                  }))
                }
                id="street-address-2"
                label="Street Address Cont'd"
                error={applicantDetails.addressSecondLine.error}
                innerRef={fieldRefs.addressSecondLine}
                styles={classes.fullWidthField}
              />
            </Box>
            <Box sx={classes.modalFormLine}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    suburb: {
                      ...prev.suburb,
                      value,
                      error:
                        value || !applicantDetails.suburb.required ? '' : 'This field is required'
                    }
                  }))
                }
                id="suburb"
                label="City, Town or Suburb"
                required={applicantDetails.suburb.required}
                error={applicantDetails.suburb.error}
                innerRef={fieldRefs.suburb}
                styles={{ width: '290px' }}
              />
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    postcode: {
                      ...prev.postcode,
                      value,
                      error:
                        value || !applicantDetails.postcode.required ? '' : 'This field is required'
                    }
                  }))
                }
                id="postcode"
                label="Postcode or zipcode"
                required={applicantDetails.postcode.required}
                error={applicantDetails.postcode.error}
                innerRef={fieldRefs.postcode}
                styles={{ width: '290px' }}
              />
            </Box>
            <Box sx={classes.modalFormLine}>
              <Box sx={{ position: 'relative', marginTop: '26px' }}>
                <Autocomplete
                  disablePortal
                  ref={fieldRefs.country}
                  id="country"
                  options={countries}
                  value={countryValue}
                  loading={countries.length < 1}
                  sx={{ ...sharedClasses.formAutocomplete, width: '287px' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      InputLabelProps={{ shrink: true }}
                      required={applicantDetails.country.required}
                    />
                  )}
                  onChange={(event, value) => {
                    setCountryValue(value);
                    setRegionValue(null);
                    setApplicantDetails((prev) => ({
                      ...prev,
                      country: {
                        ...prev.country,
                        value: value ? value.id : null,
                        error:
                          value || !applicantDetails.country.required
                            ? ''
                            : 'This field is required'
                      },
                      region: { ...prev.region, value: null }
                    }));
                  }}
                />
                {applicantDetails.country.error && (
                  <Box sx={sharedClasses.errorBox}>{applicantDetails.country.error}</Box>
                )}
              </Box>
              <Box sx={{ position: 'relative', marginTop: '26px' }}>
                <Autocomplete
                  disablePortal
                  ref={fieldRefs.region}
                  id="state-region-province"
                  options={countryValue?.states || []}
                  disabled={!countryValue}
                  value={regionValue}
                  onChange={(event, value) => {
                    setRegionValue(value ? value : null);
                    setApplicantDetails((prev) => ({
                      ...prev,
                      region: {
                        ...prev.region,
                        value: value ? value.id : null,
                        error:
                          value || !applicantDetails.region.required ? '' : 'This field is required'
                      }
                    }));
                  }}
                  sx={{
                    ...(!countryValue
                      ? sharedClasses.formAutocompleteDisabled
                      : sharedClasses.formAutocomplete),
                    width: '287px'
                  }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State, Region or Province"
                      InputLabelProps={{ shrink: true }}
                      required={applicantDetails.region.required}
                    />
                  )}
                />
                {applicantDetails.region.error && (
                  <Box sx={sharedClasses.errorBox}>{applicantDetails.region.error}</Box>
                )}
              </Box>
            </Box>
            <Box sx={classes.modalFormLine}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) =>
                  setApplicantDetails((prev) => ({
                    ...prev,
                    employeeId: {
                      ...prev.employeeId,
                      error:
                        value || !applicantDetails.employeeId.required
                          ? ''
                          : 'This field is required',
                      value
                    }
                  }))
                }
                id="employee-id"
                label="Employee ID (optional)"
                error={applicantDetails.employeeId.error}
                innerRef={fieldRefs.employeeId}
                styles={{ width: '290px' }}
              />
              <Autocomplete
                disablePortal
                id="gender"
                options={genderOptions}
                getOptionLabel={(option) => option.label}
                value={applicantDetails.gender.value}
                sx={{ ...sharedClasses.formAutocomplete, width: '287px', marginTop: '25px' }}
                ListboxProps={{
                  style: {
                    fontFamily: 'Source Sans Pro, sans-serif',
                    color: '#333333',
                    maxHeight: '200px'
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender (Optional)"
                    InputLabelProps={{ shrink: true }}
                    required={applicantDetails.gender.required}
                  />
                )}
                onChange={(event, value) => {
                  setApplicantDetails((prev) => ({
                    ...prev,
                    gender: {
                      ...prev.gender,
                      value
                    }
                  }));
                }}
              />
            </Box>
            {jobQuestions &&
              jobQuestions.map((question, index) => (
                <Box key={question.id} sx={{ ...classes.modalFormLine, position: 'relative' }}>
                  <QuestionFieldGenerator
                    question={question}
                    questionRef={(el) => (questionsRef.current[index] = el)}
                    questionError={questionErrors[question.id]}
                    onChangeCallback={(id, value) => {
                      setApplicantQuestions((prev) => ({
                        ...prev,
                        [id]: {
                          ...prev[id],
                          data: value
                        }
                      }));
                      setQuestionErrors((prev) => ({
                        ...prev,
                        [id]: value || !question.required ? '' : 'This field is required'
                      }));
                    }}
                  />
                </Box>
              ))}
            {jobHasPrivacyAcknowledgement && (
              <Box sx={{ padding: '6px 0px 12px 12px', position: 'relative' }} ref={privacyRef}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={classes.formCheckbox}
                      onChange={(e) => setPrivacyAcknowledged(e.target.checked)}
                      value={privacyAcknowledged}
                      required
                    />
                  }
                  label={
                    <>
                      Yes I agree to the privacy statement <span>*</span>
                    </>
                  }
                  sx={classes.checkboxLabel}
                />
                {privacyError && (
                  <Box sx={{ ...sharedClasses.errorBox, bottom: '-6px', left: '13px' }}>
                    {privacyError}
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {jobAttachments && (
            <Box id="applicant-uploads" sx={classes.uploadSection}>
              <h3>Candidate Uploads</h3>
              <Box sx={classes.uploadSectionContent}>
                {jobAttachments.map((upload, index) => (
                  <Box
                    key={index}
                    sx={classes.uploadContainer}
                    ref={(el) => (uploadsRef.current[index] = el)}
                  >
                    <Box sx={classes.uploadLabel}>
                      {upload.label}
                      {upload.required && <span>*</span>}
                    </Box>
                    <SingleAttachmentSelector
                      setFile={(file) => {
                        setFileList((previous) => ({ ...previous, [upload.value]: file }));
                        setUploadErrors((previous) => ({
                          ...previous,
                          [upload.value]: ''
                        }));
                      }}
                      file={fileList[upload.value]}
                      maxSize={50000000}
                      performUpload={false}
                    />
                    {uploadErrors[upload.value] && (
                      <Box sx={classes.uploadError}>{`${upload.label} upload is required`}</Box>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          {jobSources && jobSources.length > 0 && (
            <Box sx={classes.sourceContainer}>
              <h3>Where did the applicant find this job?</h3>
              <Box sx={classes.modalFormLine}>
                <FormSelectField
                  options={jobSources.map((source) => ({ label: source.label, value: source.id }))}
                  defaultValue=""
                  onChange={(value) => setSourceValue(value)}
                  label="Source"
                  styles={{ width: '290px' }}
                  innerRef={sourceRef}
                  error={sourceError}
                  required={true}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={classes.modalFooter}>
          <FormControlLabel
            control={
              <Checkbox
                sx={sharedClasses.formCheckbox}
                checked={sendNotification}
                onClick={() => setSendNotification(!sendNotification)}
                disableRipple
              />
            }
            label="Send Notification"
            sx={{ ...sharedClasses.checkboxLabel, flexGrow: 1 }}
          />
          <Button
            id="cancel-application-button"
            sx={classes.modalCancelButton}
            onClick={() => setConfirmCancelIsOpen(true)}
          >
            Cancel
          </Button>
          <Button
            id="submit-new-application-button"
            type="submit"
            sx={classes.modalConfirmationButton}
            onClick={handleSubmitApplication}
          >
            {isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Lodge new application'
            )}
          </Button>
        </Box>
        <GenericDialog
          isDialogOpen={confirmCancelIsOpen}
          setDialogOpen={setConfirmCancelIsOpen}
          title="Close without submitting?"
          description="Are you sure you want to cancel your application? You will lose any progress you have made."
          buttonCallback={() => {
            setConfirmCancelIsOpen(false);
            handleClearForm();
          }}
          callbackLoading={false}
          buttonText="Close without saving"
          url=""
        />
      </Box>
    </Modal>
  );
}
