import React, { useState, Dispatch } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField
} from '@mui/material';
import { NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import StyledModal from '../../../../Components/GenericModal/StyledModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../API';
import {
  ApplicationAction,
  ApplicationState,
  IApplication,
  ICognologyRecord,
  IIntegrationsResponse
} from '../../../types';
import { ModalType } from '../../../config';
import { sharedClasses } from '../../../../Components/CustomUIElements/sharedClasses';
import { styles } from '../../styles';
import { ICountries, IStates } from '../../../../Components/sharedTypes';
import { classes } from '../../../../Job/Applications/styles';
import CognologySummary from './CognologySummary';
import { useGetCognologyData } from '../../../helper';
import CognologyApprovalDetails from './CognologyApprovalDetails';
import CognologyStep1 from './CognologyStep1';

export const autocompleteStyle = {
  ...sharedClasses.formAutocomplete,
  width: '48%',
  paddingTop: 3,
  '.MuiInputBase-root': { margin: '3px 0px' }
};

export default function Cognology({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  const [exportPreference, setExportPreference] = useState<string>('quick');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [country, setCountry] = useState<ICountries | null>(null);
  const [state, setState] = useState<IStates | null>(null);
  const [step, setStep] = useState<number>(1);
  const [record, setRecord] = useState<ICognologyRecord | null>(null);
  const [approvalTemplate, setApprovalTemplate] = useState<{ id: number; name: string } | null>(
    null
  );
  const [reapproval, setReapproval] = useState<boolean>(false);
  const [categories, setCategories] = useState<Record<string, [string, number] | null> | null>(
    null
  );
  const [attachments, setAttachments] = useState<Record<
    number,
    { id: number; name: string } | null
  > | null>(null);

  const { cognologyData, loadingCognologyData } = useGetCognologyData({
    applicationId: application?.id,
    dispatch,
    setRecord,
    setCategories,
    setAttachments
  });

  const categoriesPayload = () => {
    if (!categories) return;
    const payload: Record<string, { id: number | null; new_value?: string; name?: string } | null> =
      {};
    Object.keys(categories).forEach((key) => {
      if (categories[key]) {
        if (categories[key][1]) {
          return (payload[key] = { id: categories[key][1], name: categories[key][0] });
        } else {
          return (payload[key] = { id: null, new_value: categories[key][0] });
        }
      } else {
        return (payload[key] = null);
      }
    });
    return payload;
  };

  const { mutate: cognologyExport, isLoading: exportingCognology } = useMutation({
    mutationFn: async () => {
      if (application && country && record && attachments) {
        const { res } = await Api.cognologyExport({
          application_id: application.id,
          candidate_title: record.candidate_title || '',
          candidate_first_name: record.candidate_first_name,
          candidate_last_name: record.candidate_last_name,
          candidate_email: record.candidate_email,
          candidate_phone1: record.candidate_phone1,
          candidate_phone2: record.candidate_phone2,
          candidate_addr_street1: record.candidate_addr_street1,
          candidate_addr_street2: record.candidate_addr_street2,
          candidate_addr_suburb: record.candidate_addr_suburb,
          candidate_addr_state: state?.abbreviation || '',
          candidate_addr_postcode: record.candidate_addr_postcode,
          candidate_addr_country: country.iso,
          requisition_form_id: approvalTemplate ? approvalTemplate.id : undefined,
          candidate_gender: record.candidate_gender || '',
          start_date: startDate?.toISOString() || '',
          candidate_employee_id: record.candidate_employee_id || '',
          manager_id: record.manager_id || '',
          recruitment_record_url: record.recruitment_record_url,
          reapproval_required_for_modifications: reapproval,
          categories: exportPreference === 'quick' ? null : categoriesPayload(),
          assets: attachments
        });
        return res;
      }
    },
    onSuccess: (res) => {
      if (!res) return;
      if (!setApprovalTemplate) {
        queryClient.invalidateQueries(['integrations'], { exact: true });
        dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
        handleClose();
      } else {
        if (!res.approval_url) return;
        window.location.href = res.approval_url;
      }
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      cognologyExport();
    }
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  const buttonName = () => {
    if (step === 1) {
      return 'Next';
    } else {
      return integrationsData?.cognology_config.approval_bypassed ? 'Export' : 'Submit';
    }
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.COGNOLOGY || modalsOpen === ModalType.EDIT_COGNOLOGY}
      label="Cognology export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to Cognology</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 2 }}>
          {modalsOpen === ModalType.EDIT_COGNOLOGY && cognologyData?.cognology_requisition && (
            <CognologyApprovalDetails
              requisition={cognologyData.cognology_requisition}
              reapprovalRequired={cognologyData.record.reapproval_required_for_modifications}
            />
          )}
          {step === 1 ? (
            <CognologyStep1
              {...{
                exportPreference,
                setExportPreference,
                record,
                setRecord,
                country,
                setCountry,
                state,
                setState,
                categories,
                setCategories,
                attachments,
                setAttachments,
                startDate,
                setStartDate,
                dispatch
              }}
            />
          ) : (
            <>
              {(integrationsData?.cognology_config.approval_bypassed ||
                modalsOpen === ModalType.EDIT_COGNOLOGY) &&
              record ? (
                <CognologySummary {...{ record, country, state, startDate }} />
              ) : (
                <>
                  <Stack sx={styles.cognologySectionTitle}>Approval Details</Stack>
                  <Autocomplete
                    disablePortal
                    autoHighlight
                    includeInputInList
                    value={approvalTemplate}
                    options={cognologyData?.requisition_forms || []}
                    getOptionLabel={(option) => option.name}
                    loading={loadingCognologyData}
                    loadingText="Loading approval templates..."
                    onChange={(_event, newValue) => setApprovalTemplate(newValue)}
                    sx={autocompleteStyle}
                    ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Approval template"
                        InputLabelProps={{ shrink: true }}
                        placeholder="Please select"
                      />
                    )}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={sharedClasses.formCheckbox}
                        checked={reapproval}
                        onClick={() => setReapproval(!reapproval)}
                        disableRipple
                      />
                    }
                    label="Reapproval required for modifications"
                    sx={sharedClasses.checkboxLabel}
                  />
                </>
              )}
            </>
          )}
        </Stack>
        <Stack
          sx={{ flexDirection: 'row', justifyContent: 'flex-end', columnGap: 1, paddingTop: 1 }}
        >
          <Button
            sx={classes.modalCancelButton}
            onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: null })}
          >
            Cancel
          </Button>
          {step === 2 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            sx={{ ...sharedClasses.genericButton, minWidth: '95px', minHeight: '41px' }}
            onClick={handleSubmit}
          >
            {exportingCognology ? <CircularProgress size={20} color="inherit" /> : buttonName()}
          </Button>
        </Stack>
      </Stack>
    </StyledModal>
  );
}
