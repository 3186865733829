import React, { Dispatch } from 'react';
import { Link, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IIntegrationsResponse, ApplicationAction, IApplication } from '../../types';
import UpdateStatusIcon from '../UpdateStatusIcon';
import Api from '../../API';
import formatToUserTimeZone from '../../../utils/formatToUserTimeZone';

export default function Fit2WorkRecord({ dispatch }: { dispatch: Dispatch<ApplicationAction> }) {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { mutate: updateFit2WorkStatus, isLoading: updatingFit2WorkStatus } = useMutation({
    mutationFn: async (id: number) => {
      if (application) {
        const { res } = await Api.updateFit2WorkStatus(application.id, id);
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      queryClient.invalidateQueries(['integrations'], { exact: true });
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: error.error, state: 'error' }
      });
    }
  });

  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Fit2Work requested checks</Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {['Submitted At', 'Package Name', 'Reference ID', 'Candidate email', 'Status'].map(
              (title, index) => (
                <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                  <Stack>{title}</Stack>
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationsData?.fit2_work_records.map((record, index) => (
            <TableRow key={index}>
              <TableCell sx={{ verticalAlign: 'top', minWidth: '100px' }}>
                {formatToUserTimeZone(record.created_at)}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top', minWidth: '110px' }}>
                {record.package_name}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top', minWidth: '100px' }}>
                {record.reference_id}
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{record.candidate_email}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>
                <Stack sx={{ rowGap: 1 }}>
                  {record.checks.map((check, index) => (
                    <Stack key={index}>
                      <Stack>{check.status}</Stack>
                      {check.report_path && (
                        <Link
                          href={check.report_path}
                          target="_blank"
                          style={{
                            color: 'inherit',
                            width: 'fit-content',
                            textDecoration: 'underline'
                          }}
                        >
                          Download Report
                        </Link>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>
                <UpdateStatusIcon
                  callback={() => updateFit2WorkStatus(record.reference_id)}
                  loading={updatingFit2WorkStatus}
                  dispatch={dispatch}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}
