import React, { useState, Dispatch, useRef } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { validateEmail } from '../../../Candidates/EditCandidate';
import { scrollToElement } from '../../../utils/scroll-to-element';

export default function AlayaCare({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [state, setState] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [gender, setGender] = useState<string | null>(null);

  const fieldRefs = {
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    mobile: useRef<HTMLInputElement>(null),
    postcode: useRef<HTMLInputElement>(null)
  };

  const { data: alayaCareData, isLoading: loadingAlayaCareData } = useQuery({
    queryKey: ['AlayaCare data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getAlayaCareData(application.id);
        return res;
      }
    },
    onSuccess: (res) => {
      if (!res) return;
      const savedState = res.states.find(
        (state) => state === application?.candidate?.address_details.state?.abbreviation
      );
      const savedTitle = res.titles.find((title) => title === application?.candidate?.title);
      const savedGender = res.genders.find(
        (gender) => gender === application?.candidate?.gender_full
      );
      savedState && setState(savedState);
      savedTitle && setTitle(savedTitle);
      savedGender && setGender(savedGender);
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting AlayaCare data, ${error}`,
          state: 'error'
        }
      })
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    if (!candidate.phone1.trim()) inputErrors.mobile = 'Please enter a mobile number';
    if (!candidate.address_details?.postcode?.trim())
      inputErrors.postcode = 'Please enter a postcode';
    setErrors(inputErrors);
    if (inputErrors.firstName) {
      scrollToElement(fieldRefs.firstName);
    } else if (inputErrors.lastName) {
      scrollToElement(fieldRefs.lastName);
    } else if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    } else if (inputErrors.mobile) {
      scrollToElement(fieldRefs.mobile);
    } else if (inputErrors.postcode) {
      scrollToElement(fieldRefs.postcode);
    }
    return !Object.keys(inputErrors).length;
  };

  const { mutate: alayaCareExport, isLoading: exportingAlayaCare } = useMutation({
    mutationFn: async () => {
      if (application && candidate && candidate.address_details.postcode) {
        const { res } = await Api.alayaCareExport({
          application_id: application.id,
          firstname: candidate.firstname,
          lastname: candidate.lastname,
          email: candidate.email,
          gender: gender || undefined,
          title: title || undefined,
          mobile_phone: candidate.phone1,
          street: candidate.address_details.street1 || undefined,
          street_cont: candidate.address_details.street2 || undefined,
          suburb_town: candidate.address_details.suburb || undefined,
          state: state || undefined,
          postcode: candidate.address_details.postcode
        });
        return res;
      }
    },
    onSuccess: (res) => {
      if (!res) return;
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({ type: 'SET_SNACKBAR', payload: { message: error.error, state: 'error' } });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.ALAYACARE}
      label="AlayaCare export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to AlayaCare</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 3 }}>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="First name"
              value={candidate.firstname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
              error={errors.firstName}
              innerRef={fieldRefs.firstName}
            />
            <FormTextField
              label="Last name"
              value={candidate.lastname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
              error={errors.lastName}
              innerRef={fieldRefs.lastName}
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Email"
              value={candidate.email}
              required
              onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
              fullWidth
              error={errors.email}
              innerRef={fieldRefs.email}
            />
            <FormTextField
              label={'Mobile phone'}
              value={candidate.phone1}
              required
              onChange={(e) => setCandidate({ ...candidate, phone1: e.target.value })}
              fullWidth
              error={errors.mobile}
              innerRef={fieldRefs.mobile}
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <Autocomplete
              disablePortal
              options={alayaCareData?.titles || []}
              value={title}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
              loading={loadingAlayaCareData}
              loadingText="Loading titles..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Title"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                />
              )}
              onChange={(event, value) => setTitle(value)}
            />
            <Autocomplete
              disablePortal
              options={alayaCareData?.genders || []}
              value={gender}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
              loading={loadingAlayaCareData}
              loadingText="Loading gender options..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Gender"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                />
              )}
              onChange={(event, value) => setGender(value)}
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Street address"
              value={candidate?.address_details?.street1 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street1: e.target.value }
                })
              }
            />
            <FormTextField
              label="Street address cont'd"
              value={candidate?.address_details?.street2 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street2: e.target.value }
                })
              }
            />
          </Stack>
          <Stack sx={{ ...styles.modalDoubleColumn, marginRight: '2px' }}>
            <FormTextField
              label="City, town or suburb"
              value={candidate?.address_details?.suburb || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, suburb: e.target.value }
                })
              }
            />
            <Autocomplete
              disablePortal
              options={alayaCareData?.states || []}
              value={state}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: '20px' }}
              loading={loadingAlayaCareData}
              loadingText="Loading states..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State, region or province"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    ...styles.autoCompleteTextfield,
                    '& .MuiInputBase-root': { margin: 'unset', marginTop: 0.5 }
                  }}
                />
              )}
              onChange={(event, value) => setState(value)}
            />
          </Stack>
          <FormTextField
            label="Postcode"
            value={candidate?.address_details?.postcode || ''}
            styles={{ width: '48%' }}
            onChange={(e) =>
              setCandidate({
                ...candidate,
                address_details: { ...candidate.address_details, postcode: e.target.value }
              })
            }
            required
            error={errors.postcode}
            innerRef={fieldRefs.postcode}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && alayaCareExport()}
          isLoading={exportingAlayaCare}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
