import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from './API';
import { IAttendee, ScoutCalendarAction, ScoutCalendarState } from './types';
import { IEvent } from './types';
import GroupedAvatars from './CreateEvent/EventProgress/GroupedAvatars';

export function useDeleteEvent({
  events,
  setSnackbar,
  ScoutCalendarState,
  dispatch,
  onClose
}: {
  events: IEvent[];
  setSnackbar?: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const { createEventObject } = ScoutCalendarState;
  const { candidates, eventId } = createEventObject;

  const { mutate: deleteEvent, isLoading: deletingEvent } = useMutation({
    mutationFn: async () => await API.deleteEvent(eventId),
    onError: (error: { errors: string }) => {
      const snackbarPayload: { message: string; state: 'error' } = {
        message: `There was an error deleting the event, ${error.errors}`,
        state: 'error'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
    },
    onSuccess: () => {
      const snackbarPayload: { message: string; state: 'success' } = {
        message: `Event has been successfully deleted.${
          candidates.length > 0
            ? ` ${candidates[0].name}${
                candidates.length > 1 ? ` and ${candidates.length - 1} others` : ''
              } has been successfully removed and notified`
            : ''
        }`,
        state: 'success'
      };
      setSnackbar
        ? setSnackbar(snackbarPayload)
        : dispatch({ type: 'SET_SNACKBAR', payload: snackbarPayload });
      const updatedList = events.filter((e) => e.id !== eventId);
      setSnackbar
        ? queryClient.setQueryData(['getEvents'], { pages: [{ data: { events: updatedList } }] })
        : queryClient.setQueryData(['events', ScoutCalendarState.weekStart], updatedList);
      //refreshes the events list on the application page events table on the tasks and events tab
      queryClient.invalidateQueries(['application events'], { exact: true });
      onClose();
    }
  });

  return { deleteEvent, deletingEvent };
}

export const getAttendees = (attendees: IAttendee[]) =>
  attendees.map((r) => ({ id: r.id, name: r.name, state: r.state }));

export function CandidateGroupedAvatars({ candidates }: { candidates: IAttendee[] }) {
  return (
    <GroupedAvatars users={getAttendees(candidates).map((c) => ({ ...c, state: undefined }))} />
  );
}
