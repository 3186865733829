import React, { Dispatch } from 'react';
import { Button, Dialog, Stack, Tooltip } from '@mui/material';
import { CheckCircle as CheckCircleIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IApplication } from '../../types';
import { styles } from '../styles';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export type AlreadyExportedModalProps = {
  revolve: boolean;
  referoo: boolean;
  hro: boolean;
  sparkhire: boolean;
  cognology: boolean;
  foundu: boolean;
};

export default function AlreadyExportedModal({
  alreadyExportedModalOpen,
  onClose,
  dispatch,
  buttonName,
  callback
}: {
  alreadyExportedModalOpen: AlreadyExportedModalProps;
  onClose: () => void;
  dispatch: Dispatch<ApplicationAction>;
  buttonName?: string;
  callback?: () => void;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const integrationTitle = () => {
    if (alreadyExportedModalOpen.referoo) {
      return 'Referoo';
    } else if (alreadyExportedModalOpen.sparkhire) {
      return 'SparkHire';
    } else if (alreadyExportedModalOpen.revolve) {
      return 'Revolve Onboard';
    } else if (alreadyExportedModalOpen.hro) {
      return 'HROnboard';
    } else if (alreadyExportedModalOpen.foundu) {
      return 'foundU';
    } else {
      return 'Cognology';
    }
  };

  const integrationName = () => {
    if (alreadyExportedModalOpen.referoo) {
      return 'Referoo';
    } else if (alreadyExportedModalOpen.sparkhire) {
      return 'SparkHire';
    } else if (alreadyExportedModalOpen.cognology) {
      return 'Cognology';
    } else if (alreadyExportedModalOpen.foundu) {
      return 'foundU';
    }
  };

  if (!application) return null;
  return (
    <Dialog
      open={
        alreadyExportedModalOpen.revolve ||
        alreadyExportedModalOpen.referoo ||
        alreadyExportedModalOpen.hro ||
        alreadyExportedModalOpen.sparkhire ||
        alreadyExportedModalOpen.foundu ||
        alreadyExportedModalOpen.cognology
      }
      onClose={onClose}
    >
      <Stack sx={styles.alreadyExportedModal}>
        <CheckCircleIcon sx={{ color: '#5BC4BF', fontSize: '4rem' }} />
        <Stack sx={styles.modalTitle}>{`Export to ${integrationTitle()}`}</Stack>
        <Stack sx={{ alignItems: 'center', color: '#333333', paddingBottom: 2 }}>
          {alreadyExportedModalOpen.referoo ||
          alreadyExportedModalOpen.foundu ||
          alreadyExportedModalOpen.sparkhire ||
          alreadyExportedModalOpen.cognology ? (
            <>
              <Stack>This application has already been</Stack>
              <Stack>{`exported to ${integrationName()}`}</Stack>
            </>
          ) : (
            <Stack>{`This application has already been exported to ${alreadyExportedModalOpen.hro ? 'HROnboard' : 'Revolve.'}`}</Stack>
          )}
          {alreadyExportedModalOpen.revolve && (
            <Stack sx={{ flexDirection: 'row', columnGap: 1, alignItems: 'center' }}>
              <Stack>Revolve record id is</Stack>
              <Stack fontWeight="bold">{application.revolve_record_id}</Stack>
              <CopyToClipboard
                id="copy-revolve-id-button"
                text={application.revolve_record_id}
                onCopy={() =>
                  dispatch({
                    type: 'SET_SNACKBAR',
                    payload: {
                      message: 'Revolve record id has been copied to clipboard',
                      state: 'success'
                    }
                  })
                }
              >
                <Tooltip title={'Copy ID'} placement={'bottom'}>
                  <FileCopyIcon fontSize="small" sx={{ color: '#666666', cursor: 'pointer' }} />
                </Tooltip>
              </CopyToClipboard>
            </Stack>
          )}
        </Stack>
        <Button
          sx={sharedClasses.genericButton}
          onClick={() => {
            callback && callback();
            onClose();
          }}
        >
          {buttonName || 'Exit'}
        </Button>
      </Stack>
    </Dialog>
  );
}
