"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { alpha } from "@mui/material/styles";
import { theme } from "../../../ThemeContext/ThemeObject";
import { actionModalFormLine, checkboxLabel, closeIcon } from "../../shared/styleClasses";
import { modalConfirmationButtonCommonStyles } from "../../shared/commonStyles";
export const sharedClasses = {
  formAutocomplete: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    },
    "& .MuiInputBase-root": {
      "&.Mui-disabled": { backgroundColor: "rgba(217, 217, 217, 0.23)" }
    }
  },
  formAutocompleteDisabled: {
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#EEEEEE",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      margin: "3px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#CCCCCC !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      left: "-13px",
      top: "-15px",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.light
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#EEEEEE"
    }
  },
  inputLabel: {
    color: "#838383 !important",
    fontSize: "21px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px",
    "& span": {
      color: theme.palette.error.main,
      marginLeft: "2px"
    }
  },
  errorBox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "12px",
    marginLeft: 0.5
  },
  fixedErrorBox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px",
    marginLeft: "4px"
  },
  errorBoxCheckbox: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "13px"
  },
  groupLabel: {
    color: "#838383 !important",
    fontSize: "16px",
    fontFamily: "Source Sans Pro, sans-serif",
    paddingLeft: "4px",
    marginBottom: "12px"
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    color: "#666666",
    width: "119px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#DDDDDD !important"
    },
    "& input": {
      padding: "0px 4px 4px 10px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  calendar: {
    zIndex: 19e3,
    paddingTop: 1,
    "& .PrivatePickersFadeTransitionGroup-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    "& .Mui-selected": {
      backgroundColor: `${theme.palette.action.main} !important`
    },
    "& .PrivatePickersSlideTransition-root": {
      minHeight: "242px !important"
    },
    "& .MuiMultiSectionDigitalClock-root": {
      ul: {
        li: {
          fontFamily: "Source Sans Pro"
        },
        "&::-webkit-scrollbar": {
          display: "none"
        },
        "&::after": {
          display: "none"
        }
      }
    },
    "& .MuiDialogActions-root": {
      display: "none !important"
    }
  },
  calendarHeader: {
    "& .MuiPickersFadeTransitionGroup-root": {
      display: "flex",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      fontFamily: "Source Sans Pro",
      fontSize: "18px"
    }
  },
  rankFieldOption: {
    fontFamily: "Source Sans Pro",
    color: "#000000",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    marginLeft: "12px",
    marginBottom: "6px",
    marginTop: "6px"
  },
  rankFieldSelect: {
    "& .MuiInputBase-input": {
      fontSize: "14px"
    },
    "& .MuiSvgIcon-root": {
      marginRight: 0
    },
    width: "90%",
    marginRight: "20px"
  },
  snackbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "670px",
    height: "84px",
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    fontSize: "17px",
    fontWeight: "bold",
    paddingInline: "34px"
  },
  snackbarMessageIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    columnGap: "22px"
  },
  snackbarMessage: {
    textAlign: "center",
    maxWidth: "474px"
  },
  snackbarSuccess: {
    backgroundColor: "#EFFDF3",
    color: "#8CCF95"
  },
  snackbarWarning: {
    backgroundColor: "#FCF4EA",
    color: "#E6A052"
  },
  snackbarError: {
    backgroundColor: "#FCEFEF",
    color: theme.palette.error.main
  },
  snackbarButton: {
    fontFamily: "Source Sans Pro",
    color: "#666666",
    fontSize: "12px",
    textDecoration: "underline",
    padding: "2px"
  },
  textAndTagsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "46px",
    columnGap: "8px",
    rowGap: "8px",
    borderRadius: "6px",
    position: "relative",
    backgroundColor: "#FFFFFF",
    color: "#333333",
    border: "1px solid #DDDDDD",
    fontSize: "16px",
    padding: "8px",
    margin: "3px",
    fontFamily: "Source Sans Pro, sans-serif",
    transition: "all 0.16s ease-in-out",
    cursor: "text"
  },
  focusedStyles: {
    borderColor: theme.palette.primary.main,
    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`
  },
  textInput: {
    backgroundColor: "transparent",
    color: "inherit",
    border: "0",
    outline: "0",
    marginLeft: "0"
  },
  tag: {
    color: "#000000",
    backgroundColor: "#f1f1f1",
    borderRadius: "6px",
    padding: "2px 8px",
    paddingLeft: "13px",
    fontSize: "14px"
  },
  tagIconButton: {
    padding: "2px",
    marginLeft: "5px",
    marginBottom: "2px"
  },
  tagIcon: {
    fontSize: "12px",
    color: "#000000"
  },
  tagSelected: {
    boxShadow: `${alpha(theme.palette.action.main, 0.5)} 0 0 0 2px`
  },
  multiTagLabel: {
    fontSize: "15.5px",
    color: "#838383",
    fontFamily: "Source Sans Pro, sans-serif",
    marginBottom: "6px",
    marginLeft: "3px",
    span: { color: theme.palette.error.main, marginLeft: "2px" }
  },
  multiTagHelperText: {
    marginLeft: "4px",
    marginBottom: "4px",
    fontStyle: "italic"
  },
  toggleButton: {
    padding: "4px 17px !important",
    fontFamily: "Source Sans Pro",
    color: "#AAAAAA",
    fontSize: "15px",
    fontWeight: "bold",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    "&.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.action.main} !important`
    },
    "&:focus": {
      outline: "none"
    }
  },
  timePicker: {
    marginTop: "28px",
    "& .time-picker": {
      width: "117px",
      height: "40px",
      border: "1px solid #DDDDDD",
      color: "#666666",
      borderRadius: "6px",
      padding: "8px",
      fontSize: "16px",
      fontFamily: "Source Sans Pro"
    },
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      filter: "invert(74%) sepia(0%) saturate(0%) hue-rotate(239deg) brightness(92%) contrast(89%)",
      transform: "scale(1.37)",
      cursor: "pointer",
      "&:hover": {
        filter: "invert(38%) sepia(34%) saturate(5%) hue-rotate(314deg) brightness(95%) contrast(90%)"
      }
    },
    '& input[type="time"]::-webkit-datetime-edit-text': {
      color: "#666666"
    },
    '& input[type="time"]::-webkit-datetime-edit-hour-field': {
      color: "#666666"
    },
    '& input[type="time"]::-webkit-datetime-edit-minute-field': {
      color: "#666666"
    },
    '& input[type="time"]::-webkit-datetime-edit-ampm-field': {
      color: "#666666"
    }
  },
  dateSelect: {
    color: "#084d6d",
    border: "1px solid #E5E7EB",
    padding: "6px 16px",
    marginTop: "auto",
    fontSize: "14px",
    fontWeight: "600",
    display: "inline-flex",
    height: "fit-content",
    borderRadius: "6px",
    letterSpacing: "0.1px",
    "& em": {
      color: "#babdc3"
    },
    "&.active": {
      borderColor: theme.palette.secondary.main
    },
    "&:hover": {
      borderColor: "#d9dce3",
      cursor: "pointer",
      userSelect: "none"
    }
  },
  autoCompleteListStyles: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#333333",
    maxHeight: "200px"
  },
  autoCompleteTextfield: {
    "& .MuiInputBase-root": { margin: "unset", marginTop: 1 },
    "& .MuiFormHelperText-root": {
      margin: 0.5,
      fontSize: "12px",
      fontFamily: "inherit",
      color: theme.palette.error.main
    }
  },
  autocomplete: {
    width: "205px",
    margin: "24px 25px 0 0",
    "& .MuiAutocomplete-root": {
      "& .MuiOutlinedInput-root": {
        padding: "0px"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      position: "relative",
      backgroundColor: "#FFFFFF",
      color: "#333333",
      border: "1px solid #DDDDDD",
      fontSize: "16px",
      padding: "0px",
      fontFamily: "Source Sans Pro, sans-serif",
      transition: "all 0.16s ease-in-out",
      "& .MuiAutocomplete-input": {
        padding: "6px 4px 6px 11px"
      }
    },
    "& fieldset": {
      display: "none"
    },
    "& label": {
      color: "#838383 !important",
      fontSize: "21px",
      fontFamily: "Source Sans Pro, sans-serif",
      paddingLeft: "4px",
      lineHeight: "inherit",
      "& .MuiFormLabel-asterisk": {
        color: theme.palette.error.main
      }
    },
    "& .MuiAutocomplete-popupIndicator": {
      transition: "all 0.25s ease-in-out",
      color: "#AAAAAA"
    }
  },
  iconButton: {
    border: "1px solid #DDDDDD",
    backgroundColor: "#FFFFFF",
    borderRadius: "6px",
    width: "40px",
    height: "37px",
    "& svg": {
      fontSize: "20px",
      color: "#084D6D",
      transform: "translateY(1px)"
    }
  },
  tableHeaderRow: {
    "& td, th": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      backgroundColor: "white",
      "& span": {
        color: theme.palette.primary.main,
        "&:hover": {
          color: "#0c74a4"
        }
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#F2F2F2"
    },
    "& .Mui-active": {
      color: theme.palette.primary.main
    },
    "& .MuiTableSortLabel-icon": {
      color: "darkgrey !important"
    }
  },
  noContentWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#DDDDDD",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "32px 0px"
  },
  genericButton: __spreadProps(__spreadValues({}, modalConfirmationButtonCommonStyles), {
    padding: "8px 21px !important",
    "&:focus": {
      outline: "none"
    }
  }),
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    columnGap: "20px",
    marginTop: "auto"
  },
  sectionHeader: {
    fontFamily: "Source Sans Pro",
    fontSize: "28px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    width: "100%"
  },
  sectionContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "46px 0px",
    padding: "0 60px"
  },
  modalBody: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1000px",
    width: "100%",
    minHeight: "550px",
    maxHeight: "1200px",
    borderRadius: "6px",
    dropShadow: "0px 2px 14px #305A6F29",
    position: "absolute",
    background: "#FFFFFF",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  closeIcon,
  sectionSubHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "600",
    fontSize: "18px"
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "26px",
    "& h1": {
      fontFamily: "Source Sans Pro",
      fontWeight: "bold",
      fontSize: "38px",
      color: "#666666",
      marginTop: "0"
    }
  },
  genericButtonSecondary: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    backgroundColor: "#EBF8F7",
    color: theme.palette.action.main,
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "& svg": {
      color: "#5BC4BF"
    },
    "&:hover": {
      backgroundColor: "#5BC4BF",
      color: "#FFFFFF",
      "& svg": {
        color: "#FFFFFF",
        path: { fill: "white" }
      }
    },
    "&:focus": {
      outline: "none"
    }
  },
  infoIcon: {
    color: theme.palette.action.main,
    marginTop: "4px",
    fontSize: "16px"
  },
  materialTimePicker: {
    // TODO migrating existing html tags of type 'time-picker' to material component
    "& .MuiOutlinedInput-notchedOutline": {
      legend: { display: "none" },
      borderColor: "#DDDDDD",
      borderRadius: "6px"
    },
    "& .MuiOutlinedInput-root": {
      width: "100%",
      height: "35px",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "Source Sans Pro",
      paddingBottom: "6px",
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#DDDDDD"
      }
    },
    "& .MuiInputAdornment-root": {
      "& button": {
        "&:hover": {
          backgroundColor: "transparent"
        },
        "&:focus": {
          outline: "none"
        }
      }
    }
  },
  ratingSlider: {
    fontFamily: "Source Sans Pro",
    color: theme.palette.action.main,
    height: "2px",
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0"
    },
    "& .MuiSlider-track": {
      backgroundColor: theme.palette.action.main
    },
    "& .MuiSlider-thumb": {
      backgroundColor: theme.palette.action.main,
      width: "8px",
      height: "8px",
      "&:hover": {
        boxShadow: "0px 0px 0px 5px" + theme.palette.action.main + "16"
      }
    },
    "& .MuiSlider-thumb.Mui-active": {
      boxShadow: "0px 0px 0px 7px" + theme.palette.action.main + "16"
    },
    "& .MuiSlider-valueLabel": {
      fontSize: "11px",
      backgroundColor: "transparent",
      color: theme.palette.primary.main,
      top: "36px",
      "&::after": {
        display: "none"
      }
    }
  },
  datePickerCalendar: {
    "& .DayPicker-NavBar": {
      left: "unset",
      right: "unset",
      padding: "0px",
      width: "233px"
    },
    "& .DayPicker-Weekday": {
      padding: "0px",
      width: "32px"
    },
    "& .DayPicker-Weekday abbr": {
      color: "#939393",
      textDecoration: "none",
      textTransform: "uppercase",
      fontSize: "10px"
    },
    "& .DayPicker-Caption": {
      color: "#004665",
      fontSize: "12px",
      fontWeight: "bold"
    },
    "& .DayPicker-Month": {
      margin: "0px"
    },
    "& .DayPicker-Day": {
      border: "none",
      fontSize: "10px",
      padding: "0px",
      height: "32px",
      borderRadius: "0"
    },
    "& .DayPicker-Day--outside": {
      backgroundColor: "#FFFFFF !important",
      "&::after": {
        display: "none"
      }
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      background: "rgba(91, 196, 191, 0.27)",
      color: "#000000"
    },
    "& .DayPicker-Day--start:not(.DayPicker-Day--outside)": {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      background: "rgba(91, 196, 191, 0.27) !important",
      position: "relative",
      "&::after": {
        content: '""',
        background: "rgba(91, 196, 191)",
        width: "32px",
        height: "32px",
        borderRadius: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1"
      }
    },
    "& .DayPicker-Day--end:not(.DayPicker-Day--outside)": {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      background: "rgba(91, 196, 191, 0.27) !important",
      position: "relative",
      "&::after": {
        content: '""',
        background: "rgba(91, 196, 191)",
        width: "32px",
        height: "32px",
        borderRadius: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-1"
      }
    },
    "& .DayPicker-Day--today": {
      fontWeight: "bold",
      color: "black"
    },
    "& .DayPicker-wrapper": {
      zIndex: "0",
      padding: "9px 0px",
      "& .DayPicker-NavButton": {
        width: "10px",
        height: "10px"
      },
      "& .DayPicker-Day--disabled": {
        backgroundColor: "white",
        pointerEvents: "none"
      }
    }
  },
  onlyStartDate: {
    "& .DayPicker-Day--start": {
      borderRadius: "100% !important"
    }
  },
  mainModalContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "40px",
    marginBottom: "26px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  actionModalFormLine,
  formCheckbox: {
    color: "#ddd !important",
    "& .MuiSvgIcon-root": {
      fontSize: "20px"
    },
    "&.Mui-checked": {
      color: "#15C7C0 !important"
    }
  },
  checkboxLabel,
  modalSection: {
    marginLeft: "15px",
    marginTop: "15px"
  },
  formLabel: {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    color: "#838383",
    marginBottom: "5px"
  },
  placeholderText: {
    fontFamily: "Source Sans Pro",
    color: "#838383",
    fontSize: "11px",
    margin: "6px 0px 20px 1px"
  },
  emailAttachmentIcon: {
    width: "50px",
    height: "50px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
    backgroundColor: "#084D6D10",
    "& svg": {
      color: theme.palette.primary.main
    }
  },
  emailAttachmentName: {
    color: theme.palette.primary.main,
    fontSize: "15px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "196px"
  },
  fileSize: {
    color: "#999999"
  },
  emailAttachmentContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    width: "fit-content"
  },
  emailAttachmentsList: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px"
  },
  noticeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "9px 16px",
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: "8px",
    color: "#939393",
    fontSize: "16px",
    margin: "16px 0",
    rowGap: "1px"
  },
  noticeIcon: {
    color: theme.palette.action.main,
    margin: "0 9px 3px 0",
    fontSize: "16px"
  },
  createButtonAction: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& svg": {
      color: "#666666",
      fontSize: "40px",
      padding: "4px",
      borderRadius: "2rem"
    }
  },
  createButtonDiv: {
    color: "#666666",
    fontSize: "16px",
    fontWeight: "700",
    padding: "6px 29px 6px 23px",
    backgroundColor: "#F2F2F2",
    borderBottomRightRadius: "10px 10px",
    borderTopRightRadius: "10px 10px"
  },
  addIcon: {
    position: "absolute",
    left: "-20px",
    backgroundColor: "#E5E5E5"
  },
  dropdownIcon: {
    transition: "all .2s ease",
    position: "absolute",
    right: "-3px"
  },
  genericRedButton: {
    borderRadius: "6px",
    fontFamily: "Source Sans Pro",
    padding: "6px 16px",
    textTransform: "none",
    fontSize: "14px",
    boxShadow: "none",
    fontWeight: "bold",
    background: "#FCEFEF",
    color: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.main,
      color: "white",
      boxShadow: "none"
    },
    "&.Mui-disabled": {
      color: "#FFFFFF",
      background: "#DDDDDD"
    },
    "&:focus": {
      outline: "none"
    }
  },
  pickersDay: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    "&:focus": { outline: "none", background: "inherit" }
  },
  multipleDatesPickerLabel: {
    paddingBottom: "13px",
    fontFamily: "inherit",
    color: "#838383",
    lineHeight: "18px",
    paddingLeft: "3px",
    display: "flex",
    columnGap: "2px"
  },
  multipleDatesPickerInput: {
    "& legend": { display: "none" },
    height: "35px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px",
      borderColor: "#DDDDDD"
    },
    paddingBottom: "7px",
    fontFamily: "Source Sans Pro"
  },
  multipleDatesPickerExtraDates: {
    paddingRight: 1,
    fontSize: "18px",
    color: "#5BC4C0",
    fontWeight: "bold"
  },
  multipleDatesPickerStaticDatePicker: {
    padding: 1,
    "& .MuiPickersLayout-contentWrapper": { gridColumn: 1, gridRow: 1 },
    "& .MuiPickersDay-root": {
      margin: "unset"
    },
    "& .MuiDayCalendar-weekDayLabel": {
      margin: "unset"
    },
    "& .MuiDateCalendar-root": {
      width: "300px"
    }
  },
  navigateBackButton: {
    marginBottom: "20px",
    fontSize: "16px",
    color: "#eee",
    opacity: "0.8",
    display: "flex",
    alignItems: "center",
    "& div": {
      fontWeight: "600",
      color: "#666666",
      ":hover": {
        color: "#545454",
        cursor: "pointer"
      }
    }
  },
  errorText: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "12px",
    width: "100%",
    margin: "unset !important"
  },
  toggleButtonsContainer: {
    marginTop: "60px",
    marginBottom: "40px",
    padding: "0px 16px"
  },
  applicationBetaButton: {
    padding: "4px 12px",
    borderRadius: "30px",
    backgroundColor: "rgba(247, 161, 255, 0.2)",
    color: "#F7A1FF",
    fontWeight: "bold",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontSize: "12px"
  },
  errorMessage: {
    fontFamily: "inherit",
    color: theme.palette.error.main,
    margin: "4px"
  },
  saveButton: {
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform: "none",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    width: "91px",
    height: "41px",
    margin: "8px 0",
    borderRadius: "6px",
    boxShadow: "none",
    padding: "8px 14px",
    "&:hover": {
      backgroundColor: "#4da8a4",
      boxShadow: "none"
    }
  },
  emptyStateText: {
    fontFamily: "Source Sans Pro, sans-serif",
    color: "#949494",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "8px"
  }
};
