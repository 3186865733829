"use strict";
const integrations = (enabled) => ({
  revolve: {
    permissionKey: "Export To Revolve Onboard"
  },
  scoutOnboard: {
    permissionKey: "Export To SCOUT Onboard"
  },
  xref: {
    permissionKey: "Xref (Export)",
    applicationKey: "xref"
  },
  mvs: {
    newIntegrationEnabled: enabled,
    permissionKey: "My Verification Service (MVS)",
    applicationKey: "mvs"
  },
  referoo: {
    permissionKey: "Referoo",
    applicationKey: "referoo"
  },
  foundU: {
    newIntegrationEnabled: enabled,
    permissionKey: "foundU",
    applicationKey: "foundu"
  },
  testGrid: {
    permissionKey: "TestGrid",
    applicationKey: "test_grid"
  },
  hro: {
    newIntegrationEnabled: enabled,
    permissionKey: "Export To HR Onboard"
  },
  cognology: {
    newIntegrationEnabled: enabled,
    permissionKey: "Export To Cognology",
    applicationKey: "cognology"
  },
  roubler: {
    newIntegrationEnabled: enabled,
    permissionKey: "Roubler Integration",
    applicationKey: "roubler"
  },
  sparkhire: {
    newIntegrationEnabled: enabled,
    permissionKey: "Export To Sparkhire",
    applicationKey: "sparkhire"
  },
  fit2work: {
    newIntegrationEnabled: enabled,
    permissionKey: "Fit2Work",
    applicationKey: "fit2_work"
  },
  alayacare: {
    newIntegrationEnabled: enabled,
    permissionKey: "AlayaCare Integration",
    applicationKey: "alayacare"
  }
});
const isIntegrationAvailable = (integrationName, newIntegrationEnabled, permissions, application) => {
  var _a, _b, _c, _d;
  const integrationConfig = integrations(newIntegrationEnabled)[integrationName];
  if (!integrationConfig) {
    console.warn(`Integration not found: ${integrationName}`);
    return false;
  }
  const hasPermission = integrationConfig.permissionKey ? (_b = (_a = permissions == null ? void 0 : permissions.Integrations) == null ? void 0 : _a[integrationConfig.permissionKey]) != null ? _b : false : true;
  const isEnabled = (_c = integrationConfig.newIntegrationEnabled) != null ? _c : true;
  const hasApplicationKey = integrationConfig.applicationKey ? !!((_d = application == null ? void 0 : application.integrations) == null ? void 0 : _d[integrationConfig.applicationKey]) : true;
  return hasPermission && isEnabled && hasApplicationKey;
};
export { integrations, isIntegrationAvailable };
