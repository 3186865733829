import React, { useRef, useState, useEffect } from 'react';
import { Autocomplete, Box, Button, IconButton, Stack, Skeleton, TextField } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IApprovalTemplate, IApprovalManagers, IApprovers } from '../types';
import { classes } from '../NewApproval/styles';
import Api from '../API';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import StyledSnackbar from '../../Components/CustomUIElements/StyledSnackbar';
import { DebouncedFormTextField } from '../../Components/CustomUIElements/FormTextField';
import QuestionFieldGenerator, {
  IApplicantQuestion,
  IFieldChoice
} from '../../Components/Utilities/QuestionFieldGenerator';
import { FileWithPath } from 'react-dropzone';
import DocTypeIcon from '../../Components/Utilities/DocTypeIcon';
import { serialize } from 'object-to-formdata';
import moment from 'moment';
import LoadingButton from '../../Components/Buttons/LoadingButton';
import { scrollToElement } from '../../utils/scroll-to-element';
import AttachmentSelector from '../../Components/Utilities/AttachmentSelector';

const BASE_URL = window.location.origin;

const createApprovalFormQuestionsObject = (questions: IApplicantQuestion[]) => {
  const newObject = {};
  questions.forEach((question: IApplicantQuestion) => {
    if (question.type === 'RankField') {
      newObject[question.id] = {
        ...question,
        data: {}
      };
      question.field_choices.forEach((choice: IFieldChoice, index: number) => {
        newObject[question.id]['data'][choice.id] = index + 1;
      });
    } else if (question.type === 'JobReferenceLookupField') {
      newObject[question.id] = {
        ...question,
        data: 'validation bypass'
      };
    } else {
      newObject[question.id] = {
        ...question,
        data: ''
      };
    }
  });
  return newObject;
};

const createQuestionErrorsObject = (questions: IApplicantQuestion[]) => {
  const errors: Record<number, string> = {};
  questions.forEach((question: IApplicantQuestion) => {
    errors[question.id] = '';
  });
  return errors;
};

const getAnswerAttributes = (id: number, value: any) => {
  if (value.type === 'RadioButtonField' || value.type === 'SelectField') {
    return {
      field_id: id,
      field_choice_id: value.data
    };
  } else if (value.type === 'CheckBoxField') {
    return {
      field_id: id,
      field_choice_ids: Object.entries(value.data)
        .filter(([_, value]) => value)
        .map(([id]) => id)
    };
  } else {
    return {
      field_id: id,
      data: value.data
    };
  }
};

export default function ApprovalPublicLink({
  apiKey,
  templateId,
  token,
  approvalTemplateData,
  approvalManagers
}: {
  apiKey: string;
  templateId: number;
  token: string;
  approvalTemplateData?: IApprovalTemplate;
  approvalManagers?: IApprovalManagers[];
}) {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingApprovers, setLoadingApprovers] = useState<boolean>(false);
  const [approvers, setApprovers] = useState<
    { email: string; id?: number; destroyed?: boolean; error?: string; mandatoryBlank?: boolean }[]
  >([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [approvalAdministrator, setApprovalAdministrator] = useState<string>('');
  const [administratorList, setAdministratorList] = useState<IApprovers[]>([]);
  const [fileList, setFileList] = useState<FileWithPath[]>([]);
  const [questions, setQuestions] = useState<IApplicantQuestion[]>([]);
  const [approvalFormQuestions, setApprovalFormQuestions] = useState(() =>
    createApprovalFormQuestionsObject([])
  );
  const [questionErrors, setQuestionErrors] = useState(() =>
    createQuestionErrorsObject(questions || [])
  );

  const fieldRefs = {
    positionTitle: useRef<HTMLDivElement>(null),
    approvalManagers: useRef<HTMLDivElement>(null),
    requesterAttributes: useRef<HTMLDivElement>(null),
    questions: useRef<Array<HTMLDivElement | null>>([])
  };

  const [fetchingCSV, setFetchingCSV] = useState<boolean>(false);
  const [positionTitle, setPositionTitle] = useState<string>('');
  const [positionTitleError, setPositionTitleError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [requesterAttributesErrors, setRequesterAttributesErrors] = useState<{
    name: string;
    email: string;
    phone: string;
  }>({ name: '', email: '', phone: '' });
  const [requisitionId, setRequisitionId] = useState<number | null>(null);

  const containsDuplicates = (array: string[]) => {
    return new Set(array).size !== array.length;
  };

  // Validate approval question fields
  const validateQuestions = () => {
    let isValid = true;
    let questionError: number | undefined = undefined;

    questions.forEach((question: IApplicantQuestion, index: number) => {
      let tempErrorMessage = '';
      if (question.type === 'CheckBoxField') {
        const isChecked = Object.values(approvalFormQuestions?.[question.id]?.data).some(
          (value) => value === true
        );
        if (question.required && !isChecked) {
          tempErrorMessage = 'Must select at least one option';
          if (questionError === undefined) {
            questionError = index;
          }
          isValid = false;
        }
      } else if (question.type === 'RankField') {
        const duplicates = containsDuplicates(
          Object.values(approvalFormQuestions?.[question.id]?.data)
        );
        if (duplicates) {
          tempErrorMessage = 'Ranked choices must be unique';
          if (questionError === undefined) {
            questionError = index;
          }
          isValid = false;
        }
      } else if (question.required && !approvalFormQuestions?.[question.id]?.data) {
        tempErrorMessage =
          question.type !== 'RadioButtonField' ? 'This field is required' : 'Must select an option';
        if (questionError === undefined) {
          questionError = index;
        }
        isValid = false;
      }
      setQuestionErrors((prev) => ({
        ...prev,
        [question.id]: tempErrorMessage
      }));
    });

    if (questionError !== undefined) {
      scrollToElement(fieldRefs.questions.current[questionError]);
    }
    return isValid;
  };

  const validatePositionTitle = () => {
    if (approvalTemplateData?.config.hide_position_title) return true;
    setPositionTitleError('');
    if (positionTitle) {
      return true;
    } else {
      setPositionTitleError('Position title is required to create an approval form.');
      scrollToElement(fieldRefs.positionTitle);
      return false;
    }
  };

  const validateApprovalManagers = () => {
    const newApprovers = [...approvers];
    let isValid = true;
    newApprovers.map((approver, i) => {
      approver.error = '';
      if (!approver.email) {
        newApprovers[i].error = 'Please choose an approver or delete the row.';
        isValid = false;
      } else if (!validateEmail(approver.email)) {
        newApprovers[i].error = 'Please enter a valid email address.';
        isValid = false;
      }
    });
    if (!isValid) {
      scrollToElement(fieldRefs.approvalManagers);
    }
    setApprovers(newApprovers);
    return isValid;
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateRequesterAttributes = () => {
    const errors = { name: '', email: '', phone: '' };
    let isValid = true;
    if (!name) {
      errors.name = 'Please fill in the name field.';
      isValid = false;
    }
    if (!email) {
      errors.email = 'Please fill in the email field.';
      isValid = false;
    } else if (!validateEmail(email)) {
      errors.email = 'Please enter a proper email address';
      isValid = false;
    }
    if (
      phone !== '' &&
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)
    ) {
      errors.phone = 'Please enter a proper phone number.';
      isValid = false;
    }
    if (!isValid) {
      scrollToElement(fieldRefs.requesterAttributes);
    }
    setRequesterAttributesErrors(errors);
    return isValid;
  };

  const answers_attributes = {};
  Object.entries(approvalFormQuestions).forEach(([id, value], index) => {
    answers_attributes[index] = getAnswerAttributes(id, value);
  });

  const approvers_attributes = {};
  approvers.map((approver, i) => {
    approvers_attributes[i] = {
      id: approver.id,
      email: approver.email.includes('*') ? approver.email.slice(1) : approver.email,
      position: 0,
      required: approver.email.includes('*'),
      _destroy: approver.destroyed
    };
  });

  const documents_attributes = {};
  fileList.map((document, i) => {
    documents_attributes[i] = {
      attached: document
    };
  });

  const requisition_requester_attributes = {
    name,
    email,
    phone
  };

  const approvalAttributes = {
    requisition_form_id: templateId,
    position_title: positionTitle,
    documents_attributes,
    to_email: approvalAdministrator,
    approvers_attributes
  };

  const approvalData = {
    requisition: {
      ...approvalAttributes,
      answers_attributes,
      requisition_requester_attributes
    }
  };

  const handleSubmitApproval = async () => {
    if (
      !validateRequesterAttributes() ||
      !validatePositionTitle() ||
      !validateQuestions() ||
      !validateApprovalManagers()
    )
      return;
    const formData = serialize(approvalData);

    try {
      const response = await fetch(
        `${BASE_URL}/api/v4/requisitions/submit_public_requisition?token=${token}`,
        {
          method: 'POST',
          body: formData
        }
      ).then((res) => res.json());
      if (response.success) {
        setRequisitionId(response.requisition_id);
      } else {
        throw response.errors;
      }
    } catch (error) {
      setSnackbar({
        message: `Something went wrong: ${error}. Please try again.`,
        state: 'error'
      });
    }
  };

  const handleLookUpButton = async (questionId: number, positionId: number) => {
    setFetchingCSV(true);
    try {
      const data = await Api.getCSVData(questionId, positionId);
      setApprovers(
        data.res.approvers.map((approver: string) => ({
          email: approver.toLowerCase(),
          destroyed: false
        }))
      );
      questions?.map((question) => {
        const answerExist = data.res.fields[0][question.original_title];
        if (answerExist) {
          setApprovalFormQuestions((prev) => ({
            ...prev,
            [question.id]: {
              ...prev[question.id],
              data:
                question.type === 'SelectField' || question.type === 'RadioButtonField'
                  ? question.field_choices.find((e) => e.name === answerExist)?.id
                  : answerExist
            }
          }));
        }
      });
    } catch (err) {
      setSnackbar({
        message: `Failed to fetch csv data, ${err}`,
        state: 'error'
      });
    } finally {
      setFetchingCSV(false);
    }
  };

  useEffect(() => {
    if (!approvalTemplateData) return;
    const questionsData = approvalTemplateData.fields.filter((question) => question.enabled);
    setAdministratorList(approvalTemplateData.approvers);
    setQuestions(questionsData);
    questionsData && setApprovalFormQuestions(createApprovalFormQuestionsObject(questionsData));
    // If no default approvers set in approval template but has default approvers in entity level
    if (approvalTemplateData.config.default_approvers) {
      setApprovers(
        approvalTemplateData.config.default_approvers.split(',').map((approver) => ({
          email: approver,
          destroyed: false,
          mandatoryBlank: approver === '*'
        }))
      );
    } else if (approvalTemplateData.entity_default_approver) {
      setApprovers(
        approvalTemplateData.entity_default_approver.map((approver) => ({
          email: approver.required ? `*${approver.email}` : approver.email,
          destroyed: false,
          mandatoryBlank: approver.required
        }))
      );
    }
  }, [approvalTemplateData]);

  const handleAddApprover = () => {
    setApprovers([...approvers, { email: '', destroyed: false }]);
  };

  const removeApprover = (i: number) => {
    if (approvers.length > 1) {
      const newApprovers = [...approvers];
      if (newApprovers[i].id) {
        newApprovers[i].destroyed = true;
      } else {
        newApprovers.splice(i, 1);
      }
      setApprovers(newApprovers);
    }
  };

  const handleEmailChange = (i: number, value: IApprovalManagers) => {
    const newApprovers = [...approvers];
    newApprovers[i].email = value ? value.email : '';
    setApprovers(newApprovers);
  };

  const handleDeleteAttachment = (index: number) => {
    const updatedFileList = fileList.filter((file, i) => i !== index);

    setFileList(updatedFileList);
  };

  return (
    <Box>
      {requisitionId ? (
        <Stack sx={{ rowGap: 2, fontSize: '16px' }}>
          <Stack sx={{ fontSize: '22px', fontWeight: 'bold' }}>
            Your request was successfully submitted.
          </Stack>
          <Stack>Your requisition number is {requisitionId}.</Stack>
          <Stack>
            You will receive a notification when your requisition is approved or declined.
          </Stack>
          <Stack>It is safe to leave this page.</Stack>
        </Stack>
      ) : (
        <Box>
          <Box sx={{ padding: 2, paddingLeft: 'unset' }} ref={fieldRefs.requesterAttributes}>
            <Box sx={{ paddingBottom: 3 }}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setName(value);
                }}
                label="Your full name"
                styles={{ width: '300px' }}
                required
                id="full-name"
                defaultValue={name}
                error={requesterAttributesErrors.name}
              />
            </Box>
            <Box sx={{ paddingBottom: 3 }}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setEmail(value);
                }}
                label="Your email"
                styles={{ width: '300px' }}
                required
                id="email"
                defaultValue={email}
                error={requesterAttributesErrors.email}
              />
            </Box>
            <Box sx={{ paddingBottom: 3 }}>
              <DebouncedFormTextField
                onDebouncedChange={(value: string) => {
                  setPhone(value);
                }}
                label="Your phone number"
                styles={{ width: '300px' }}
                // eslint-disable-next-line max-lines
                id="phone-number"
                defaultValue={phone}
                error={requesterAttributesErrors.phone}
              />
            </Box>
          </Box>
          <Box sx={{ padding: 2, paddingLeft: 'unset' }}>
            {loadingData ? (
              <Skeleton animation="wave" width={600} height={56} />
            ) : (
              <>
                {!Number(approvalTemplateData?.config.hide_position_title) && (
                  <Box sx={{ paddingBottom: 3 }}>
                    <DebouncedFormTextField
                      onDebouncedChange={(value: string) => {
                        setPositionTitle(value);
                      }}
                      label="Vacancy position title"
                      defaultValue={positionTitle}
                      styles={{ width: '300px' }}
                      required
                      innerRef={fieldRefs.positionTitle}
                      error={positionTitleError}
                    />
                  </Box>
                )}
                {questions?.map((question, index) => (
                  <Box sx={{ paddingBottom: 3 }} key={question.id}>
                    <QuestionFieldGenerator
                      question={question}
                      questionRef={(el) => (fieldRefs.questions.current[index] = el)}
                      questionError={questionErrors[question.id]}
                      onChangeCallback={(id, value) => {
                        setApprovalFormQuestions((prev) => ({
                          ...prev,
                          [id]: {
                            ...prev[id],
                            data: value
                          }
                        }));
                        setQuestionErrors((prev) => ({
                          ...prev,
                          [id]: value || !question.required ? '' : 'This field is required'
                        }));
                      }}
                      handleLookUpButton={handleLookUpButton}
                      answer={approvalFormQuestions[question.id]?.data}
                      fetchingCSV={fetchingCSV}
                    />
                  </Box>
                ))}
              </>
            )}
          </Box>
          <Box sx={{ padding: 2, paddingLeft: 'unset' }}>
            <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>Documents</Stack>
            <AttachmentSelector
              performUpload={false}
              apiKey={apiKey}
              setFileList={(file) => {
                setFileList((previous) => [...previous, ...file]);
              }}
              maxSize={50000000}
            />
            {fileList &&
              fileList.map(
                (file, index) =>
                  file.name && (
                    <Box key={index} sx={classes.fileContainer}>
                      <Box sx={classes.fileIconContainer}>
                        <Box sx={classes.fileIcon}>{DocTypeIcon(file.name.split('.').pop())}</Box>
                        <Box>
                          <Box sx={classes.fileName}>{file.name}</Box>
                          <Box sx={classes.fileSize}>
                            {moment(file.lastModified).format('Do MMMM YYYY[,] h:mma')}
                          </Box>
                        </Box>
                      </Box>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon sx={{ color: '#D6827D' }} />
                      </IconButton>
                    </Box>
                  )
              )}
          </Box>
          {loadingData ? (
            <Skeleton animation="wave" width={600} height={56} />
          ) : (
            <>
              {!Number(approvalTemplateData?.config.hide_approval_administrator) &&
                administratorList && (
                  <Box sx={{ padding: 2, paddingLeft: 'unset' }}>
                    <Stack sx={{ ...classes.sectionHeader, paddingBottom: 2 }}>
                      Approval Administrator
                    </Stack>
                    <Autocomplete
                      disablePortal
                      options={administratorList}
                      getOptionLabel={(option) => option.label}
                      value={
                        administratorList.find(
                          (manager) => manager.value === approvalAdministrator
                        ) || null
                      }
                      onChange={(event, value) =>
                        value ? setApprovalAdministrator(value.value) : setApprovalAdministrator('')
                      }
                      sx={{ width: '300px', paddingTop: 3, ...sharedClasses.formAutocomplete }}
                      ListboxProps={{
                        style: {
                          fontFamily: 'Source Sans Pßro, sans-serif',
                          color: '#333333',
                          maxHeight: '200px'
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Send to"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Please select"
                        />
                      )}
                    />
                  </Box>
                )}
            </>
          )}
          {loadingApprovers ? (
            <Skeleton animation="wave" width={600} height={56} />
          ) : (
            <Box sx={{ padding: 2, paddingLeft: 'unset' }}>
              <Stack sx={classes.sectionHeader} ref={fieldRefs.approvalManagers}>
                Approvers
              </Stack>
              {approvers.map((approver, index) => {
                const mandatory = approver.email.includes('*');
                const mandatoryBlank = approver.mandatoryBlank;
                const value = approvalManagers.find(
                  (manager) =>
                    (mandatory ? approver.email.slice(1) : approver.email) === manager.email
                );
                return (
                  <React.Fragment key={`${value?.id}-${index}`}>
                    {!approver.destroyed && (
                      <Box sx={classes.approversContainer}>
                        <Autocomplete
                          disablePortal
                          disabled={!mandatoryBlank && mandatory}
                          options={approvalManagers}
                          getOptionLabel={(option) => `${option.name} (${option.email})`}
                          value={value || null}
                          onChange={(event, value) => handleEmailChange(index, value)}
                          sx={{ width: '300px', ...sharedClasses.formAutocomplete }}
                          ListboxProps={{
                            style: {
                              fontFamily: 'Source Sans Pro, sans-serif',
                              color: '#333333',
                              maxHeight: '200px'
                            }
                          }}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                label="Email"
                                InputLabelProps={{ shrink: true }}
                                placeholder="Please select"
                              />
                              {approver.error && (
                                <Stack sx={{ color: '#E37D7A', fontSize: '13px', paddingLeft: 1 }}>
                                  {approver.error}
                                </Stack>
                              )}
                            </>
                          )}
                        />
                        {!mandatoryBlank && !mandatory && (
                          <Button
                            sx={{ '&:hover': { backgroundColor: 'inherit' } }}
                            onClick={() => removeApprover(index)}
                          >
                            <CloseIcon color="warning" sx={{ cursor: 'pointer' }} />
                          </Button>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
              <Box sx={{ paddingTop: 1 }}>
                <Button
                  sx={{
                    ...classes.draftButton,
                    fontSize: '12px',
                    padding: '4px 12px',
                    height: '30px'
                  }}
                  onClick={handleAddApprover}
                >
                  Add approver
                </Button>
              </Box>
            </Box>
          )}
          <Box
            sx={{ ...classes.footerButtons, justifyContent: 'flex-start', paddingLeft: 'unset' }}
          >
            <LoadingButton
              id="submit-new-approval"
              type="submit"
              sx={{ ...sharedClasses.saveButton, width: 'auto' }}
              task={handleSubmitApproval}
            >
              Submit Requisition for Approval
            </LoadingButton>
          </Box>
          <StyledSnackbar
            message={snackbar.message}
            state={snackbar.state}
            setSnackbarState={setSnackbar}
          />
        </Box>
      )}
    </Box>
  );
}
