import React, { useCallback, useEffect, useRef, useState } from 'react';
import StyledSnackbar from '../Components/CustomUIElements/StyledSnackbar';
import { Box, Tab, Tabs, ToggleButton, ToggleButtonGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PushPinIcon from '@mui/icons-material/PushPin';
import { withScoutTheme } from '../../ThemeContext/ThemeContext';
import { styles } from './styles';
import FieldDropdown from './Dropdowns/FieldDropdown';
import FilterDropdown from './Dropdowns/FilterDropdown';
import ExportButton from './Dropdowns/Export';
import { IFilters, IOptionType, IPermissions, ITabs } from './types';
import CurrentJobs from './JobsTable/CurrentJobs';
import ArchivedJobs from './JobsTable/ArchivedJobs';
import SeekAds from '../Components/Multiposter/Seek/SeekAds';
import CreateNewJob from './NewJob/NewJob';
import IndeedAds from '../Components/Multiposter/Indeed/IndeedAds';
import ViewJobsAds from '../Components/Multiposter/ViewJobs/ViewJobsAds';
import Search from './Search';
import Api from './API';
import { jobsConfig } from './config';
import { useDebounce } from 'use-debounce';
import { getLocalStorageItem } from '../utils/local-storage';
import { FILTERS_KEY } from '../constants';
import { IUserPermissions } from '../Components/sharedTypes';
import CreateButton from '../Components/Buttons/CreateButton';
import { ISortableColumn, IUIPreferences } from '../Job/types';
import { getUpdatedSortableColumns } from '../utils/sortable-column';
import { getPermissions } from '../../shared/permissionHelpers';
import { fetchPreferences, updateUiSettings } from '../helpers/uiPreferencesHelpers';

const URL_PARAMS = new URL(window.location.href);

const getTabNumber = (urlParams: string) => {
  switch (urlParams) {
    case '?archived=true':
      return 1;
    case '?multiposter=true':
      return 2;
    default:
      return 0;
  }
};

function AdminJobs({
  apiKey,
  aiStudioEnabled,
  jobStatusFeature,
  userTimezone
}: {
  apiKey: string;
  aiStudioEnabled: boolean;
  jobStatusFeature: boolean;
  userTimezone: string;
}) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [filters, setFilters] = useState<Omit<IFilters, 'pinned'>>({
    state: [],
    status: [],
    entity: [],
    location: [],
    assigned_user: [],
    recruitment_ad_user: [],
    published: [],
    expiry: []
  });
  const [options, setOptions] = useState(jobsConfig.defaultFilterOptions);
  const [sortableColumns, setSortableColumns] = useState(jobsConfig.sortableColumnsDefaults);
  const [fetchUISwitch, setFetchUISwitch] = useState(true);
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [loadingFilters, setLoadingFilters] = useState(0);
  const [debouncedSearch] = useDebounce(search, 600);
  const [tabValue, setTabValue] = useState(getTabNumber(URL_PARAMS.search));
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [userPermissions, setUserPermissions] = useState<IUserPermissions | undefined>(undefined);
  const [multiposterSelection, setMultiposterSelection] = useState('seek');
  const [showPinnedJobs, setShowPinnedJobs] = useState(false);
  const headerRefs = useRef<HTMLDivElement[]>([]);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });
  const [density, setDensity] = useState<string>('Default');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const filterKeys = Object.keys(filters) as (keyof IFilters)[];
  const TabPanel = useCallback((props: ITabs) => {
    const { children, tabValue, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`jobs-tabpanel-${index}`}
        aria-labelledby={`jobs-tab-${index}`}
        {...other}
      >
        {tabValue === index && <>{children}</>}
      </div>
    );
  }, []);

  const TabProps = useCallback((index: number) => {
    return {
      id: `jobs-tab-${index}`,
      'aria-controls': `jobs-tabpanel-${index}`
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilters({
      state: [],
      status: [],
      entity: [],
      location: [],
      assigned_user: [],
      recruitment_ad_user: [],
      published: [],
      expiry: []
    });
    const states = newValue ? jobsConfig.archivedStateOptions : jobsConfig.currentStateOptions;
    setSearch('');
    setPage(1);
    getEntites(states, Boolean(newValue));
    getLocations(states, Boolean(newValue));
    getAssignedUsers(states, Boolean(newValue));
    getRecruitmentAdUsers(states, Boolean(newValue));
    getStatuses(states, Boolean(newValue));
    setTabValue(newValue);
    URL_PARAMS.search = getPageParams(newValue);
    window.history.pushState(null, null, URL_PARAMS.href);
  };

  async function getEntites(states: Record<string, IOptionType[]>, archived: boolean) {
    setLoadingFilters((prevValue) => prevValue + 1);
    try {
      const data = await Api.getFilters('entities', {
        'filter[archived]': archived
      });
      setOptions((previous) => ({ ...previous, ...states, entity: data.res.entities }));
      setLoadingFilters((prevValue) => prevValue - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function getLocations(states: Record<string, IOptionType[]>, archived: boolean) {
    setLoadingFilters((prevValue) => prevValue + 1);
    try {
      const data = await Api.getFilters('locations', {
        'filter[archived]': archived
      });
      setOptions((previous) => ({
        ...previous,
        ...states,
        location: data.res.flatMap((location: string, index: number) =>
          location ? { index: index, name: location } : ([] as null[])
        )
      }));
      setLoadingFilters((prevValue) => prevValue - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAssignedUsers(states: Record<string, IOptionType[]>, archived: boolean) {
    setLoadingFilters((prevValue) => prevValue + 1);
    try {
      const data = await Api.getFilters('assigned_users', {
        'filter[archived]': archived
      });
      setOptions((previous) => ({
        ...previous,
        ...states,
        assigned_user: data.res.assigned_users
      }));
      setLoadingFilters((prevValue) => prevValue - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function getRecruitmentAdUsers(states: Record<string, IOptionType[]>, archived: boolean) {
    setLoadingFilters((prevValue) => prevValue + 1);
    try {
      const data = await Api.getFilters('recruitment_ad_users', {
        'filter[archived]': archived
      });
      setOptions((previous) => ({
        ...previous,
        ...states,
        recruitment_ad_user: data.res.recruitment_ad_users
      }));
      setLoadingFilters((prevValue) => prevValue - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function getStatuses(states: Record<string, IOptionType[]>, archived: boolean) {
    setLoadingFilters((prevValue) => prevValue + 1);
    try {
      const data = await Api.getFilters('statuses', {
        'filter[archived]': archived
      });
      setOptions((previous) => ({ ...previous, ...states, status: data.res.statuses }));
      setLoadingFilters((prevValue) => prevValue - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserPermissions(apiKey: string) {
    try {
      const data = await getPermissions(apiKey);
      const allRoles: Record<string, Record<string, boolean>> = {};
      data.roles.forEach((role: IPermissions) =>
        role.feature_groups.forEach((group) => {
          !allRoles[group.name] ? (allRoles[group.name] = {}) : null;
          group.permissions.forEach((permission) => (allRoles[group.name][permission] = true));
        })
      );
      const hasRecruitmentTeamEnabled = data.roles.some((user) => user.recruitment_team_enabled);
      allRoles['recruitment_team_enabled'] = hasRecruitmentTeamEnabled;
      setUserPermissions(allRoles);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUISettings() {
    try {
      const { res } = await fetchPreferences('job');
      const densityData = await fetchPreferences('universal');
      if (res.ui_preferences[0]?.sortable_columns) {
        const { updatedColumns } = getUpdatedSortableColumns(
          sortableColumns as ISortableColumn[],
          res.ui_preferences[0] as IUIPreferences
        );
        setSortableColumns(updatedColumns);
      }
      densityData.res.ui_preferences[0]?.columns?.density &&
        setDensity(densityData.res.ui_preferences[0]?.columns?.density);
      res.ui_preferences[0]?.row_count && setRowsPerPage(res.ui_preferences[0].row_count);
      res.ui_preferences[0]?.sorting?.direction &&
        setOrder(res.ui_preferences[0].sorting.direction);
      res.ui_preferences[0]?.sorting?.field && setOrderBy(res.ui_preferences[0].sorting.field);
      res.ui_preferences[0]?.group_pinned && setShowPinnedJobs(res.ui_preferences[0]?.group_pinned);
      setFetchUISwitch(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function postDensitySetting(density: string) {
    await updateUiSettings({ columns: { density }, source: 'universal' }, (errorMessage) => {
      setSnackbar({
        message: `There was an error saving your density preferences, ${errorMessage}`,
        state: 'error'
      });
    });
  }

  async function postUISettings({ sortableColumns, order, orderBy, rowsPerPage, showPinnedJobs }) {
    await updateUiSettings({
      sortable_columns: sortableColumns,
      sorting: { direction: order, field: orderBy },
      row_count: rowsPerPage,
      source: 'job',
      group_pinned: showPinnedJobs
    });
  }

  useEffect(() => {
    getEntites(jobsConfig.currentStateOptions, Boolean(tabValue));
    getLocations(jobsConfig.currentStateOptions, Boolean(tabValue));
    getAssignedUsers(jobsConfig.currentStateOptions, Boolean(tabValue));
    getRecruitmentAdUsers(jobsConfig.currentStateOptions, Boolean(tabValue));
    getStatuses(jobsConfig.currentStateOptions, Boolean(tabValue));
    fetchUISettings();
    getUserPermissions(apiKey);
  }, []);

  useEffect(() => {
    setIndicatorWidth(headerRefs.current[tabValue].clientWidth - 32);
    getUserPermissions(apiKey);
  }, [tabValue]);

  useEffect(() => {
    const filtersLength = filterKeys
      .map((key) => filters[key].length)
      .reduce((acc, item) => (acc += item), 0);

    setNumberOfFilters(filtersLength);
  }, [filters]);

  useEffect(() => {
    return () => {
      if (fetchUISwitch) return;
      postUISettings({ sortableColumns, order, orderBy, rowsPerPage, showPinnedJobs });
    };
  }, [sortableColumns, order, orderBy, rowsPerPage, showPinnedJobs]);

  const getPageParams = (tabNo: number) => {
    switch (tabNo) {
      case 0:
        return '';
      case 1:
        return '?archived=true';
      case 2:
        return '?multiposter=true';
      default:
        return '';
    }
  };

  useEffect(() => {
    setFilters({
      state: getLocalStorageItem(FILTERS_KEY, { state: [] }).state ?? [],
      status: getLocalStorageItem(FILTERS_KEY, { status: [] }).status ?? [],
      entity: getLocalStorageItem(FILTERS_KEY, { entity: [] }).entity ?? [],
      location: getLocalStorageItem(FILTERS_KEY, { location: [] }).location ?? [],
      assigned_user: getLocalStorageItem(FILTERS_KEY, { assigned_user: [] }).assigned_user ?? [],
      recruitment_ad_user:
        getLocalStorageItem(FILTERS_KEY, { recruitment_ad_user: [] }).recruitment_ad_user ?? [],
      published: getLocalStorageItem(FILTERS_KEY, { published: [] }).published ?? [],
      expiry: getLocalStorageItem(FILTERS_KEY, { expiry: [] }).expiry ?? []
    });
  }, []);

  return (
    <Box sx={styles.jobPageContainer}>
      <Box sx={styles.jobPageHeader}>
        <h1>Jobs</h1>
        {userPermissions?.Jobs?.['Create / Edit Jobs'] && (
          <CreateButton
            id="add-job-button"
            ariaLabel="add job"
            onClick={() => setIsDialogOpen(true)}
            disableDropDown
          />
        )}
      </Box>
      <Box sx={styles.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Jobs, Archive, Multiposter Tabs"
          sx={{
            ...styles.tabs,
            '& .MuiTabs-indicator': { ...styles.tabIndicator, maxWidth: indicatorWidth + 'px' }
          }}
        >
          <Tab
            ref={(el) => (headerRefs.current[0] = el)}
            label="Current jobs"
            {...TabProps(0)}
            id="current-jobs-tab-button"
            data-testid="current-jobs-tab-button"
          />
          <Tab
            ref={(el) => (headerRefs.current[1] = el)}
            label="Archived jobs"
            {...TabProps(1)}
            id="archived-jobs-tab-button"
          />
          <Tab
            ref={(el) => (headerRefs.current[2] = el)}
            label="Multiposter job ads"
            {...TabProps(2)}
            id="multiposter-jobs-tab-button"
          />
        </Tabs>
      </Box>
      <TabPanel tabValue={tabValue} index={0}>
        <Box sx={styles.filtersRow}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <FilterDropdown
            filters={filters}
            setFilters={setFilters}
            options={options}
            setPage={setPage}
            numberOfFilters={numberOfFilters}
            loadingFilters={loadingFilters}
            jobStatusFeature={jobStatusFeature}
            userPermissions={userPermissions}
          />
          <FieldDropdown
            sortableColumns={sortableColumns}
            setSortableColumns={setSortableColumns}
            density={density}
            setDensity={setDensity}
            postDensitySetting={postDensitySetting}
            jobStatusFeature={jobStatusFeature}
            userPermissions={userPermissions}
          />
          <ExportButton
            apiKey={apiKey}
            filters={filters}
            order={order}
            orderBy={orderBy}
            sortableColumns={sortableColumns}
            search={search}
            jobStatusFeature={jobStatusFeature}
          />
          <Tooltip
            placement="top"
            title={showPinnedJobs ? 'Ungroup pinned jobs' : 'Group pinned jobs on top'}
            arrow
          >
            <IconButton
              id="group-pinned-jobs-button"
              data-testid="group-pinned-jobs-button"
              sx={{
                ...styles.iconButton,
                ...(showPinnedJobs ? styles.iconButtonSelected : {})
              }}
              onClick={() => setShowPinnedJobs((prev) => !prev)}
            >
              <PushPinIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <CurrentJobs
          {...{ userTimezone }}
          apiKey={apiKey}
          sortableColumns={sortableColumns}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          search={debouncedSearch}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filters={filters}
          fetchUISwitch={fetchUISwitch}
          page={page}
          setPage={setPage}
          filtersApplied={numberOfFilters > 0}
          userPermissions={userPermissions}
          setSnackbarState={setSnackbar}
          showPinnedJobs={showPinnedJobs}
          setShowPinnedJobs={setShowPinnedJobs}
          density={density}
          jobStatusFeature={jobStatusFeature}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={1}>
        <Box sx={styles.filtersRow}>
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <FilterDropdown
            filters={filters}
            setFilters={setFilters}
            options={options}
            setPage={setPage}
            numberOfFilters={numberOfFilters}
            loadingFilters={loadingFilters}
            jobStatusFeature={jobStatusFeature}
            archived
            userPermissions={userPermissions}
          />
          <FieldDropdown
            sortableColumns={sortableColumns}
            setSortableColumns={setSortableColumns}
            density={density}
            setDensity={setDensity}
            postDensitySetting={postDensitySetting}
            archived
            jobStatusFeature={jobStatusFeature}
            userPermissions={userPermissions}
          />
          <ExportButton
            apiKey={apiKey}
            filters={filters}
            order={order}
            orderBy={orderBy}
            sortableColumns={sortableColumns}
            jobStatusFeature={jobStatusFeature}
            archived
          />
        </Box>
        <ArchivedJobs
          apiKey={apiKey}
          sortableColumns={sortableColumns}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          search={debouncedSearch}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filters={filters}
          fetchUISwitch={fetchUISwitch}
          page={page}
          setPage={setPage}
          filtersApplied={numberOfFilters > 0}
          userPermissions={userPermissions}
          setSnackbarState={setSnackbar}
          density={density}
          jobStatusFeature={jobStatusFeature}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={2}>
        <Box sx={styles.multiposterContainer}>
          <Box sx={styles.toggleContainer}>
            <ToggleButtonGroup
              value={multiposterSelection}
              exclusive
              onChange={(e, value) => {
                if (value === null) return;
                setMultiposterSelection(value);
              }}
              aria-label="Multiposter selection"
              sx={styles.toggleButtonGroup}
            >
              <ToggleButton sx={styles.toggleButton} value="seek" id="multiposter-seek-jobs-tab">
                <Box id="seek-logo" sx={styles.logoSize} />
              </ToggleButton>
              <ToggleButton
                sx={styles.toggleButton}
                value="indeed"
                id="multiposter-indeed-jobs-tab"
              >
                <Box id="indeed-logo" sx={{ transform: 'translateY(3px)', ...styles.logoSize }} />
              </ToggleButton>
              <ToggleButton
                sx={styles.toggleButton}
                value="viewjobs"
                id="multiposter-viewjobs-jobs-tab"
              >
                <Box id="viewjobs-logo" sx={{ transform: 'translateY(3px)', ...styles.logoSize }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {(() => {
            switch (multiposterSelection) {
              case 'seek':
                return <SeekAds apiKey={apiKey} jobId={null} />;
              case 'indeed':
                return <IndeedAds apiKey={apiKey} jobId={null} />;
              case 'viewjobs':
                return <ViewJobsAds apiKey={apiKey} jobId={null} />;
              default:
                return null;
            }
          })()}
        </Box>
      </TabPanel>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
      <CreateNewJob
        apiKey={apiKey}
        setFilters={setFilters}
        filters={filters}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        aiStudioEnabled={
          !!(userPermissions?.['AI Features']?.['Activate AI Studio'] && aiStudioEnabled)
        }
        showEntitySection={!!userPermissions?.Misc?.['Entity Manager Access']}
        setSnackbar={setSnackbar}
        userPermissions={userPermissions}
        userTimezone={userTimezone}
      />
    </Box>
  );
}

export default withScoutTheme(AdminJobs);
