import React, { useEffect } from 'react';
import { classes } from '../../../Components/Modals/styles';
import { Box } from '@mui/material';
import { IAttachment, IVideoInterview } from '../../types';

export default function VideoPreview({
  selectedAttachment,
  videoRef,
  setIsLoadingPreview,
  source
}: {
  selectedAttachment: IAttachment | IVideoInterview;
  videoRef: React.RefObject<HTMLVideoElement>;
  isLoadingPreview: boolean;
  setIsLoadingPreview: React.Dispatch<React.SetStateAction<boolean>>;
  source: string | undefined;
}) {
  useEffect(() => {
    setIsLoadingPreview(true);
  }, [selectedAttachment]);

  useEffect(() => {
    if (source && videoRef.current) {
      videoRef.current.load();
      setIsLoadingPreview(false);
    }
  }, [source, videoRef, setIsLoadingPreview]);

  return (
    <Box sx={classes.mediaContentContainer}>
      <video
        ref={videoRef}
        key={selectedAttachment?.id}
        width="100%"
        controls
        style={{ objectFit: 'contain', maxHeight: '30vh' }}
        onLoadStart={() => setIsLoadingPreview(false)}
      >
        <source src={source} />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}
