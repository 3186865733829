import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { styles } from '../../styles';
import { sharedClasses } from '../../../../Components/CustomUIElements/sharedClasses';
import {
  ApplicationAction,
  IApplication,
  IIntegrationsResponse,
  ICognologyRecord
} from '../../../types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../../API';
import { useGetCognologyData } from '../../../helper';
import FormRadioButtonGroup from '../../../../Components/CustomUIElements/FormRadioButtonGroup';
import { FormTextField } from '../../../../Components/CustomUIElements/FormTextField';
import { SingleDatePicker } from '../../../../Components/CustomUIElements/SingleDatePicker';
import { cognologyExportPreference } from '../../../config';
import { autocompleteStyle } from './Cognology';
import DocTypeIcon from '../../../../Components/Utilities/DocTypeIcon';
import { ICountries, IStates } from '../../../../Components/sharedTypes';
import dayjs from 'dayjs';

export default function CognologyStep1({
  exportPreference,
  setExportPreference,
  record,
  setRecord,
  country,
  setCountry,
  state,
  setState,
  categories,
  setCategories,
  attachments,
  setAttachments,
  startDate,
  setStartDate,
  dispatch
}: {
  exportPreference: string;
  setExportPreference: Dispatch<SetStateAction<string>>;
  record: ICognologyRecord | null;
  setRecord: Dispatch<SetStateAction<ICognologyRecord | null>>;
  country: ICountries | null;
  setCountry: Dispatch<SetStateAction<ICountries | null>>;
  state: IStates | null;
  setState: Dispatch<SetStateAction<IStates | null>>;
  categories: Record<string, [string, number] | null> | null;
  setCategories: Dispatch<SetStateAction<Record<string, [string, number] | null> | null>>;
  attachments: Record<number, { id: number; name: string } | null> | null;
  setAttachments: Dispatch<
    SetStateAction<Record<number, { id: number; name: string } | null> | null>
  >;
  startDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);
  const [dateError, setDateError] = useState<string>('');

  const { cognologyData, loadingCognologyData } = useGetCognologyData({
    applicationId: application?.id,
    dispatch
  });

  const { data: countriesStates } = useQuery({
    queryKey: ['countries states'],
    queryFn: async () => {
      const { res } = await Api.getCountriesStates();
      return res.countries;
    },
    onSuccess: (res) => {
      const savedCountry = res.find((r) => r.iso === record?.candidate_addr_country);
      const savedState = savedCountry?.states?.find(
        (s) => s.abbreviation === record?.candidate_addr_state
      );
      savedCountry && setCountry(savedCountry);
      savedState && setState(savedState);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting countries and states data, ${error}`,
          state: 'error'
        }
      });
    },
    enabled: !!record
  });

  useEffect(() => {
    record?.categories && setExportPreference('full');
  }, [record, setExportPreference]);

  return (
    <>
      <FormRadioButtonGroup
        options={cognologyExportPreference}
        onChange={(value) => setExportPreference(value.toString())}
        directionRow
        color={'#939393'}
        defaultValue={exportPreference}
        groupLabel="Export preference"
        required
      />
      <Stack sx={styles.cognologySectionTitle}>Candidate Details</Stack>
      {!integrationsData?.cognology_config.id_blank && (
        <FormTextField
          label="Employee ID"
          value={record?.candidate_employee_id || ''}
          styles={{ width: '48%' }}
          required
          onChange={(e) =>
            record && setRecord({ ...record, candidate_employee_id: e.target.value })
          }
          helperText="Please leave it blank to have Cognology generate an employee ID automatically"
        />
      )}
      <Autocomplete
        disablePortal
        autoHighlight
        includeInputInList
        value={record?.candidate_title || null}
        options={['Mr', 'Mrs', 'Ms', 'Miss', 'Dr', 'Other']}
        onChange={(_event, newValue) =>
          record && setRecord({ ...record, candidate_title: newValue })
        }
        sx={autocompleteStyle}
        ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Title"
            InputLabelProps={{ shrink: true }}
            placeholder="Please select"
          />
        )}
      />
      <Stack sx={styles.modalDoubleColumn}>
        <FormTextField
          label="First name"
          value={record?.candidate_first_name || ''}
          required
          fullWidth
          onChange={(e) => record && setRecord({ ...record, candidate_first_name: e.target.value })}
        />
        <FormTextField
          label="Last name"
          value={record?.candidate_last_name || ''}
          required
          fullWidth
          onChange={(e) => record && setRecord({ ...record, candidate_last_name: e.target.value })}
        />
      </Stack>
      <FormTextField
        label="Email"
        value={record?.candidate_email || ''}
        styles={{ width: '48%' }}
        required
        onChange={(e) => record && setRecord({ ...record, candidate_email: e.target.value })}
      />
      <Stack sx={styles.modalDoubleColumn}>
        <FormTextField
          label={'Phone (must include country code)'}
          value={record?.candidate_phone1 || ''}
          onChange={(e) => record && setRecord({ ...record, candidate_phone1: e.target.value })}
          fullWidth
        />
        <FormTextField
          label={'Mobile (must include country code)'}
          value={record?.candidate_phone2 || ''}
          onChange={(e) => record && setRecord({ ...record, candidate_phone2: e.target.value })}
          fullWidth
        />
      </Stack>
      <Stack sx={styles.modalDoubleColumn}>
        <FormTextField
          label="Street address"
          value={record?.candidate_addr_street1 || ''}
          fullWidth
          onChange={(e) =>
            record && setRecord({ ...record, candidate_addr_street1: e.target.value })
          }
        />
        <FormTextField
          label="Street address cont'd"
          value={record?.candidate_addr_street2 || ''}
          fullWidth
          onChange={(e) =>
            record && setRecord({ ...record, candidate_addr_street2: e.target.value })
          }
        />
      </Stack>
      <Stack sx={styles.modalDoubleColumn}>
        <FormTextField
          label="City, town or suburb"
          value={record?.candidate_addr_suburb || ''}
          fullWidth
          onChange={(e) =>
            record && setRecord({ ...record, candidate_addr_suburb: e.target.value })
          }
        />
        <FormTextField
          label="Postcode or zipcode"
          value={record?.candidate_addr_postcode || ''}
          fullWidth
          onChange={(e) =>
            record && setRecord({ ...record, candidate_addr_postcode: e.target.value })
          }
        />
      </Stack>
      <Stack sx={styles.modalDoubleColumn}>
        <Autocomplete
          filterSelectedOptions
          disablePortal
          autoHighlight
          includeInputInList
          value={country}
          options={countriesStates || []}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue) => {
            setCountry(newValue);
            setState(null);
          }}
          sx={autocompleteStyle}
          ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              InputLabelProps={{ shrink: true }}
              placeholder="Please select"
            />
          )}
        />
        <Autocomplete
          filterSelectedOptions
          disablePortal
          autoHighlight
          includeInputInList
          value={state}
          options={country?.states || []}
          getOptionLabel={(option) => option.name}
          onChange={(_event, newValue) => setState(newValue)}
          disabled={!country}
          sx={autocompleteStyle}
          ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State, region or province"
              InputLabelProps={{ shrink: true }}
              placeholder="Please select"
            />
          )}
        />
      </Stack>
      <Autocomplete
        disablePortal
        autoHighlight
        includeInputInList
        value={record?.candidate_gender || null}
        options={cognologyData?.genders || []}
        loading={loadingCognologyData}
        loadingText="Loading genders..."
        onChange={(_event, newValue) =>
          record && setRecord({ ...record, candidate_gender: newValue })
        }
        sx={autocompleteStyle}
        ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Gender"
            InputLabelProps={{ shrink: true }}
            placeholder="Please select"
          />
        )}
      />
      <FormTextField
        label="Manager employee ID"
        value={record?.manager_id || ''}
        styles={{ width: '48%' }}
        onChange={(e) => record && setRecord({ ...record, manager_id: e.target.value })}
      />
      <FormTextField
        label="Application URL"
        value={record?.recruitment_record_url || ''}
        styles={{ width: '48%' }}
        onChange={(e) => record && setRecord({ ...record, recruitment_record_url: e.target.value })}
      />
      <Stack>
        <SingleDatePicker
          value={startDate}
          label="Start date"
          inputStyles={{ ...sharedClasses.datePicker, width: '48%' }}
          onChange={(value) => {
            if (!value?.isValid()) {
              setDateError('Please input a valid date');
            } else {
              setDateError('');
              setStartDate(dayjs(value.toDate()).toDate());
            }
          }}
          error={dateError}
        />
      </Stack>
      {exportPreference === 'full' && (
        <Stack sx={styles.cognologyCategoriesContainer}>
          {cognologyData?.categories?.map((category) => (
            <Autocomplete
              key={category.id}
              disablePortal
              autoHighlight
              includeInputInList
              value={categories?.[category.id]}
              options={category.options}
              getOptionLabel={(option) => option[0]}
              filterOptions={(options, state) => {
                const { inputValue } = state;
                const filtered = options.filter((option) =>
                  option[0].toLowerCase().includes(inputValue.toLowerCase())
                );
                inputValue && filtered.push([`Create ${inputValue}`, 0]);
                return filtered;
              }}
              onChange={(_event, newValue) => {
                if (newValue) {
                  const newCategories = { ...categories };
                  if (newValue[1] === 0) {
                    newValue = [newValue[0].replace('Create ', ''), newValue[1]];
                  }
                  newCategories[category.id] = newValue;
                  setCategories(newCategories);
                }
              }}
              sx={autocompleteStyle}
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={category.name}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Please select"
                />
              )}
            />
          ))}
        </Stack>
      )}
      {!!cognologyData?.application_assets?.length && (
        <>
          <Stack sx={styles.cognologySectionTitle}>Attachments</Stack>
          {cognologyData.application_assets.map((asset, index) => (
            <Stack key={index} sx={styles.modalDoubleColumn}>
              <Stack sx={styles.cognologyAttachmentContainer}>
                <Stack sx={styles.fileIcon}>{DocTypeIcon(asset.file_name.split('.').pop())}</Stack>
                <Stack>{asset.file_name}</Stack>
              </Stack>
              <Autocomplete
                disablePortal
                autoHighlight
                includeInputInList
                value={attachments?.[asset.id] || null}
                options={cognologyData?.document_tags}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(_event, newValue) =>
                  setAttachments({ ...attachments, [asset.id]: newValue })
                }
                sx={autocompleteStyle}
                ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Attachment tag"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please select"
                  />
                )}
              />
            </Stack>
          ))}
        </>
      )}
    </>
  );
}
