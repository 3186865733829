import React, { useState } from 'react';
import { Popover, Stack } from '@mui/material';
import { classes } from './styles';
import formatToUserTimeZone from '../../../NewUI/utils/formatToUserTimeZone';

export default function DateDisplay({ dates }: { dates: Date[] }) {
  const [datesAnchorEl, setDatesAnchorEl] = useState<HTMLDivElement | null>(null);
  return (
    <Stack flexDirection="row">
      {formatToUserTimeZone(dates[0], 'shortWithDayOfWeek')}
      {dates.length > 1 && (
        <Stack
          sx={classes.multipleDatesIndicator}
          onClick={(e) => setDatesAnchorEl(e.currentTarget)}
        >{`+${dates.length - 1}`}</Stack>
      )}
      <Popover
        open={Boolean(datesAnchorEl)}
        anchorEl={datesAnchorEl}
        onClose={() => setDatesAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
      >
        <Stack sx={{ padding: '8px 16px', rowGap: '4px' }}>
          {dates.slice(1).map((d, i) => (
            <Stack key={i} sx={{ fontSize: '14px' }}>
              {formatToUserTimeZone(d, 'shortWithDayOfWeek')}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
}
